import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import STRING_KEYS from '../../../language/keys';
import { numberWithCommas } from '../../../utils/number';
import { THEME } from '../../../themes';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { LocationDto } from '../../../models/dto/location.dto';
import {
  ColumnContainer,
  BarTextTitle,
  BarTextValue,
  BarTextWrapper,
  ColumnWrapper,
  Bold,
  Container,
  Divider,
  DividerContainer,
  DividerTop,
  Bar,
  BarWrapper,
  StayTunedValue,
} from './funnel-overview-widget.styled';

export interface FunnelOverviewWidgetProps {
  jobTitle: string;
  positionLocation?: LocationDto;
  talentsInThisRole: number;
  talentsWithRelevantBasicQualifications: number;
  talentsInPositionLocation: number;
  talentsWillingToMove?: number;
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

type RenderBarOptions = {
  text: string | JSX.Element,
  value: number | undefined,
  marginLeft?: boolean,
  opacity?: number,
  height?: number,
  disableDividerHead?: boolean,
}

export default function FunnelOverviewWidget({
  jobTitle,
  talentsInThisRole,
  talentsWithRelevantBasicQualifications,
  talentsInPositionLocation,
  talentsWillingToMove,
  onIntersectionHandler,
}: FunnelOverviewWidgetProps): JSX.Element  {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);

  useInsightWidgetIntersection({
    componentRef,
    onIntersectionHandler,
    metadata: `talents: ${talentsInThisRole}, qualified: ${talentsWithRelevantBasicQualifications}, in_location: ${talentsInPositionLocation}`,
  });

  const renderDivider = (disableHead?: boolean) => {
    return (
      <DividerContainer>
        { !disableHead && <DividerTop /> }
        <Divider />
      </DividerContainer>
    );
  };

  const renderBarValue = (value?: number | undefined) => {
    if (value === undefined) {
      return (
        <>
          <StayTunedValue>•••</StayTunedValue>
        </>
      );
    }

    return <BarTextValue>{ numberWithCommas(value || 0) }</BarTextValue>;
  };

  const renderBar = ({
    text, value, marginLeft, opacity, height, disableDividerHead,
  }: RenderBarOptions) => {
    return (
      <ColumnContainer>
        <ColumnWrapper>
          <BarTextWrapper>
            <BarTextTitle>{ text }</BarTextTitle>
            { renderBarValue(value) }
          </BarTextWrapper>
          <BarWrapper>
            <Bar marginLeft={ marginLeft } opacity={ opacity } height={ height } />
          </BarWrapper>
        </ColumnWrapper>
        { renderDivider(disableDividerHead) }
      </ColumnContainer>
    );
  };

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_CARD_TEXT }>
        <Bold />
        {{ jobTitle }}
      </Trans>
    );
  };

  const renderCardBody = () => {
    return (
      <Container ref={ componentRef }>
        { renderDivider() }
        {
          renderBar({
            text: translate(STRING_KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_FIRST_BAR_TEXT),
            value: talentsInThisRole,
            opacity: THEME.OPACITY.DIVIDER,
          })
        }
        {
          renderBar({
            text: translate(STRING_KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_SECOND_BAR_TEXT),
            value: talentsWithRelevantBasicQualifications,
            marginLeft: true,
            opacity: THEME.OPACITY.LOW,
            height: 60,
          })
        }
        {
          talentsInPositionLocation != null &&
          renderBar({
            text: (
              <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_THIRD_BAR_TEXT }>
                <Bold />
              </Trans>
            ),
            value: talentsInPositionLocation,
            marginLeft: true,
            opacity: THEME.OPACITY.MED,
            height: 40,
          })
        }
        {
          renderBar({
            text: translate(STRING_KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_FOURTH_BAR_TEXT),
            value: talentsWillingToMove,
            marginLeft: true,
            height: 20,
            disableDividerHead: true,
          })
        }
      </Container>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="💡"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.POSITION_OVERVIEW_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
}

FunnelOverviewWidget.defaultProps = {
  somewhatWillingToMove: undefined,
  onIntersectionHandler: undefined,
};
