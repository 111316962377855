/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import log from 'loglevel';
import STRING_KEYS from '../../../language/keys';
import { CircularProgress } from '../../../components/Common/circular-loader/circular-progress.component';
import {
  COUNTER_TIME_FORMAT,
  END_TITLE,
  INITIAL_TITLE,
  LOADER_DURATION_MS,
  LOADER_INTERVAL_TIME_MS,
  STEP_DESCRIPTION_TIMES_MS,
} from './matching-progress-loader.consts';

const logger = log.getLogger('MATCHING_PROGRESS_LOADER');

export const MatchingProgressLoader = ({ positionId, positionTriggeredAt }: MatchingProgressLoaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const progressRef = useRef(STEP_DESCRIPTION_TIMES_MS.STEP1);
  const [progress, setProgress] = useState(STEP_DESCRIPTION_TIMES_MS.STEP1);

  const [title, setTitle] = useState(INITIAL_TITLE);
  const [descriptionTranslationKey, setDescriptionTranslationKey] = useState(STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ANALYZING);

  useEffect(() => {
    let currentTimeDescriptionTranslationKey;
    if (progressRef.current <= STEP_DESCRIPTION_TIMES_MS.STEP1 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP2) {
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ANALYZING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP2 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP3) {
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.LEARNING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP3 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP4) {
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.EXTRACTING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP4 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP5) {
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.CHERRY_PICKING;
    } else {
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ALMOST_THERE;
    }

    if (descriptionTranslationKey !== currentTimeDescriptionTranslationKey) {
      setDescriptionTranslationKey(currentTimeDescriptionTranslationKey);
    }
  }, [progress]);

  useEffect(() => {
    if (!positionTriggeredAt) {
      return undefined;
    }

    const initialTime = new Date(positionTriggeredAt || '').getTime();
    const timeNow = new Date().getTime();
    const progressDiff = LOADER_DURATION_MS - (timeNow - initialTime);
    const progressDiffMin = Math.min(progressDiff, LOADER_DURATION_MS);
    progressRef.current = progressDiffMin;

    if (progressDiffMin > LOADER_DURATION_MS) {
      logger.warn('Current matching progress loader is above max loader duration', {
        positionId, progressDiffMin, LOADER_DURATION_MS, initialTime, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }

    if (progressRef?.current <= 0) {
      setTitle(END_TITLE);
      setProgress(0);

      return undefined;
    }

    setProgress(progressRef?.current);
    setTitle(format(new Date(progressRef.current), COUNTER_TIME_FORMAT));

    const intervalId = setInterval(() => {
      progressRef.current -= LOADER_INTERVAL_TIME_MS;

      if (progressRef?.current <= 0) {
        clearInterval(intervalId);
        setTitle(END_TITLE);
        setProgress(0);

        return;
      }

      setProgress(progressRef?.current);

      setTitle(format(new Date(progressRef.current), COUNTER_TIME_FORMAT));
    }, LOADER_INTERVAL_TIME_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [positionTriggeredAt]);

  const calculateProgress = () => {
    if (!positionTriggeredAt) {
      return 0;
    }

    return 100 - (progress / LOADER_DURATION_MS) * 100;
  };

  const translatedDescription = useMemo(() => {
    return translate(descriptionTranslationKey);
  }, [descriptionTranslationKey]);

  return (
    <CircularProgress
      progress={ calculateProgress() }
      title={ title }
      description={ translatedDescription }
    />
  );
};
