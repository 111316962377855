import { Role } from './experience-item-role-title.styled';

type RoleTitleProps = {
  title: string,
  isSingleRole?: boolean,
}
export const RoleTitle = ({
  title,
  isSingleRole = false,
} :RoleTitleProps): JSX.Element => {
  return <Role isSingleRole={ isSingleRole }>{ title }</Role>;
};

RoleTitle.defaultProps = {
  isSingleRole: false,
};
