import { useMemo } from 'react';
import { getCompanyByIdQuery } from '../store/rtk-query/apis/company/hooks/get-company-by-id.query-hook';
import { CompanyIndustry } from '../@types/match';
import { CompanyEnrichedData } from '../@types/api';

export type CompanyInsightData = {
  companyId?: string,
  companyName: string,
  companyLogo?: string,
  companyDescription?: string,
  industries?: CompanyIndustry[],
  companyEnrichedData: CompanyEnrichedData,
}

export default function useGetCompanyById(companyInsightData: CompanyInsightData): {data: CompanyInsightData, isLoading: boolean, isError: boolean } {
  const shouldFetchCompanyData = !companyInsightData.companyEnrichedData;

  const {
    data, isLoading, isError,
  } = getCompanyByIdQuery(companyInsightData.companyId, shouldFetchCompanyData);

  const memoizedCompanyData = useMemo(() => ({
    companyId: companyInsightData.companyId,
    companyName: companyInsightData.companyName,
    companyLogo: companyInsightData.companyLogo,
    industries: companyInsightData.industries,
    companyDescription: companyInsightData.companyDescription,
    companyEnrichedData: companyInsightData.companyEnrichedData ? companyInsightData.companyEnrichedData : {
      companyLocation: data?.location,
      companyFullDescription: data?.fullDescription,
      companyFoundedAt: data?.foundedAt,
      companyLinkedinUrl: data?.linkedinUrl,
      companySize: data?.size,
      companyWebsite: data?.website,
      companyIpoStatus: data?.ipoStatus,
      companyLastFundingType: data?.lastFundingType,
    },
  }), [companyInsightData, data]);

  return {
    isLoading,
    isError,
    data: memoizedCompanyData,
  };
}
