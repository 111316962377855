import Lottie from 'react-lottie';
import loaderAnimation from '../../../../assets/lottie/fab_loader.json';
import { Container } from './dots-loader.styled';

const DotsLoader = (): JSX.Element => {
  return (
    <Container>
      <Lottie
        options={ {
          animationData: loaderAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        } }
        height={ 56 }
        width={ 200 }
      />
    </Container>
  );
};

export default DotsLoader;
