import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRef } from 'react';
import STRING_KEYS from '../../../../language/keys';
import { MatchingProgressLoader } from '../../../../pages/matching-progress-page/matching-progress-loader/matching-progress-loader.component';
import ScrollButton from '../../../../components/Common/scroll-button/scroll-button.component';
import PositionInsightsWidgetsContainer from '../../../../components/position-insights/position-insights-widgets-container/position-insights-widgets-container.component';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useGetPositionInsightsQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-insights.query-hook';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { PositionRunTriggerTypeEnum } from '../../../../enums/position-run-trigger-type.enum';
import {
  Container, DescriptionIcon, HeaderContainer, HeaderDescription, HeaderTextContainer, HeaderTitle, ScrollerWrapper,
} from './matching-progress.styled';

const MatchingProgress = ({
  showInsights, scrollButtonClickHandler,
}: MatchingProgressProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const widgetsContainerRef = useRef<HTMLDivElement>(null);
  const { data: position } = useGetPositionByIdQuery(positionId);

  const {
    data: insights,
  } = useGetPositionInsightsQuery(positionId);

  const renderText = () => {
    const isAdjustmentRun = position.latestRun?.triggerType === PositionRunTriggerTypeEnum.DeadEndRecommendation;

    const titleKey = isAdjustmentRun ?
      STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE_ADJUSTMENT :
      STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE;

    const descriptionKey = isAdjustmentRun ?
      STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION_ADJUSTMENT :
      STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION;

    return (
      <HeaderTextContainer>
        <HeaderTitle>
          { translate(titleKey) }
        </HeaderTitle>
        <HeaderDescription>
          { !isAdjustmentRun && <DescriptionIcon /> }
          { translate(descriptionKey) }
        </HeaderDescription>
      </HeaderTextContainer>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        { renderText() }
        <MatchingProgressLoader
          positionId={ position.id }
          positionTriggeredAt={ position?.latestRun?.triggeredAt }
        />
        { showInsights && (
          <ScrollerWrapper>
            <ScrollButton
              text={ translate(STRING_KEYS.INSIGHTS_COMPONENTS.INSIGHTS_AVAILABLE) }
              clickHandler={ scrollButtonClickHandler }
              anchorRef={ widgetsContainerRef }
            />
          </ScrollerWrapper>
        ) }
      </HeaderContainer>
      { showInsights && (
        <div ref={ widgetsContainerRef }>
          <PositionInsightsWidgetsContainer
            pageName={ PAGE_NAMES.POSITION_INSIGHTS_PAGE_ONBOARDING }
            positionInsights={ insights }
          />
        </div>
      ) }
    </Container>
  );
};

export default MatchingProgress;
