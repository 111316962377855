import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/lottie/app_loader.json';
import { isProduction } from '../../../../utils/environment';
import { Container, LoadingMessage } from './styled';

type AppLoaderProps = {
  title?: string,
};

const AppLoaderComponent = ({
  title,
}: AppLoaderProps) :JSX.Element => {
  const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <Lottie
        options={ loaderOptions }
        height={ 128 }
        width={ 128 }
      />

      {!isProduction() && (
        <>
          <LoadingMessage>Loading {title}...</LoadingMessage>
          <LoadingMessage>(Developer mode ON)</LoadingMessage>
        </>
      )}
    </Container>
  );
};

AppLoaderComponent.defaultProps = {
  title: '',
};

export const AppLoader = React.memo(AppLoaderComponent);
