import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';

export const Container = styled(MuiAvatar)<{
  // "$" fixed react render prop error
  $outlineWidth?: number,
  $outlineColor?: string,
  $borderWidth?: number,
  $borderRadiusPx?: number,
}>` // "$" fixed react render prop error
  outline: ${({ $outlineWidth, $outlineColor }) => $outlineWidth === 0 ?
    undefined :
    `${$outlineWidth}px solid ${$outlineColor || hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)}`};
  border: ${({ $borderWidth }) => $borderWidth === 0 ? undefined : `${$borderWidth}px solid ${THEME.COLORS.PURE_WHITE} !important`};
  transition: outline-color 0.8s ease-in;
  border-radius: ${({ $borderRadiusPx }) => $borderRadiusPx != null ? `${$borderRadiusPx}px !important` : undefined};
`;
