import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Title3Font } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

export const TopPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
`;

const getLinearGradient = (direction: 'left' | 'right'):string => {
  const linearGradientPart1 = `linear-gradient(to ${direction}, rgba(255, 255, 255, 0)`;
  const linearGradientPart2 = `5%, ${THEME.COLORS.PURE_WHITE} 100%)`;

  return `${linearGradientPart1} ${linearGradientPart2}`;
};

type ExperienceSectionProps = {
  isScrolling: boolean,
  isScrollable:boolean,
  isLeftScrollDisabled: boolean,
  isRightScrollDisabled: boolean,
};

// padding-bottom is for spacing above the scrollbar
export const ExperienceSection = styled.div<ExperienceSectionProps>`
    display: flex;
    flex-direction: row;
    margin-top: ${THEME.SPACING.LARGE};
    align-items: start;
    justify-content: start;
    overflow-x: hidden;
    user-select: none;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: rgba(48,37,133,0.4) transparent;
    width: 100%;
    margin: ${THEME.SPACING.LARGE} auto 0 auto;
    padding-bottom: ${({ isScrollable }) => isScrollable ? '24px' : '0'};

    padding-left: 180px;

    @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MEDIUM}px) {
      padding-left: 132px;
    }

    @media (max-width: ${THEME.BREAKPOINTS.WIDTH.SMALL}px) {
      padding-left: 113px;
    }

    ::-webkit-scrollbar {
      border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb {
      border: 6px solid transparent;
      border-radius: 100px;
      background-color: rgba(48,37,133,0.4);
      background-clip: content-box;
    }
    &:hover {
      cursor: ${({ isScrolling, isScrollable }) => {
    if (isScrollable) {
      return isScrolling ? 'grabbing' : 'grab';
    }

    return 'default';
  }};
      scroll-behavior: unset;
}
:before {
  visibility: ${({ isLeftScrollDisabled }) => isLeftScrollDisabled ? 'hidden' : 'visible'};
  opacity: ${({ isLeftScrollDisabled }) => isLeftScrollDisabled ? '0' : '1'};
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  content: '';
  top: 10px;
  left: 0;
  width: 300px;
  height: 70%;
  z-index: 1;
  background-image:  ${getLinearGradient('left')};
}

:after {
  visibility: ${({ isRightScrollDisabled }) => isRightScrollDisabled ? 'hidden' : 'visible'};
  opacity: ${({ isRightScrollDisabled }) => isRightScrollDisabled ? '0' : '1'};
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  content: '';
  top: 10px;
  right: 0;
  width: 300px;
  height: 70%;
  background-image:  ${getLinearGradient('right')};
}
transition: background-image 0.5s linear, visibility 0.5s linear;

`;

export const DividerContainer = styled.div`
  margin-top: ${THEME.SPACING.MEDIUM};
  margin-bottom: ${THEME.SPACING.LOOSE};
`;

export const Header = styled.div`
  display: flex;
  opacity: ${THEME.OPACITY.LOW};
  ${Title3Font};
  font-weight: 500;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.XLOOSE};
  :before {
    content: url(${ASSETS.WORK_ICON});
    margin-right: ${THEME.SPACING.TINY};
  }
`;

export const ExperienceContainer = styled.div`
`;
