import {
  SequenceCustomStepSenderTypeEnum,
} from '../../../../../../store/rtk-query/apis/sequence/enums/sequence-custom-step-sender-type.enum';

export const MAX_CUSTOM_STEPS = 10;

export const EMPTY_STEP = {
  days: '3',
  time: '10:30',
  type: SequenceCustomStepSenderTypeEnum.Email,
  sendAsReply: true,
  isNotSavedStep: true,
  isWorkDays: true,
  initSubject: '',
  initMessage: '',
};

export const FIRST_TIME_DAYS = '0';
