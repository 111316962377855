import {
  CardBody,
  CardContainer,
  CardHeader,
  CardText,
  CardIcon,
  CardTitle,
  CardInfoWrapper,
  Logo,
} from './base-widget-card.styled';

interface BaseWidgetCardProps {
  cardIcon: string;
  cardTitle: string;
  logo?: string;
  cardText: JSX.Element;
  cardBody: JSX.Element;
}

export const BaseWidgetCard = ({
  cardIcon,
  cardTitle,
  logo,
  cardText,
  cardBody,
}:BaseWidgetCardProps): JSX.Element => {
  return (
    <CardContainer>
      <CardHeader>
        <div>
          <CardInfoWrapper>
            <CardIcon>{cardIcon}</CardIcon>
            <CardTitle>
              {cardTitle}
            </CardTitle>
          </CardInfoWrapper>
        </div>
        { logo && <Logo src={ logo } /> }
      </CardHeader>

      <CardText>
        { cardText }
      </CardText>

      <CardBody>
        { cardBody }
      </CardBody>
    </CardContainer>
  );
};

BaseWidgetCard.defaultProps = {
  logo: undefined,
};
