/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useMapConversationsById } from '../../../../../hooks/conversations-map-by-id.hook';
import {
  getConversationItemProps,
  sortMinimizedConversationByLastAction,
  sortMinimizedConversationByNextScheduleTime,
  sortMinimizedConversationByNextTaskTime,
} from '../../conversation-group.util';
import STRING_KEYS from '../../../../../../../language/keys';
import { ConversationMinimizedResponseDto } from '../../../../../../../store/rtk-query/apis/outreach/dto/response/conversation-minimized.response';
import { ConversationItemType } from '../../../conversation-item/conversation-item.enums';
import { ConversationsItemsProps } from './conversation-items';
import {
  ConversationItemsContainer,
  GroupContainer,
  GroupTitle,
  ScrollWrapper,
  StyleItem,
  Title,
} from './conversation-items.styled';

type NestedItemsGroupsType = {
  tasks: ConversationMinimizedResponseDto[];
  scheduled: ConversationMinimizedResponseDto[];
  other: ConversationMinimizedResponseDto[];
};

const getDefaultNestedItemsGroups = () => ({
  tasks: [] as ConversationMinimizedResponseDto[],
  scheduled: [] as ConversationMinimizedResponseDto[],
  other: [] as ConversationMinimizedResponseDto[],
});

const ConversationItemsAutoMode = ({ selectedConversationIds, conversations }: ConversationsItemsProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const conversationByIdMap = useMapConversationsById(conversations);
  const nestedConversationsByGroup = useMemo<NestedItemsGroupsType>(() => {
    return selectedConversationIds?.reduce((acc, conversationId) => {
      const conversation = conversationByIdMap.get(conversationId);
      if (conversation) {
        if (conversation?.nextOpenLinkedinTask) {
          acc.tasks.push(conversation);
        } else if (conversation?.nextScheduledMessage) {
          acc.scheduled.push(conversation);
        } else {
          acc.other.push(conversation);
        }
      }

      return acc;
    }, getDefaultNestedItemsGroups()) || getDefaultNestedItemsGroups();
  }, [conversationByIdMap, selectedConversationIds]);

  const renderConversationItems = useCallback((activeConversations: ConversationMinimizedResponseDto[], sortType?: ConversationItemType) => {
    const sortFunctionByType =
      // eslint-disable-next-line no-nested-ternary
      sortType === ConversationItemType.Scheduled ? sortMinimizedConversationByNextScheduleTime :
        sortType === ConversationItemType.Task ? sortMinimizedConversationByNextTaskTime :
          sortMinimizedConversationByLastAction;
    activeConversations.sort(sortFunctionByType);

    return activeConversations.map((conversation, index) => {
      if (conversation) {
        const {
          type,
          url,
          fullName,
          conversationMessage,
        } = getConversationItemProps(conversation, true);

        let sequenceStep;
        if (type === ConversationItemType.Scheduled) {
          sequenceStep = conversation.nextScheduledMessage?.sequenceStep;
        } else if (type === ConversationItemType.Task) {
          sequenceStep = conversation.nextOpenLinkedinTask?.sequenceStep;
        } else if (type === ConversationItemType.Default) {
          sequenceStep = conversation.sequenceLength ? conversation.sequenceLength + 1 : undefined;
        }

        return (
          <StyleItem
            positionId={ positionId }
            key={ `${conversation.id}_${index}` }
            conversationId={ conversation.id }
            type={ type }
            url={ url }
            fullName={ fullName }
            conversationMessage={ conversationMessage }
            isAutoMode
            totalSteps={ conversation.sequenceLength }
            completedSteps={ sequenceStep ? sequenceStep - 1 : undefined }
          />
        );
      }

      return undefined;
    });
  }, [positionId]);

  if (!selectedConversationIds) {
    return null;
  }

  return (
    <ConversationItemsContainer>
      <Title>
        { translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.NESTED_PANEL.TITLE,
          { number: selectedConversationIds.length },
        ) }
      </Title>
      <ScrollWrapper>
        {!!(nestedConversationsByGroup.tasks.length) && (
          <GroupContainer>
            <GroupTitle>{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.NESTED_PANEL.OPEN_TASKS_TITLE) }</GroupTitle>
            { renderConversationItems(nestedConversationsByGroup.tasks, ConversationItemType.Task) }
          </GroupContainer>
        )}
        {!!(nestedConversationsByGroup.scheduled.length + nestedConversationsByGroup.other.length) && (
          <GroupContainer>
            <GroupTitle>{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.NESTED_PANEL.CONVERSATIONS_TITLE) }</GroupTitle>
            { renderConversationItems(nestedConversationsByGroup.scheduled, ConversationItemType.Scheduled) }
            { renderConversationItems(nestedConversationsByGroup.other) }
          </GroupContainer>
        )}
      </ScrollWrapper>
    </ConversationItemsContainer>
  );
};

export default ConversationItemsAutoMode;
