import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import BaseInput from '../../../../../../../components/Common/perfect-base-input/perfect-base-input.component';
import { Subtitle2Font } from '../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  padding: 0 ${THEME.SPACING.BASE};
  align-items: center;
  justify-content: space-between;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  user-select: none;
`;

export const Label = styled.div`
  ${Subtitle2Font};
  line-height: 1.71;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW)};
`;

export const InputContainer = styled(BaseInput)`
  width: 96px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  };
  input[type=number] {
    -moz-appearance: textfield;
  };
`;
