import styled  from 'styled-components';
import { THEME } from '../../../../themes';
import IconButton from '../../../../components/Common/Buttons/icon-button/icon-button.component';
import { drawerElevation } from '../../../../themes/elevations';

export const Container = styled.div`
  overflow: auto;
  height: 100vh;
`;

export const PositionIntroductionScheduleMeetingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const MatcherFab = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 32px;
  z-index: 101;
  left: 36px;
  gap: ${THEME.SPACING.TINO};
`;

export const EditPositionButton = styled(IconButton)`
  color: ${THEME.COLORS.ACCENT};
  background-color: ${THEME.COLORS.LIGHT_PINK};
  border-radius: ${THEME.RADIUS.LOOPY};
  border: 4px solid ${THEME.COLORS.PURE_WHITE};
  width: 56px;
  height: 56px;
  ${drawerElevation};
`;
