import styled from 'styled-components';
import { transparentize } from '../../../themes/theme.utils';
import { THEME } from '../../../themes';

export const Bold = styled.span`
  font-weight: bold;
`;

export const PositionTitlesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.TINY};
`;

export const PositionTitleItem = styled.span<{ opacity: number }>`
  background-color: ${({ opacity }) => transparentize(THEME.COLORS.PRIMARY, opacity)};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  color: ${({ opacity }) => opacity > 0.4 ? THEME.COLORS.PURE_WHITE : THEME.COLORS.PRIMARY};
  font-size: 14px;
  height: fit-content;
  border-radius: ${THEME.RADIUS.BASE};
`;
