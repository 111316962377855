import {
  Container, Progress, Section, Svg, Text, TextContainer, Title, Track,
} from './circular-progress.styled';

export const CircularProgress = ({ progress, title, description }: CircularProgressProps): JSX.Element => {
  return (
    <Section>
      <Container>
        <TextContainer>
          <Title>{ title }</Title>
          <Text>{ description }</Text>
        </TextContainer>
        <Svg>
          <Track cx="100" cy="100" r="90" />
          <Progress cx="100" cy="100" r="90" progress={ progress } />
        </Svg>
      </Container>
    </Section>
  );
};
