import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import {
  PromotedBadgeContainer,
  PromotionLabel,
} from './experience-item-promoted-badge.styled';

export const PromotedBadge = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <PromotedBadgeContainer >
      <PromotionLabel>
        {translate(STRING_KEYS.PROMOTION)}
      </PromotionLabel>
    </PromotedBadgeContainer>
  );
};
