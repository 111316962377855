import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import { Body2Font, HeadlineFont, Subtitle1Font } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';
import { hexToRgbA } from '../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const TitleIcon = styled(ASSETS.VERTICAL_SETTINGS)`
  height: 40px;
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  width: fit-content;
`;

export const SectionItemTitle = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
`;

export const TextInputCommonStyle = css`
  ${Body2Font};
  padding: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  background-color: ${hexToRgbA(THEME.COLORS.LIGHT_GREY)};
  resize: none;
  ::placeholder {
    opacity: ${THEME.OPACITY.MED};
    line-height: 1.57;
    letter-spacing: 0.25px;
    color: ${THEME.COLORS.CONTENT_A};
  };
  :focus {
    outline: none;
  }
`;
