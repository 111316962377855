import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../../utils/colors';
import { CaptionFont, Subtitle1Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';
import { OutreachBannerSizeEnum } from './outreach-banner-type.enum';

export const SMALL_BANNER_HEIGHT_PX = 40;
export const MEDIUM_BANNER_HEIGHT_PX = 56;
export const LARGE_BANNER_HEIGHT_PX = 80;

const getHeight = (size: OutreachBannerSizeEnum) => {
  switch (size) {
    case OutreachBannerSizeEnum.Small:
      return `${SMALL_BANNER_HEIGHT_PX}px`;
    case OutreachBannerSizeEnum.Medium:
      return `${MEDIUM_BANNER_HEIGHT_PX}px`;
    case OutreachBannerSizeEnum.Large:
    default:
      return `${LARGE_BANNER_HEIGHT_PX}px`;
  }
};

export const BannerLottieWrapper = styled.div`
  height: 65px;
`;

export const BannerActionButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.SECONDARY};
  font-weight: 600;
`;

export const Container = styled.div<{
  color: string,
  size: OutreachBannerSizeEnum,
  actionButtonColor?: string,
}>`
  min-height: ${({ size }) => getHeight(size)};
  width: 100%;
  padding: 0 ${THEME.SPACING.LOOSE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ color }) => hexToRgbA(color, THEME.OPACITY.DIVIDER)};
  
  & svg {
    color: ${({ color }) => color};
  }

  & ${BannerActionButton} {
    color: ${({ color, actionButtonColor }) => actionButtonColor || color};
  }
`;

export const ConversationBannerStartWrapper = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
`;

export const ConversationBannerTitle = styled.div`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  font-weight: 600;
  margin-bottom: ${THEME.SPACING.MICRO};
`;

export const ConversationBannerDescription = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const OutreachIcon = styled(ASSETS.OUTREACH_ICON)`
  fill: ${THEME.COLORS.SECONDARY};
  height: 32px;
`;

export const PauseIcon = styled(ASSETS.PAUSE)`
  height: 24px;
`;

export const ContactSupportLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
