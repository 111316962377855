import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { BodyText, Container, Title } from './position-no-results-error.styled';

const PositionNoResultsError = ():JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Title>
        <div>{ translate(STRING_KEYS.POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE.TITLE) }</div>
        <div>🏋️</div>
      </Title>
      <BodyText>{ translate(STRING_KEYS.POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE.SUBTITLE) }</BodyText>
    </Container>
  );
};

export default PositionNoResultsError;
