import styled from 'styled-components';
import { THEME } from '../../themes';
import { Body2Font } from '../../themes/fonts';

export const TALENTS_ROW_HEIGHT = 80;

type ContainerProps = {
  isLeftScrollDisabled: boolean,
  isRightScrollDisabled: boolean,
  isDisabled: boolean,
};

const getLinearGradient = (direction: 'left' | 'right'):string => {
  const linearGradientPart1 = `linear-gradient(to ${direction}, rgba(255, 255, 255, 0)`;
  const linearGradientPart2 = `50%, ${THEME.COLORS.PURE_WHITE} 100%)`;

  return `${linearGradientPart1} ${linearGradientPart2}`;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${TALENTS_ROW_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${THEME.SPACING.LOOSE};
  border-bottom: solid 1px ${THEME.COLORS_RGB.PRIMARY_GHOST};
  pointer-events: ${({ isDisabled }) => isDisabled ? 'none' : 'all'};
  position: relative;
  background-color: ${THEME.COLORS.PURE_WHITE};
  :after {
    visibility: ${({ isRightScrollDisabled }) => isRightScrollDisabled ? 'hidden' : 'visible'};
    opacity: ${({ isRightScrollDisabled }) => isRightScrollDisabled ? '0' : '1'};
    transition: visibility 0s, opacity 0.5s linear;
    position: absolute;
    content: '';
    top: 0;
    right: 128px;
    width: 550px;
    height: 80px;
    pointer-events: none;
    background-image: ${getLinearGradient('right')};
  }

  :before {
    visibility: ${({ isLeftScrollDisabled }) => isLeftScrollDisabled ? 'hidden' : 'visible'};
    opacity: ${({ isLeftScrollDisabled }) => isLeftScrollDisabled ? '0' : '1'};
    transition: visibility 0s, opacity 0.5s linear;
    position: absolute;
    z-index: 1;
    content: '';
    top: 0;
    left: 220px;
    width: 550px;
    height: 80px;
    pointer-events:none;
    background-image:  ${getLinearGradient('left')};
  }
`;

export const AvatarsContainer = styled.div`
  flex:1;
  height: 100%;
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: ${THEME.SPACING.BASE};
`;

export const StyledButton = styled.button`
  width: 48px;
  height: 48px;
  cursor: pointer;
  padding: ${THEME.SPACING.TINO};
  border-radius: ${THEME.RADIUS.CURVY};
  margin-right: ${THEME.SPACING.TINY};
  margin-left: ${THEME.SPACING.TINY};
  border: solid 1px rgba(48, 37, 133, 0.12);
  background-color: ${THEME.COLORS.CONTENT_INVERSE};
  position: relative;

  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  z-index: 1;
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CIRCLE} 0 0 ${THEME.RADIUS.CIRCLE};
`;

export const EmptyAvatarsMessage = styled.div`
  display: flex;
  margin-left: ${THEME.SPACING.BASE};
  ${Body2Font};
  color:  ${THEME.COLORS.PRIMARY_DARK};
`;

export const ClearFilterButton = styled.div`
  display: flex;
  ${Body2Font};
  font-weight: 600;
  text-align: center;
  margin-left: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.ACCENT};
  cursor: pointer;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SkeletonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const SkeletonWrapper = styled.div`
  left: 16px;
`;
