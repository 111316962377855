import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { Body2Font, HeadlineFont } from '../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.LOOSE};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const TitleIcon = styled(ASSETS.SPICES)`
  height: 40px;
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  width: fit-content;
`;

export const Description = styled.div`
  ${Body2Font};
  line-height: 1.57;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.LARGE};
`;

export const CheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 8px;
  height: 8px;
  fill: ${THEME.COLORS.SECONDARY};
`;

export const UnCheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 8px;
  height: 8px;
  fill: ${THEME.COLORS.GREY};
`;
