import styled from 'styled-components';
import { THEME } from '../../../themes';
import { OverlineFont } from '../../../themes/fonts';

export const AnimationWrapper =  styled.div`
  height: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: ${THEME.OPACITY.LOW};
`;

export const Text = styled.div`
  ${OverlineFont};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: ${THEME.SPACING.BASE};
`;
