import { useState } from 'react';
import { Sentence } from '../../../../components/Common/sentence-component/sentence.component';
import GuidanceModal from '../../../../components/dialogs/guidance-modal/guidance-modal.component';
import { SKIP_ITEM_ID } from '../../utils/create-position-job-requerments.util';
import { Container } from './multi-sentence-inputs-container.styled';
import { MultiSentenceInputsContainerProps } from './multi-sentence-inputs-container';
import SentenceInput from './sentence-input/sentence-input.component';

const MultiSentenceInputsContainer = ({
  inputElementId,
  values,
  placeholder,
  secondaryPlaceholder,
  sentenceText = '',
  addHandler,
  showInstructionMessage = true,
  changeHandler,
  autoFocus,
  skipItemTitle,
  guidanceModalData,
  completeTypingHandler,
  maxItems,
}: MultiSentenceInputsContainerProps): JSX.Element => {
  const [isGuidanceModalOpen, setIsGuidanceModalOpen] = useState<boolean>(false);

  const onAddButtonClick = () => {
    if (maxItems && values.length >= maxItems) {
      return;
    }

    addHandler?.();
  };

  const onRemoveButtonClick = (index: number) => {
    const sliced = values.slice();
    sliced.splice(index, 1);
    changeHandler(sliced);
  };

  const onGuidanceButtonClick = () => {
    setIsGuidanceModalOpen(true);
  };

  const showAddButton = (index: number) => {
    const value = values[index]?.title;
    const isSkipItem = values[index]?.id === SKIP_ITEM_ID;

    return !!value && !isSkipItem && index === values.length - 1 && (!maxItems || values.length < maxItems);
  };

  const onItemChange = (value: string, index: number) => {
    const sliced = values.slice();
    const id = value === skipItemTitle ? SKIP_ITEM_ID : undefined;
    sliced[index] = { id, title: value, selected: true };
    changeHandler(sliced);
  };

  const onSkipButtonClick = () => {
    changeHandler([{ title: skipItemTitle, selected: true, id: SKIP_ITEM_ID }]);
  };

  return (
    <>
      <Container>
        { Array.from(Array(values.length || 1).keys()).map((index) => (
          <Sentence
            key={ `multi_sentence_input_${index}` }
            text={ index === 0 ? sentenceText : '+' }
            completeHandler={ () => completeTypingHandler?.() }
            Item={ (
              <SentenceInput
                inputElementId={ `${inputElementId}_${index}` }
                value={ values[index]?.title }
                placeholder={ index === 0 ? placeholder : (secondaryPlaceholder || placeholder) }
                changeHandler={ (value) => onItemChange(value, index) }
                addClickHandler={ onAddButtonClick }
                showInstructionMessage={ showInstructionMessage }
                guidanceButtonClickHandler={ onGuidanceButtonClick }
                showAddButton={ showAddButton(index) }
                removeClickHandler={ () => onRemoveButtonClick(index) }
                showRemoveButton={ index > 0 }
                autoFocus={ autoFocus }
                skipButtonClickHandler={ onSkipButtonClick }
                showSkipButton={ index === 0 }
              />
            ) }
          />
        ))}
      </Container>
      { guidanceModalData && isGuidanceModalOpen && (
        <GuidanceModal
          isOpen={ isGuidanceModalOpen }
          closeHandler={ () => setIsGuidanceModalOpen(false) }
          zIndex={ 1302 }
          title={ guidanceModalData.title }
          data={ guidanceModalData.data }
        />
      ) }
    </>
  );
};

export default MultiSentenceInputsContainer;
