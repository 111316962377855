import styled from 'styled-components';
import { THEME } from '../../../themes';

export const CardContainer = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.LOOSE};
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${THEME.SPACING.BASE};
  align-items: center;
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  font-size: 20px;
  margin-right: ${THEME.SPACING.TINY};
`;

export const CardTitle = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${THEME.COLORS.TRUE_BLACK};
  opacity: 0.6;
  letter-spacing: 1.5px;
`;

export const Logo = styled.img`
  height: 16px;
`;

export const CardText = styled.div`
  margin-bottom: ${THEME.SPACING.MEDIUM};
  letter-spacing: 0.15px;
  font-size: 20px;
`;

export const CardBody = styled.div`
`;
