import styled, { css } from 'styled-components';
import { TALENTS_ROW_HEIGHT } from '../../components/talents-preview-top-bar/talents-preview-top-bar.styled';
import { THEME } from '../../themes';
import { HEADER_HEIGHT } from '../../components/Common/headers/header/header.styled';
import BaseButton from '../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const MATCH_PAGE_HEADER_HEIGHT = 160;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SPACE_BANNER = 64;

export const ContentContainer = styled.div<{ isShowBanner: boolean}>`
  width: 100%;
  padding-bottom: ${(props) =>  props.isShowBanner ? SPACE_BANNER : 0}px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// center floating element
// https://stackoverflow.com/a/32694476
const BASE_SPACE = 32;

export const FabContainer = css<{ isShowBanner: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: ${(props) => props.isShowBanner ? BASE_SPACE + SPACE_BANNER : BASE_SPACE}px;
  z-index: 101;
  height: 80px;
  box-shadow: 0 4px 24px 0 rgba(46, 37, 128, 0.08);
  border-radius: ${THEME.SPACING.XLOOSE};
  border: solid 1px ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
`;

export const EngagementFloatingButtonContainer = styled.div<{isShowBanner: boolean}>`
  ${FabContainer};
  left: 50%;
  transform: translateX(-50%);
`;

export const DeclineWrapper = styled.div`
  height: calc(100vh - (${HEADER_HEIGHT + TALENTS_ROW_HEIGHT}px));
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 105;
`;

export const EditPositionFab = styled.div<{isShowBanner: boolean}>`
  ${FabContainer};
  width: 235px;
  left: 36px;
`;

export const EditPositionButton = styled(BaseButton)`
  width: 100%;
  color: ${THEME.COLORS.PRIMARY_DARK};
  background-color: ${THEME.COLORS.LIGHT_GREY};
`;
