import { AvatarGroup } from '@mui/material';
import { useMemo } from 'react';
import { THEME } from '../../../themes';
import StyledAvatar from '../styled-avatar/styled-avatar.component';
import { StyledIconsSection } from './styled';

const MAX_AVATARS_IN_GROUP = 6;

type IconsSectionProps = {
  icons:Array<UserProfileImage>,
  gap?: string,
  justifyContent?: string,
  shouldSlice?: boolean,
  maxNumberInGroup?: number,
  iconSize?: number,
  iconBorderWidthPx?: number;
  iconOutlineWidthPx?: number;
  iconOutlineColor?: string;
  spacing?: number;
};

function AvatarsList({
  icons,
  gap,
  justifyContent,
  shouldSlice,
  maxNumberInGroup,
  iconSize,
  iconBorderWidthPx = 2,
  iconOutlineWidthPx = 0,
  iconOutlineColor,
  spacing,
}: IconsSectionProps): JSX.Element {
  const slicedIcons = useMemo(() => {
    if (shouldSlice) {
      return [...icons].slice(0, maxNumberInGroup || MAX_AVATARS_IN_GROUP);
    }

    return icons;
  }, [icons, maxNumberInGroup, shouldSlice]);

  const avatarGroupSx = {
    '& .MuiAvatar-root': {
      width: iconSize || 32,
      height: iconSize || 32,
      fontSize: 12,
      backgroundColor: THEME.COLORS.PRIMARY,
      border: iconBorderWidthPx,
      borderColor: THEME.COLORS.PURE_WHITE,
    },
  };

  return (
    <StyledIconsSection justifyContent={ justifyContent }>
      <AvatarGroup
        max={ maxNumberInGroup || MAX_AVATARS_IN_GROUP }
        spacing={ spacing }
        style={ { gap } }
        sx={ avatarGroupSx }
      >
        {slicedIcons.map((item: UserProfileImage) => {
          const profileImage = item?.profileImage || '';

          return (
            <StyledAvatar
              key={ `${profileImage}-${item.userName}` }
              name={ item.userName }
              initialsFontSizePx={ 16 }
              image={ profileImage }
              outlineWidthPx={ iconOutlineWidthPx }
              outlineColor={ iconOutlineColor }
              borderWidthPx={ 0 }
            />
          );
        })}
      </AvatarGroup>
    </StyledIconsSection>
  );
}

AvatarsList.defaultProps = {
  gap: undefined,
  justifyContent: undefined,
  shouldSlice: false,
  maxNumberInGroup: MAX_AVATARS_IN_GROUP,
  iconSize: undefined,
  iconBorderWidthPx: undefined,
  iconOutlineWidthPx: undefined,
  iconOutlineColor: undefined,
  spacing: undefined,
};

export default AvatarsList;
