import { SkeletonTheme } from 'react-loading-skeleton';
import { THEME } from '../../../../../themes';
import { ExperienceChapter } from './experience-information-chapter/experience-information-chapter.component';
import {
  Container,
  Title,
} from './experience-information-skeleton.styled';

const ExperienceSkeleton = (): JSX.Element => {
  return  (
    <Container>
      <SkeletonTheme
        baseColor={ THEME.COLORS.LIGHT_GREY }
        highlightColor={ THEME.COLORS.SURFACE_PRIMARY }
      >
        <Title />
        <ExperienceChapter />
        <ExperienceChapter />
        <ExperienceChapter />
        <ExperienceChapter />
      </SkeletonTheme>
    </Container>
  );
};

export default ExperienceSkeleton;
