import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

const shake = keyframes`
  from {
    transform: scale(1);
  }
  10% {
    transform: scale(0.91);
  }
  17% {
    transform: scale(0.98);
  }
  33% {
    transform: scale(0.87);
  }
  45% {
    transform: scale(1);
  }
`;

export const ContinueButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  color: ${THEME.COLORS.PURE_WHITE};
  width: 160px;
`;

export const ButtonWrapper = styled.div<{
  enabled?: boolean,
}>`
  ${({ enabled }) => enabled ?
    css`animation: ${shake} 2.5s infinite ease-out both` : ''};
`;
