import styled, { css } from 'styled-components';
import { THEME } from '../../../themes';
import { BaseSwitchSize } from './base-switch.enums';

export const MediumContainerSize = css`
  width: 38px;
  height: 26px;
`;

export const SmallContainerSize = css`
  width: 27px;
  height: 17px;
`;

export const Container = styled.div<{ checked: boolean, checkedColor?: string, disabled: boolean, size: BaseSwitchSize }>`
  ${({ size }) => size === BaseSwitchSize.Small ? SmallContainerSize : MediumContainerSize};
  position: relative;
  background-color: ${THEME.COLORS.SECONDARY};
  background-color: ${({ checked, checkedColor }) => checked ? (checkedColor || THEME.COLORS.SECONDARY) : THEME.COLORS.GREY};
  border-radius: ${THEME.RADIUS.CIRCLE};
  cursor: pointer;
  padding-left: 1px;
  transition: background-color 100ms ease-in-out;
  flex-shrink: 0;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: ${THEME.OPACITY.LOW};
        pointer-events: none;
      `;
    }
    
    return undefined;
  }}
`;

export const MediumToggleSize = css<{ checked: boolean }>`
  width: 22px;
  height: 22px;
  top: 2px;
  left: ${({ checked }) => checked ? '14px' : '2px'};
`;

export const SmallToggleSize = css<{ checked: boolean }>`
  left: ${({ checked }) => checked ? '11px' : '1px'};
  width: 15px;
  height: 15px;
  top: 1px;
`;

export const Toggle = styled.div<{ checked: boolean, size: BaseSwitchSize }>`
  ${({ size }) => size === BaseSwitchSize.Small ? SmallToggleSize : MediumToggleSize};
  border-radius: ${THEME.RADIUS.CIRCLE};
  position: absolute;
  transition: left 100ms ease-in-out;
  background-color: ${THEME.COLORS.PURE_WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
`;
