import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Modal = styled.div<{modalPosition?: string}>`
  background: ${THEME.COLORS.PURE_WHITE};
  position: ${({ modalPosition }) => modalPosition ? 'absolute' : 'relative'};
  ${({ modalPosition }) => modalPosition ? `${modalPosition}: 40px` : ''};
  left: -10px;
  z-index: 2;

  width: 720px;
  min-height: 260px;
  padding:  ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
  border-radius: ${THEME.RADIUS.ROUND};
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${THEME.COLORS.LIGHT_GREY};

  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};

  animation: show 0.3s;
  animation-fill-mode: forwards;

  @keyframes show{
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`;
