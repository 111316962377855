import styled from 'styled-components';
import { Body2Font } from '../../../../themes/fonts';

export const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LoadingMessage = styled.div`
    ${Body2Font}    
`;
