/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Sentence } from '../../../../components/Common/sentence-component/sentence.component';
import {
  addCompanyBackground,
  addSkill,
  setCompanyBackground,
  setSkills,
} from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { usePositionBackgroundSelector } from '../../../../store/slices/create-position-state/selectors/create-position-background.selector';
import { CreatePositionContainer } from '../create-position-container/create-position-container.component';
import STRING_KEYS from '../../../../language/keys';
import { useGetStepInfoComponent } from '../../hooks/create-position-actions.hooks';
import { useGetCurrentStep } from '../../../onboarding/hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../enums/create-position-steps.enums';
import {
  useOnboardingContinueWatcherButton,
  useOnboardingSkipWatcherButton,
} from '../../../onboarding/hooks/onboarding-actions.hooks';
import {
  BACKGROUND_GUIDANCE_MODAL_DATA,
  SKILLS_GUIDANCE_MODAL_DATA,
} from '../../../../components/dialogs/guidance-modal/guidance-modal.consts';
import { CREATE_POSITION_INPUT_ID } from '../../consts/input-ids.consts';
import MultiSentenceInputsContainer from '../multi-sentence-inputs/multi-sentence-inputs-container.component';
import { Body, BoldText, GrayedOutContainer } from './create-position-background-step.styled';

const MAX_COMPANY_BACKGROUND_ITEMS = 3;

const CreatePositionBackgroundStep = ({
  setContinueButtonEnable,
  setContinueButtonDisable,
  isOnboarding,
}: CreatePositionBackgroundStepProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  useOnboardingContinueWatcherButton(currentStep);
  useOnboardingSkipWatcherButton();
  const [isIntroductionCompleted, setIsIntroductionCompleted] = useState<boolean>(false);
  const [isBackgroundSentenceCompleted, setIsBackgroundSentenceCompleted] = useState<boolean>(false);
  const { companyBackground, skills } = usePositionBackgroundSelector();
  const InfoComponent = useGetStepInfoComponent();

  useEffect(() => {
    const hasBackground = companyBackground.some((background) => !!background.title);
    const hasSkill = skills.some((skill) => !!skill.title);
    if (hasBackground || hasSkill) {
      dispatch(setContinueButtonEnable());
    } else {
      dispatch(setContinueButtonDisable());
    }
  }, [companyBackground, skills]);

  const onCompanyBackgroundChange = (backgrounds: PositionJobRequirementState[]) => {
    dispatch(setCompanyBackground(backgrounds));
  };

  const onAddCompanyBackgroundButtonClick = () => {
    dispatch(addCompanyBackground());
  };

  const onSkillsChange = (newSkills: PositionJobRequirementState[]) => {
    dispatch(setSkills(newSkills));
  };

  const onAddSkillButtonClick = () => {
    dispatch(addSkill());
  };

  return (
    <CreatePositionContainer
      title={ <Trans i18nKey={ STRING_KEYS.ONBOARDING.BACKGROUND_STEP.TITLE }><BoldText /></Trans> }
      info={ InfoComponent }
      isOnboarding={ isOnboarding }
    >
      <Body>
        <Sentence
          text={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.SENTENCE_INTRO) }
          completeHandler={ () => setIsIntroductionCompleted(true) }
        />
        { isIntroductionCompleted && (
          <div>
            <MultiSentenceInputsContainer
              inputElementId={ CREATE_POSITION_INPUT_ID.COMPANY_BACKGROUND }
              values={ companyBackground }
              placeholder={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.INPUT_PLACEHOLDER) }
              secondaryPlaceholder={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.SECONDARY_INPUT_PLACEHOLDER) }
              sentenceText={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.SENTENCE_TEXT) }
              addHandler={ onAddCompanyBackgroundButtonClick }
              changeHandler={ onCompanyBackgroundChange }
              completeTypingHandler={ () => setIsBackgroundSentenceCompleted(true) }
              autoFocus
              skipItemTitle={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.BLANK_INPUT_TEXT) }
              maxItems={ MAX_COMPANY_BACKGROUND_ITEMS }
              guidanceModalData={ {
                title: translate(STRING_KEYS.GUIDANCE_MODAL.COMPANY_BACKGROUND_MODAL_TITLE),
                data: BACKGROUND_GUIDANCE_MODAL_DATA,
              } }
            />
          </div>
        ) }
        { isBackgroundSentenceCompleted && (
          <GrayedOutContainer isGrayedOut={ companyBackground.length === 0 } >
            <MultiSentenceInputsContainer
              inputElementId={ CREATE_POSITION_INPUT_ID.SKILL }
              values={ skills }
              placeholder={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.INPUT_PLACEHOLDER) }
              secondaryPlaceholder={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.SECONDARY_INPUT_PLACEHOLDER) }
              sentenceText={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.SENTENCE_TEXT) }
              addHandler={ onAddSkillButtonClick }
              changeHandler={ onSkillsChange }
              autoFocus={ companyBackground.length > 0 }
              skipItemTitle={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.BLANK_INPUT_TEXT) }
              maxItems={ MAX_COMPANY_BACKGROUND_ITEMS }
              guidanceModalData={ {
                title: translate(STRING_KEYS.GUIDANCE_MODAL.SKILLS_MODAL_TITLE),
                data: SKILLS_GUIDANCE_MODAL_DATA,
              } }
            />
          </GrayedOutContainer>
        ) }
      </Body>
    </CreatePositionContainer>
  );
};

export default CreatePositionBackgroundStep;
