import { Trans, useTranslation } from 'react-i18next';
import { ClickAwayListener, Popper } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import CompanyInsightsModal from '../../Common/CompanyInsightsModal';
import { InsightList, InsightListItem } from '../base-widget-card/insight-list/insight-list.component';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import STRING_KEYS from '../../../language/keys';
import { ASSETS } from '../../../assets';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { Bold } from './previous-companies.styled';

export interface PreviousCompaniesWidgetProps {
  companyName: string;
  companyLogo?: string;
  companies: InsightListItem[];
  onLearnMoreClick?: () => void;
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const PreviousCompanies = ({
  companyName,
  companyLogo,
  companies,
  onIntersectionHandler,
}:PreviousCompaniesWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [companyModalAnchorEl, setCompanyModalAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = React.useState<null | InsightListItem>(null);
  const isCompanyModalOpen = Boolean(companyModalAnchorEl);

  const componentRef = useRef(null);
  useInsightWidgetIntersection({
    componentRef,
    onIntersectionHandler,
    metadata: companies?.map((company) => company.text).join(','),
    metadataType: 'previous companies array',
  });

  const memoizedCompanyData = useMemo(() => (selectedItem ? {
    companyId: selectedItem?.id,
    companyName: selectedItem?.text,
    companyLogo: selectedItem?.icon,
    industries: selectedItem?.industries || [],
    companyEnrichedData: selectedItem?.companyEnrichedData || {},
  } : null), [selectedItem]);

  const onCompanyItemClick = (item: InsightListItem, currentTarget: HTMLElement) => {
    setSelectedItem(item);
    setCompanyModalAnchorEl(currentTarget);
  };

  const closeCompanyModal = () => {
    setSelectedItem(null);
    setCompanyModalAnchorEl(null);
  };

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.PREVIOUS_COMPANIES_CARD_TEXT }>
        <Bold />
        {{ companyName }}
      </Trans>
    );
  };

  const renderCardBody = () => {
    return (
      <div ref={ componentRef }>
        { isCompanyModalOpen && memoizedCompanyData && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={ closeCompanyModal }
          >
            <Popper
              placement="bottom-start"
              open={ isCompanyModalOpen }
              anchorEl={ companyModalAnchorEl }
              modifiers={ [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ] }
            >
              <CompanyInsightsModal
                companyData={ memoizedCompanyData }
              />
            </Popper>
          </ClickAwayListener>
        )}

        <InsightList
          items={ companies }
          onLearnMoreClick={ onCompanyItemClick }
          activeItem={ selectedItem?.id }
          iconFallback={ ASSETS.EXPERIENCE_FALLBACK }
        />
      </div>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="🏢"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.COMPANY_INSIGHTS_CARD_TITLE) }
      logo={ companyLogo }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

PreviousCompanies.defaultProps = {
  companyLogo: undefined,
  onLearnMoreClick: undefined,
};
