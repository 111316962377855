import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { InsightList, InsightListItem } from '../base-widget-card/insight-list/insight-list.component';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import STRING_KEYS from '../../../language/keys';
import { ASSETS } from '../../../assets';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { AccentBold, Bold } from './education-schools-widget.styled';

export interface EducationSchoolsWidgetProps {
  jobTitle: string;
  schools: InsightListItem[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const EducationSchoolsWidget = ({
  jobTitle,
  schools,
  onIntersectionHandler,
}:EducationSchoolsWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const mostCommonSchool = schools[0];

  const componentRef = useRef(null);
  useInsightWidgetIntersection({
    componentRef, onIntersectionHandler, metadata: mostCommonSchool?.text, metadataType: 'most common school',
  });

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.EDUCATION_SCHOOLS_CARD_TEXT }>
        <Bold />
        <AccentBold />
        {{ jobTitle }}
        {{ schoolName: mostCommonSchool?.text }}
      </Trans>
    );
  };

  const renderCardBody = () => {
    return (
      <div ref={ componentRef }>
        <InsightList items={ schools } iconFallback={ ASSETS.EDUCATION_FALLBACK } />
      </div>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="🎓"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.EDUCATION_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

EducationSchoolsWidget.defaultProps = {
};
