import React from 'react';
import Lottie from 'react-lottie';
import scrollAnimation from '../../../assets/lottie/scroll_down.json';
import { MATCH_PAGE_HEADER_HEIGHT } from '../../../pages/match-page/match.page.styled';
import { AnimationWrapper, ButtonContainer, Text } from './scroll-button.styled';
import { ScrollButtonProps } from './scroll-button';

const ScrollButton = ({ text, clickHandler, anchorRef }: ScrollButtonProps): JSX.Element => {
  const executeScroll = () => {
    if (anchorRef?.current) {
      window.scrollTo({ top: anchorRef.current.offsetTop - MATCH_PAGE_HEADER_HEIGHT, behavior: 'smooth' });
    }
  };

  const onScrollButtonClick = () => {
    executeScroll();
    clickHandler?.();
  };

  return (
    <div>
      <ButtonContainer>
        <AnimationWrapper onClick={ onScrollButtonClick }>
          <Lottie
            options={ {
              loop: true,
              autoplay: true,
              animationData: scrollAnimation,
            } }
          />
        </AnimationWrapper>
        { text &&  <Text>{ text }</Text> }
      </ButtonContainer>
    </div>
  );
};

export default ScrollButton;

ScrollButton.defaultProps = {
  clickHandler: undefined,
  text: undefined,
};
