import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarVariants } from '../../../../../../../../components/Common/avatar-with-fallback/avatar-variant.enum';
import { ASSETS } from '../../../../../../../../assets';
import {
  AtText,
  BoldText,
  ExperienceLogoDivider,
  ExperienceTitle,
  InstitutionText,
  RoleDecorator,
  TimelineBoldText,
  TimeLineCompanyContainer,
  TimelineContainerItemLogo,
  TimelineContainerItemLogoWrapper,
  TimelineExperienceRangeContainer,
  TimelineExperienceRangeText,
} from '../../../timeline.styled';
import { PromotionBadge } from '../promotion-badge/promotion-badge.component';
import DynamicHeightContainer
  from '../../../../../../../../components/Common/dynamic-height-container/dynamic-height-container.component';
import { DateObject } from '../../../../../../../../@types/match';
import { createExperienceTimelineRange } from '../../../timeline.utils';
import ExperienceTags from '../../experience-item-tags/experience-item-tags.component';
import STRING_KEYS from '../../../../../../../../language/keys';
import MatchCardContext from '../../../../context/match-card.context';
import {
  CompanyIcon,
  CompanyName,
  ExperienceTagsContainer,
  GeneratedSummary,
  HighlightedSummary,
  PromotionBadgeWrapper,
  Row,
  SeeMoreButton,
  SpotlightIcon,
  SpotlightText,
  SummaryContainer,
} from './experience-container-item-role.styled';
import { ExperienceContainerItemRoleProps } from './experience-container-item-role';

const ExperienceContainerItemRole = ({
  experienceItem,
  role,
  isLastItem,
  isMultipleExperience,
  isNestedRole,
  showPromotionBadge,
  companyNameClickHandler,
  disableBorder,
}: ExperienceContainerItemRoleProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isRoleExpanded, setIsRoleExpanded] = useState(false);
  const { actions } = useContext(MatchCardContext);

  useEffect(() => {
    if (isRoleExpanded) {
      actions.setIsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoleExpanded]);

  const renderLogo = () => {
    if (isNestedRole) {
      return (
        <TimelineContainerItemLogoWrapper>
          <RoleDecorator isPromoted={ showPromotionBadge } />
          <ExperienceLogoDivider />
        </TimelineContainerItemLogoWrapper>
      );
    }

    return (
      <TimelineContainerItemLogoWrapper>
        <TimelineContainerItemLogo
          variant={ AvatarVariants.Circular }
          logo={ experienceItem.companyLogo }
          title={ experienceItem.companyName }
          fallbackImage={ ASSETS.EXPERIENCE_FALLBACK }
        />
        { (
          !isLastItem || isMultipleExperience
        ) && <ExperienceLogoDivider /> }
      </TimelineContainerItemLogoWrapper>
    );
  };

  const renderTimelineRange = (startDate: DateObject, endDate: DateObject) => {
    const timelineRange = createExperienceTimelineRange(startDate, endDate);

    if (!timelineRange) {
      return null;
    }

    return (
      <TimelineExperienceRangeContainer>
        <TimelineExperienceRangeText>
          <BoldText>{timelineRange.yearsSum} •</BoldText> {timelineRange.startDateString} - {timelineRange.endDateString}
        </TimelineExperienceRangeText>
        { showPromotionBadge && <PromotionBadgeWrapper><PromotionBadge /></PromotionBadgeWrapper> }
      </TimelineExperienceRangeContainer>
    );
  };

  const onCompanyNameClick = () => {
    if (experienceItem.isCompanyEnriched) {
      companyNameClickHandler();
    }
  };

  const renderCompanyName = () => {
    return (
      <CompanyName
        onClick={ onCompanyNameClick }
        disabled={ !experienceItem.isCompanyEnriched }
      >
        { experienceItem.companyName }
        { experienceItem.isCompanyEnriched ? <CompanyIcon /> : null}
      </CompanyName>
    );
  };

  const renderSummary = () => {
    if (!role.generatedSummary) {
      return null;
    }

    return (
      <SummaryContainer>
        <HighlightedSummary>
          { role.generatedSummary.highlightedText }
          { !isRoleExpanded && (
            <SeeMoreButton onClick={ () => setIsRoleExpanded(true) }>
              ...{ translate(STRING_KEYS.SEE_MORE) }
            </SeeMoreButton>
          ) }
        </HighlightedSummary>
        { isRoleExpanded && (
          <GeneratedSummary>
            { role.generatedSummary.modifiedSummary }
          </GeneratedSummary>
        ) }
      </SummaryContainer>
    );
  };

  const renderSpotlightText = () => {
    return (
      <SpotlightText>
        <SpotlightIcon />
        { /* TBD */ }
      </SpotlightText>
    );
  };

  const lastDate = role && role.endDateObject;

  return (
    <DynamicHeightContainer>
      <Row>
        { renderLogo() }
        <TimeLineCompanyContainer showBorder={ !disableBorder }>
          { renderTimelineRange(role.startDateObject, lastDate) }
          <ExperienceTitle>
            <TimelineBoldText>{ role.title }</TimelineBoldText>
            <AtText>{ translate(STRING_KEYS.AT) }</AtText>
            <InstitutionText>
              <TimelineBoldText>{ renderCompanyName() }</TimelineBoldText>
            </InstitutionText>
          </ExperienceTitle>
          { renderSummary() }
          <ExperienceTagsContainer>
            <ExperienceTags
              role={ role }
              companyName={ experienceItem.companyName }
              isExpanded={ isRoleExpanded }
              expandHandler={ () => setIsRoleExpanded(true) }
            />
          </ExperienceTagsContainer>
        </TimeLineCompanyContainer>
      </Row>
    </DynamicHeightContainer>
  );
};

export default ExperienceContainerItemRole;
