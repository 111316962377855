import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import {
  ContactedBarIcon,
  Container, FunnelContainer, FunnelIcon, HeaderContainer, HeaderTitle,
  LikedBarIcon,
  PeopleMatchedBarIcon,
  RepliedBarIcon,
  StyledFunnelBar, TitleContainer, HeaderInfoContainer, InfoLabel, InfoLabelLine, InfoLabelContainer,
} from './position-performance-funnel.styled';
import { PositionPerformanceFunnelProps } from './position-performance-funnel';
import { usePositionPerformance } from './position-performance-funnel.hook';
import { useFunnelTags } from './funnel-tags/funnel-tags.hook';
import { FunnelTagsTypeEnum } from './funnel-tags/funnel-tags.enum';

const MAX_TAGS_LENGTH = 2;

const PositionPerformanceFunnel = ({ positionStatistics }: PositionPerformanceFunnelProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const positionPerformanceStatistics = usePositionPerformance(positionStatistics);

  const {
    reviewedMatchesCount,
    engagedMatchesCount,
    peopleMatchesOptimalLevel,
    peopleMatchesActualLevel,
    peopleLikedLevel,
    peopleLikedOptimalLevel,
    peopleContactedLevel,
    peopleContactedOptimalLevel,
    peopleRepliedLevel,
    peopleRepliedOptimalLevel,
  } = positionPerformanceStatistics;

  const tagsMap = useFunnelTags(positionPerformanceStatistics);

  const getTagsByType = (tagType: FunnelTagsTypeEnum) => {
    if (tagsMap.has(tagType)) {
      return (
        <>
          { tagsMap.get(tagType)?.slice(0, MAX_TAGS_LENGTH) || null }
        </>
      );
    }

    return undefined;
  };

  const renderHeader = () => {
    return (
      <>
        <TitleContainer>
          <FunnelIcon />
          <HeaderTitle>{ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.TITLE) }</HeaderTitle>
        </TitleContainer>
        <HeaderInfoContainer>
          <InfoLabelContainer>
            <InfoLabelLine isDashedLine />
            <InfoLabel>{ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.OPTIMAL_INFO_LABEL) }</InfoLabel>
          </InfoLabelContainer>
          <InfoLabelContainer>
            <InfoLabelLine />
            <InfoLabel>{ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.ACTUAL_INFO_LABEL) }</InfoLabel>
          </InfoLabelContainer>
        </HeaderInfoContainer>
      </>
    );
  };

  const renderPeopleMatchedBar = () => {
    return (
      <StyledFunnelBar
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.TITLE) }
        Icon={ PeopleMatchedBarIcon }
        count={ reviewedMatchesCount }
        Tags={ getTagsByType(FunnelTagsTypeEnum.PeopleMatched) }
        level={ peopleMatchesActualLevel }
        optimalLevel={ peopleMatchesOptimalLevel }
        nextOptimalLevel={ peopleLikedOptimalLevel }
      />
    );
  };

  const renderLikedBar = () => {
    return (
      <StyledFunnelBar
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TITLE) }
        Icon={ LikedBarIcon }
        count={ engagedMatchesCount }
        Tags={ getTagsByType(FunnelTagsTypeEnum.Liked) }
        level={ peopleLikedLevel }
        optimalLevel={ peopleLikedOptimalLevel }
        nextOptimalLevel={ peopleContactedOptimalLevel }
      />
    );
  };

  const renderContactedBar = () => {
    return (
      <StyledFunnelBar
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TITLE) }
        Icon={ ContactedBarIcon }
        count={ positionStatistics.contactedMatches }
        Tags={ getTagsByType(FunnelTagsTypeEnum.Contacted) }
        level={ peopleContactedLevel }
        optimalLevel={ peopleContactedOptimalLevel }
        nextOptimalLevel={ peopleRepliedOptimalLevel }
      />
    );
  };

  const renderRepliedBar = () => {
    return (
      <StyledFunnelBar
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TITLE) }
        Icon={ RepliedBarIcon }
        count={ positionStatistics.repliedConversations }
        Tags={ getTagsByType(FunnelTagsTypeEnum.Replied) }
        level={ peopleRepliedLevel }
        optimalLevel={ peopleRepliedOptimalLevel }
      />
    );
  };

  return (
    <Container>
      <HeaderContainer>
        { renderHeader() }
      </HeaderContainer>
      <FunnelContainer>
        { renderPeopleMatchedBar() }
        { renderLikedBar() }
        { renderContactedBar() }
        { renderRepliedBar() }
      </FunnelContainer>
    </Container>
  );
};

export default PositionPerformanceFunnel;
