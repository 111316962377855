/* eslint-disable react/no-array-index-key */
import { RoleInformation } from '../../../../../../../@types/match';
import { Container } from './experience-container-item.styled';
import { ExperienceContainerItemProps } from './experience-container-item';
import ExperienceContainerItemRole from './experience-container-item-role/experience-container-item-role.component';

const isPromoted = (role: RoleInformation, prevRole: RoleInformation) => {
  if (
    !role ||
    !prevRole ||
    role.seniorityLevel == null ||
    prevRole.seniorityLevel == null
  ) {
    return false;
  }

  return role.seniorityLevel > prevRole.seniorityLevel;
};

const ExperienceContainerItem = ({
  experienceItem,
  clickHandler,
  viewChangeHandler,
  isLastItem,
}: ExperienceContainerItemProps): JSX.Element => {
  const renderMultiple = () => {
    const reversedRoles = experienceItem.data.slice().reverse();

    return (
      <div>
        {
          reversedRoles.map((role, index) => {
            if (experienceItem.renderRolesAmount && index >= experienceItem.renderRolesAmount) {
              return null;
            }

            const prevRole = reversedRoles[index + 1];
            const showPromotionBadge = isPromoted(role, prevRole);

            return (
              <ExperienceContainerItemRole
                key={ `${role.title}_${index}` }
                experienceItem={ experienceItem }
                isLastItem={ isLastItem }
                role={ role }
                isMultipleExperience
                isNestedRole={ index > 0 }
                showPromotionBadge={ showPromotionBadge }
                companyNameClickHandler={ clickHandler }
                disableBorder={ index !== reversedRoles.length - 1 }
              />
            );
          })
        }
      </div>
    );
  };

  const render = () => {
    if (experienceItem.data.length === 1) {
      return (
        <ExperienceContainerItemRole
          experienceItem={ experienceItem }
          isLastItem={ isLastItem }
          role={ experienceItem.data[0] }
          companyNameClickHandler={ clickHandler }
        />
      );
    }

    return renderMultiple();
  };

  return (
    <Container>
      { render() }
    </Container>
  );
};

export default ExperienceContainerItem;
