import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import {
  IconFallback,
  Container,
  InstituteLogoImage,
} from './experience-item-company-logo.styled';

type CompanyLogoProps = {
  companyLogo: string | undefined,
  companyName: string
}

export const CompanyLogo = ({
  companyLogo,
  companyName,
}:
CompanyLogoProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <InstituteLogoImage
        src={ companyLogo }
        alt={ translate(
          STRING_KEYS.LOGO_ALT,
          { name: translate(companyName) },
        ) }
      >
        <IconFallback />
      </InstituteLogoImage>
    </Container>
  );
};
