/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import { useMyUserSelector } from '../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useIsSuperUser } from '../../../../hooks/superuser';
import { useSetMatchAsViewed } from '../../../../store/rtk-query/apis/match/hooks/set-match-as-viewed.mutation-hook';
import NavigationIndication from '../../../../components/Common/navigation-indication/navigation-indication.component';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../language/keys';
import { isPreviousFamiliarityValid } from '../../../../components/match/match.utils';
import {
  BottomFaderIcon,
  Body,
  BottomFader,
  CardContainer,
  Divider,
  LeftContainer,
  MatchContainer,
  NavigationIndicationWrapper,
  RightContainer,
  StyledTalentAtsIndication,
  ShowMoreButton,
  ShowMoreButtonIcon,
  Container,
} from './match-card.styled';
import { MatchCardProps } from './match-card';
import Timeline from './timeline/timeline.component';
import Header from './header/header.component';
import WidgetsContainer from './widgets/widgets-container.component';
import { ItemsContainer } from './timeline/timeline.styled';
import useMatchCardReducer from './context/match-card.reducer';
import MatchCardContext from './context/match-card.context';

const logger = log.getLogger('MATCH_CARD');

const MINIMIZED_CONTAINER_HEIGHT_PX = 640;

const MatchCard = (props: MatchCardProps): JSX.Element => {
  const {
    match,
    engageHandler,
    declineHandler,
    viewChangeHandler,
    trackMatchWidgetViewed,
    isActive,
    disableActions,
    hideActions = false,
    engagedMatchCount,
    matchesLength,
    showBottomFader,
    disableExpansion = false,
    ...otherProps
  } = props;
  const { t: translate } = useTranslation();
  const { data: currentUser } = useMyUserSelector();
  const [matchCardState, matchCardActions] = useMatchCardReducer();
  const isSuperUser = useIsSuperUser();
  const [setMatchAsViewed] = useSetMatchAsViewed();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(disableExpansion || matchCardState.isExpanded);
  const [removeCardHeight, setRemoveCardHeight] = useState<boolean>(false);

  useEffect(() => {
    if (!disableExpansion) {
      setIsExpanded(matchCardState.isExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchCardState.isExpanded]);

  useEffect(() => {
    if (isActive && currentUser?.id && match?.id && setMatchAsViewed) {
      const viewedByCurrentUser = match?.viewedBy?.some((viewedUser) => viewedUser?.userId === currentUser?.id);

      if (viewedByCurrentUser) {
        return;
      }

      if (isSuperUser) {
        logger.debug(
          'skipping setting match as viewed for super-user', {
            positionId: match.positionId,
            matchId: match.id,
          },
        );

        return;
      }

      setMatchAsViewed(match.positionId, match.id, currentUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (containerRef?.current && !disableExpansion) {
      setIsExpanded(containerRef?.current?.clientHeight <= MINIMIZED_CONTAINER_HEIGHT_PX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowMoreButtonClick = () => {
    matchCardActions.setIsExpanded(true);
    viewChangeHandler();
  };

  const onContainerTransitionEnd = () => {
    if (!isExpanded || !containerRef?.current?.clientHeight || MINIMIZED_CONTAINER_HEIGHT_PX >= containerRef?.current?.clientHeight) {
      return;
    }

    if (!removeCardHeight) {
      setRemoveCardHeight(true);
    }
  };

  const renderTalentAtsIndication = () => {
    if (!match.previousFamiliarity || !isPreviousFamiliarityValid(match?.previousFamiliarity)) {
      return null;
    }

    return (
      <StyledTalentAtsIndication previousFamiliarity={ match.previousFamiliarity } />
    );
  };

  return (
    <MatchCardContext.Provider value={ { state: matchCardState, actions: matchCardActions } }>
      <div { ...otherProps }>
        <Container>
          <ItemsContainer
            minimized={ !isExpanded }
            minimizedHeight={ MINIMIZED_CONTAINER_HEIGHT_PX }
            fullHeight={ removeCardHeight ? undefined : containerRef?.current?.clientHeight }
            onTransitionEnd={ () => setTimeout(onContainerTransitionEnd, 100) }
          >
            <CardContainer ref={ containerRef }>
              <>
                <NavigationIndicationWrapper>
                  <NavigationIndication count={ matchesLength } activeIndex={ engagedMatchCount } />
                </NavigationIndicationWrapper>
                <MatchContainer>
                  <Header
                    match={ match }
                    engageHandler={ engageHandler }
                    declineHandler={ declineHandler }
                    disableActions={ disableActions }
                    hideActions={ hideActions }
                  />
                  <Divider />
                  { renderTalentAtsIndication() }
                  <Body>
                    <LeftContainer>
                      <Timeline
                        talent={ match.talent }
                        viewChangeHandler={ viewChangeHandler }
                      />
                    </LeftContainer>
                    <RightContainer>
                      <WidgetsContainer
                        match={ match }
                        trackMatchWidgetViewed={ trackMatchWidgetViewed }
                      />
                    </RightContainer>
                  </Body>
                </MatchContainer>
              </>
            </CardContainer>
          </ItemsContainer>
          { (!isExpanded || showBottomFader) && (
            <BottomFader isExpended={ isExpanded } >
              { isExpanded && <BottomFaderIcon /> }
              { !isExpanded && (
                <ShowMoreButton
                  type="button"
                  variant={ PerfectButtonVariants.Outline }
                  hoverVariant={ PerfectButtonHoverVariants.Grow }
                  onClick={ onShowMoreButtonClick }
                  StartIcon={ ShowMoreButtonIcon }
                >
                  { translate(STRING_KEYS.SEE_MORE) }
                </ShowMoreButton>
              ) }
            </BottomFader>
          ) }
        </Container>
      </div>
    </MatchCardContext.Provider>
  );
};

MatchCard.displayName = 'MatchCard';

export default MatchCard;
