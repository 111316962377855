import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { TransitionScreenWrapper } from '../transition-screen-wrapper/transition-screen-wrapper.component';
import animationData from '../../../assets/lottie/all_done_loader.json';
import { SlideInSentence } from '../slide-in-sentence/slide-in-sentence.component';
import {
  LoaderWrapper,
} from './matching-making-transition.styled';

export const MatchingMakingTransitionScreen = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <TransitionScreenWrapper>
      <SlideInSentence
        content={ translate(STRING_KEYS.ONBOARDING.DONE_SCREEN_MATCHING_PROGRESS) }
        delay={ 400 }
      />

      <LoaderWrapper>
        <Lottie
          options={ {
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          } }
          height={ 300 }
          width={ 100 }
        />
      </LoaderWrapper>

    </TransitionScreenWrapper>
  );
};
