import {
  EducationDegreeDto,
  EducationFieldOfStudyDto,
  EducationSchoolDto,
  FunnelOverviewDto,
  MedianExperienceDto,
  PositionInsightDto,
  PositionsInsightsResponseDto,
  PreviousCompaniesDto,
  RoleJourneyDto,
  SimilarTitlesDto,
  TopEmployerDto,
  TopIndustriesDto,
} from '../models/dto/response/position/position-insights-response.dto';
import { FieldOfStudyWidgetProps } from '../components/position-insights/field-of-study-widget/field-of-study-widget.component';
import { PositionInsightTypeEnum } from '../enums/position-insight-type.enum';
import { MedianExperienceWidgetProps } from '../components/position-insights/median-experience-widget/median-experience-widget.component';
import { EducationDegreeWidgetProps } from '../components/position-insights/education-degree-widget/education-degree-widget.component';
import { EducationSchoolsWidgetProps } from '../components/position-insights/education-schools-widget/education-schools-widget.component';
import { PreviousCompaniesWidgetProps } from '../components/position-insights/previous-companies-widget/previous-companies-widget.component';
import { TopIndustriesWidgetProps } from '../components/position-insights/industries-insight-widget/industries-insight-widget.component';
import { PositionInsights } from '../models/dto/model/position-insights.model';
import { EmployerByIndustryWidgetProps } from '../components/position-insights/employer-by-industry-widget/employer-by-industry-widget.component';
import { SimilarTitleWidgetProps } from '../components/position-insights/similar-title-widget/similar-title-widget.component';
import { FunnelOverviewWidgetProps } from '../components/position-insights/funnel-overview-widget/funnel-overview-widget.componet';
import { RoleJourneyWidgetProps } from '../components/position-insights/role-journey-widget/role-journey-widget.component';
import { PositionResponse } from '../store/rtk-query/apis/position/dto/response/position.response';
import { AccountInfoResponse } from '../store/rtk-query/apis/account/dto/response/account-info.response';

const percentageTransformer = (percent: number) => Math.floor(percent * 100);

export class PositionInsightsTransformer {
  public static createPositionInsights(
    currentAccount: AccountInfoResponse,
    currentPosition: PositionResponse | undefined,
    positionInsightsResponse: PositionsInsightsResponseDto | undefined,
  ) :PositionInsights | undefined {
    if (!currentAccount || !currentPosition || !positionInsightsResponse) {
      return undefined;
    }

    const {
      id, status, jobTitle,
    } = currentPosition;

    const positionInsights: PositionInsights = {
      positionId: id,
      positionJobTitle: jobTitle,
      positionStatus: status,
    };

    positionInsightsResponse.insights.forEach((insight: PositionInsightDto) => {
      switch (insight.type) {
        case PositionInsightTypeEnum.MedianExperience:
          positionInsights.medianExperience =
            PositionInsightsTransformer.transformMedianExperienceWidget(jobTitle, insight.data as MedianExperienceDto);
          break;
        case PositionInsightTypeEnum.TopIndustries:
          positionInsights.topIndustries = PositionInsightsTransformer.transformTopIndustriesWidget(jobTitle, insight.data as TopIndustriesDto);
          break;
        case PositionInsightTypeEnum.SimilarTitles:
          positionInsights.similarTitles = PositionInsightsTransformer.transformSimilarTitlesWidget(jobTitle, insight.data as SimilarTitlesDto);
          break;
        case PositionInsightTypeEnum.EducationDegree:
          positionInsights.educationDegree = PositionInsightsTransformer.transformEducationDegreeWidget(jobTitle, insight.data as EducationDegreeDto);
          break;
        case PositionInsightTypeEnum.EducationFieldOfStudy:
          positionInsights.fieldOfStudy =
            PositionInsightsTransformer.transformEducationFieldOfStudyWidget(jobTitle, insight.data as EducationFieldOfStudyDto);
          break;
        case PositionInsightTypeEnum.TopEmployer:
          positionInsights.employerByIndustry =
            PositionInsightsTransformer.transformTopEmployerWidget(insight.data as TopEmployerDto[]);
          break;
        case PositionInsightTypeEnum.PreviousCompanies:
          positionInsights.previousCompanies =
            PositionInsightsTransformer.transformPreviousCompaniesWidget(currentAccount, insight.data as PreviousCompaniesDto);
          break;
        case PositionInsightTypeEnum.EducationSchool:
          positionInsights.educationSchool =
            PositionInsightsTransformer.transformEducationSchoolsWidget(jobTitle, insight.data as EducationSchoolDto);
          break;
        case PositionInsightTypeEnum.FunnelOverview:
          positionInsights.funnelOverview =
            PositionInsightsTransformer.transformFunnelOverviewWidget(jobTitle, insight.data as FunnelOverviewDto);
          break;
        case PositionInsightTypeEnum.RoleJourney:
          positionInsights.roleJourney =
            PositionInsightsTransformer.transformRoleJourneyWidget(jobTitle, insight.data as RoleJourneyDto);
          break;
        default:
          // Unknown type
          break;
      }
    });

    return positionInsights;
  }

  private static transformMedianExperienceWidget(jobTitle: string, widgetData: MedianExperienceDto): MedianExperienceWidgetProps {
    return {
      jobTitle,
      medianYearTenure: widgetData.medianYearTenure,
      data: widgetData.buckets.map((medianValue) => ({
        years: medianValue.label,
        isMedian: medianValue.isMedian,
        thisRole: percentageTransformer(medianValue.targetRoleTalentsCountPercentage),
        otherTechRole: percentageTransformer(medianValue.otherRolesTalentsCountPercentage),
      })),
    };
  }

  private static transformTopIndustriesWidget(jobTitle: string, widgetData: TopIndustriesDto): TopIndustriesWidgetProps {
    return {
      jobTitle,
      data: widgetData.industries.map((industryData) => ({
        text: industryData.name,
        value: percentageTransformer(industryData.percentage),
      })),
    };
  }

  private static transformSimilarTitlesWidget(jobTitle: string, widgetData: SimilarTitlesDto): SimilarTitleWidgetProps {
    return {
      jobTitle,
      positionTitles: widgetData.titles.map((title) => ({
        title: title.name,
        distance: title.distance,
      })),
    };
  }

  private static transformEducationFieldOfStudyWidget(jobTitle: string, widgetData: EducationFieldOfStudyDto): FieldOfStudyWidgetProps {
    return {
      jobTitle,
      data: widgetData.fieldsOfStudy.map((title) => ({
        text: title.name,
        value: percentageTransformer(title.percentage),
      })),
    };
  }

  private static transformEducationDegreeWidget(jobTitle: string, widgetData: EducationDegreeDto): EducationDegreeWidgetProps {
    return {
      jobTitle,
      data: widgetData.degrees.map((degree) => ({
        text: degree.name,
        value: percentageTransformer(degree.percentage),
      })),
    };
  }

  private static transformEducationSchoolsWidget(jobTitle: string, widgetData: EducationSchoolDto): EducationSchoolsWidgetProps {
    return {
      jobTitle,
      schools: widgetData.schools.map((degree) => ({
        id: degree.id,
        text: degree.name,
        subText: degree.location && `${degree.location.city ? `${degree.location.city}, ` : ''} ${degree.location.country}`,
        icon: degree.logo,
      })),
    };
  }

  private static transformTopEmployerWidget(widgetData: TopEmployerDto[]): EmployerByIndustryWidgetProps[] {
    return widgetData.map((topIndustry) => ({
      companyName: topIndustry.topEmployers[0].name,
      industryName: topIndustry.industry,
      companies: topIndustry.topEmployers.map((topEmployer) => ({
        id: topEmployer.id,
        industries: topEmployer.industries,
        companyEnrichedData: topEmployer.companyEnrichedData,
        text: topEmployer.name,
        icon: topEmployer.logo,
      })),
    }));
  }

  private static transformPreviousCompaniesWidget(
    currentAccount: AccountInfoResponse,
    widgetData: PreviousCompaniesDto,
  ): PreviousCompaniesWidgetProps {
    return {
      companyName: currentAccount.displayName,
      companyLogo: currentAccount.logo,
      companies: widgetData.previousCompanies.map((company) => ({
        id: company.id,
        industries: company.industries,
        companyEnrichedData: company.companyEnrichedData,
        text: company.name,
        icon: company.logo,
      })),
    };
  }

  private static transformFunnelOverviewWidget(
    jobTitle: string,
    widgetData: FunnelOverviewDto,
  ): FunnelOverviewWidgetProps {
    return {
      jobTitle,
      talentsInThisRole: widgetData.talentsInThisRole,
      talentsWithRelevantBasicQualifications: widgetData.talentsWithRelevantBasicQualifications,
      talentsInPositionLocation: widgetData.talentsInPositionLocation,
      talentsWillingToMove: widgetData.talentsWillingToMove,
    };
  }

  private static transformRoleJourneyWidget(
    jobTitle: string,
    widgetData: RoleJourneyDto,
  ): RoleJourneyWidgetProps {
    return {
      currentTitle: jobTitle,
      previousTitles: widgetData?.previousTitles?.map((item) => item.name),
      nextTitles: widgetData?.nextTitles?.map((item) => item.name),
    };
  }
}
