import styled from 'styled-components';
import { THEME } from '../../../../../themes';

export const ExperienceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${THEME.SPACING.LOOSE};
`;

export const IconAndDividerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionSection = styled.div`
  display: flex;
  flex-direction:column;
  width: 600px;
`;

export const ExperienceLogoDivider = styled.span`
  border-left: 4px solid #eeeef5;
  display: block;
  margin-left: 30px;
  flex: 1;
`;

// TO DO Use divider from common
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: ${THEME.OPACITY.GHOST};
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  margin: ${THEME.SPACING.MEDIUM} 0px;
`;

export const SmallDivider = styled.div`
  width: 60px;
  height: 1px;
  opacity: ${THEME.OPACITY.GHOST};
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  margin-top: ${THEME.SPACING.BASE};
`;

export const RoleItem = styled.div`
  display:flex;
  flex-direction: column;
  margin-top: ${THEME.SPACING.BASE};
`;

export const RoleYearsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const RoleTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${THEME.SPACING.TINY};
`;

export const TalentNetworkWrapper = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;
