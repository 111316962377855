import React from 'react';
import {
  ActionButtonsContainer,
  Container,
  IconContainer,
  Title,
  TitleContainer,
} from './preferences-body-header.styled';

const PreferencesBodyHeaderComponent = ({ title, Icon, ActionButtons }: PreferencesBodyHeaderProps): JSX.Element => {
  return (
    <Container>
      <TitleContainer>
        <IconContainer>
          <Icon />
        </IconContainer>
        <Title>{ title }</Title>
      </TitleContainer>
      <ActionButtonsContainer>
        { ActionButtons }
      </ActionButtonsContainer>
    </Container>
  );
};

export default PreferencesBodyHeaderComponent;
