import { useTranslation } from 'react-i18next';
import { PreviousCompanies } from '../previous-companies-widget/previous-companies-widget.component';
import { SimilarTitleWidget } from '../similar-title-widget/similar-title-widget.component';
import { EducationSchoolsWidget } from '../education-schools-widget/education-schools-widget.component';
import { MedianExperienceWidget } from '../median-experience-widget/median-experience-widget.component';
import {
  EmployerByIndustryWidget,
  EmployerByIndustryWidgetProps,
} from '../employer-by-industry-widget/employer-by-industry-widget.component';
import { IndustriesInsightWidget } from '../industries-insight-widget/industries-insight-widget.component';
import { FieldOfStudyWidget } from '../field-of-study-widget/field-of-study-widget.component';
import { EducationDegreeWidget } from '../education-degree-widget/education-degree-widget.component';
import FunnelOverviewWidget from '../funnel-overview-widget/funnel-overview-widget.componet';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../consts/analytics';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import STRING_KEYS from '../../../language/keys';
import { RoleJourneyWidget } from '../role-journey-widget/role-journey-widget.component';
import { TrackEventPayload } from '../../../@types/analytics/eventPayload';
import { OnInsightWidgetIntersectionHandlerArguments } from '../../../hooks/useInsightWidgetIntersection';
import {
  InsightsDisclaimer,
  InsightsWidgetsContainer,
  InsightsWidgetsWrapper,
  WidgetRow,
} from './position-insights-widgets-container.styled';
import {
  OnWidgetIntersectionFunctionArguments,
  PositionInsightsWidgetsContainerProps,
} from './position-insights-widgets-container';

const SMALL_WIDGETS_PER_ROW = 2;

const PositionInsightsWidgetsContainer = ({
  pageName,
  positionInsights,
}: PositionInsightsWidgetsContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();

  const onWidgetIntersection = ({
    widgetName,
    metadata,
    metadataStruct,
    metadataType,
  }: OnWidgetIntersectionFunctionArguments) => {
    const payload: TrackEventPayload = {
      eventName: ANALYTICS_EVENTS.POSITION_INSIGHTS_WIDGET_VIEWED,
      eventGroup: EVENT_GROUP.IMP,
      pageName,
      productArea: PRODUCT_AREA.POSITION_PAGE,
      pagePositionId: positionInsights.positionId,
      pagePositionName: positionInsights?.positionJobTitle,
      pagePositionStatus: positionInsights?.positionStatus,
      componentName: widgetName,
      componentMetadataType: metadataType,
      componentMetadata: metadata,
      componentMetadataStruct: metadataStruct,
    };

    analyticsTrackEvent(payload);
  };

  const createSmallWidgetsArray = () => {
    const smallWidgetsArray = [];

    if (positionInsights?.educationDegree) {
      smallWidgetsArray.push(
        <EducationDegreeWidget
          jobTitle={ positionInsights.educationDegree.jobTitle }
          data={ positionInsights.educationDegree.data }
          onIntersectionHandler={
            ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
              widgetName: 'education_level_insights',
              metadata,
              metadataStruct,
              metadataType,
            })
          }
        />);
    }

    if (positionInsights?.educationSchool) {
      smallWidgetsArray.push(
        <EducationSchoolsWidget
          jobTitle={ positionInsights.educationSchool.jobTitle }
          schools={ positionInsights.educationSchool.schools }
          onIntersectionHandler={
            ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
              widgetName: 'education_school_insights',
              metadata,
              metadataStruct,
              metadataType,
            })
          }
        />);
    }

    if (positionInsights?.previousCompanies) {
      smallWidgetsArray.push(
        <PreviousCompanies
          companyName={ positionInsights.previousCompanies.companyName }
          companyLogo={ positionInsights.previousCompanies.companyLogo }
          companies={ positionInsights.previousCompanies.companies }
          onIntersectionHandler={
            ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
              widgetName: 'previous_company_insights',
              metadata,
              metadataStruct,
              metadataType,
            })
          }
        />);
    }

    if (positionInsights?.employerByIndustry?.length) {
      smallWidgetsArray.push(...positionInsights.employerByIndustry.map((widgetProps: EmployerByIndustryWidgetProps) => (
        <EmployerByIndustryWidget
          key={ `EmployerByIndustryWidgetRow${widgetProps.industryName}` }
          companyName={ widgetProps.companyName }
          industryName={ widgetProps.industryName }
          companies={ widgetProps.companies }
          onIntersectionHandler={
            ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
              widgetName: 'top_company_insights',
              metadata,
              metadataStruct,
              metadataType,
            })
          }
        />
      )));
    }

    return smallWidgetsArray;
  };

  const renderSmallWidgetsRows = (): JSX.Element | undefined => {
    const smallWidgetArray = createSmallWidgetsArray();

    if (!smallWidgetArray?.length) {
      return undefined;
    }

    const widgetRowsAmount = Math.round(smallWidgetArray.length / SMALL_WIDGETS_PER_ROW);

    return (
      <>
        { Array.from(Array(widgetRowsAmount).keys()).map((key: number, index: number) => {
          const startIndex = index * 2;

          return (
            <WidgetRow isSmallCardsRow key={ `SmallWidgetRowIndex${key}` }>
              { smallWidgetArray[startIndex] }
              { smallWidgetArray[startIndex + 1] && smallWidgetArray[startIndex + 1] }
            </WidgetRow>
          );
        })}
      </>
    );
  };

  return (
    <InsightsWidgetsContainer>
      <InsightsWidgetsWrapper>
        {positionInsights?.funnelOverview && (
          <WidgetRow>
            <FunnelOverviewWidget
              jobTitle={ positionInsights.funnelOverview?.jobTitle }
              positionLocation={ positionInsights.funnelOverview.positionLocation }
              talentsInThisRole={ positionInsights.funnelOverview.talentsInThisRole }
              talentsWithRelevantBasicQualifications={ positionInsights.funnelOverview.talentsWithRelevantBasicQualifications }
              talentsInPositionLocation={ positionInsights.funnelOverview.talentsInPositionLocation }
              talentsWillingToMove={ positionInsights.funnelOverview.talentsWillingToMove }
              onIntersectionHandler={
                ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
                  widgetName: 'position_overview',
                  metadata,
                  metadataStruct,
                  metadataType,
                })
              }
            />
          </WidgetRow>
        )}
        {positionInsights?.medianExperience && (
          <WidgetRow>
            <MedianExperienceWidget
              jobTitle={ positionInsights.medianExperience?.jobTitle }
              medianYearTenure={ positionInsights.medianExperience.medianYearTenure }
              data={ positionInsights.medianExperience.data }
              onIntersectionHandler={
                ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
                  widgetName: 'experience_insights',
                  metadata,
                  metadataStruct,
                  metadataType,
                })
              }
            />
          </WidgetRow>
        )}
        {positionInsights?.topIndustries && (
          <WidgetRow>
            <IndustriesInsightWidget
              jobTitle={ positionInsights.topIndustries.jobTitle }
              data={ positionInsights.topIndustries.data }
              onIntersectionHandler={
                ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
                  widgetName: 'industry_insights',
                  metadata,
                  metadataStruct,
                  metadataType,
                })
              }
            />
          </WidgetRow>
        )}
        {positionInsights?.similarTitles && (
          <WidgetRow>
            <SimilarTitleWidget
              jobTitle={ positionInsights.similarTitles.jobTitle }
              positionTitles={ positionInsights.similarTitles.positionTitles }
              onIntersectionHandler={
                ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
                  widgetName: 'similar_titles_insights',
                  metadata,
                  metadataStruct,
                  metadataType,
                })
              }
            />
          </WidgetRow>
        )}
        {positionInsights?.roleJourney && (
          <WidgetRow>
            <RoleJourneyWidget
              currentTitle={ positionInsights.roleJourney.currentTitle }
              previousTitles={ positionInsights.roleJourney.previousTitles }
              nextTitles={ positionInsights.roleJourney.nextTitles }
              onIntersectionHandler={
                ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
                  widgetName: 'role_path_insights',
                  metadata,
                  metadataStruct,
                  metadataType,
                })
              }
            />
          </WidgetRow>
        )}
        {positionInsights?.fieldOfStudy && (
          <WidgetRow>
            <FieldOfStudyWidget
              jobTitle={ positionInsights.fieldOfStudy.jobTitle }
              data={ positionInsights.fieldOfStudy.data }
              onIntersectionHandler={
                ({ metadata, metadataStruct, metadataType }: OnInsightWidgetIntersectionHandlerArguments) => onWidgetIntersection({
                  widgetName: 'education_titles_insights',
                  metadata,
                  metadataStruct,
                  metadataType,
                })
              }
            />
          </WidgetRow>
        )}
        { renderSmallWidgetsRows() }
        <InsightsDisclaimer>
          *{ translate(STRING_KEYS.INSIGHT_WIDGETS.INSIGHTS_DISCLAIMER) }
        </InsightsDisclaimer>
      </InsightsWidgetsWrapper>
    </InsightsWidgetsContainer>
  );
};
export default PositionInsightsWidgetsContainer;
