import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  PopupMainContainer, PopupDescription, PopupDivider, PopupSharedContainer, PopupTitle, RemoveStepButton,
} from './selection-popup.styled';

const SelectionPopup = ({
  title, description, children, withFooterAction, actionHandler,
}: SelectionPopupProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const renderFooter = () => {
    if (!withFooterAction) {
      return null;
    }

    return (
      <PopupSharedContainer>
        <PopupDivider />
        <RemoveStepButton onClick={ actionHandler }>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.CONFIRM_BUTTON) }</RemoveStepButton>
      </PopupSharedContainer>
    );
  };

  return (
    <PopupMainContainer showSpaceBetween={ !!withFooterAction }>
      <PopupSharedContainer>
        <PopupTitle>
          { title }
        </PopupTitle>
        <PopupDescription>
          { description }
        </PopupDescription>
        <PopupDivider />
      </PopupSharedContainer>
      { children }
      { renderFooter() }
    </PopupMainContainer>
  );
};

export default SelectionPopup;
