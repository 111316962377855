import styled, { css } from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../../utils/colors';
import { CaptionFont, HeadlineFont } from '../../../../../themes/fonts';
import { ASSETS, SOCIAL_ICONS } from '../../../../../assets';
import IconButton from '../../../../../components/Common/Buttons/icon-button/icon-button.component';

export const CONVERSATION_HEADER_HEIGHT = '96px';

const buttonCss = css`
  color: ${THEME.COLORS.CONTENT_A};
`;

export const ConversationsPageHeader = styled.div`
  height: ${CONVERSATION_HEADER_HEIGHT};
  min-height: ${CONVERSATION_HEADER_HEIGHT};
  width: 100%;
  border-bottom: solid 1px ${THEME.COLORS_RGB.PRIMARY_GHOST};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${THEME.SPACING.LOOSE};
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
`;

export const NameContainer = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  display: flex;
  gap: ${THEME.SPACING.BASE};
`;

export const Name = styled.span`
  ${HeadlineFont};
  font-weight: 600;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PositionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${THEME.SPACING.MICRO};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const Label = styled.span`
  ${CaptionFont};
  font-weight: 600;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.MED)};
`;

export const Caption = styled.span`
  ${CaptionFont};
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.MED)};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const ShowDetailsButton = styled(BaseButton)`
  ${buttonCss};
  border: unset;
  border: 2px solid ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)};
`;

export const ActionButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  ${buttonCss};
`;

export const MatchDetailsIcon = styled(ASSETS.MATCH_DETAILS)`
  width: 22px;
  height: 22px;
  fill: ${THEME.COLORS.CONTENT_A};
`;

export const ArchiveIcon = styled(ASSETS.ARCHIVE_FILLED)`
  width: 22px;
  height: 22px;
  fill: ${THEME.COLORS.CONTENT_A};
`;

export const LinkedinIcon = styled(SOCIAL_ICONS.LINKED_IN_OUTLINE)`
  width: 14px;
  height: 14px;
  fill: ${THEME.COLORS.CONTENT_A};
`;

export const Avatar = styled(MuiAvatar)`
  width: 48px !important;
  height: 48px !important;
`;

const iconCss = css`
  height: 16px;
  fill: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
`;

export const PositionIcon = styled(ASSETS.WORK_FULL_ICON)`
  ${iconCss};
`;

export const LocationIcon = styled(ASSETS.LOCATION_PIN)`
  ${iconCss};
`;
