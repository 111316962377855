import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../themes';
import Dialog from '../../../../../../components/dialogs/base-dialog/base-dialog.component';
import { DividerSection } from '../../../../../../components/Common/Divider/styled';
import { Body2Font, Title2Font } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../../../utils/colors';
import {
  StartIconContainer,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.styled';

export const CustomModeDialogContainer = styled(Dialog)`
  padding-top: ${THEME.SPACING.XLOOSE};
  padding-left: ${THEME.SPACING.XLOOSE};
  padding-right: ${THEME.SPACING.XLOOSE};
  justify-content: space-between;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const CustomModeDialogHeaderContainer = styled(Dialog.Header)`
  display: flex;
  flex-direction: column;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 600;
  line-height: 1.38;
`;

export const Description = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
`;

export const CustomModeDialogBodyContainer = styled(Dialog.Body)`
  padding: 0;
`;

export const CustomModeStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const CustomModeDialogFooterContainer = styled(Dialog.Footer)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Divider = styled(DividerSection)`
  width: calc(100% + ${THEME.SPACING.XLOOSE} + ${THEME.SPACING.XLOOSE});
  margin-left: -${THEME.SPACING.XLOOSE};
`;

export const StepContainer = styled.div<{ warning?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  min-height: 382px;
  border-radius: ${THEME.RADIUS.CURVY};
  border: ${({ warning }) => warning ? `solid 2px ${THEME.COLORS.WARNING}` : undefined};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

export const RemoveButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTextContainer = styled.div`
  ${Body2Font};
  line-height: 1.57;
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  gap: ${THEME.SPACING.MICRO};
`;

export const HeaderDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${THEME.SPACING.MICRO}
`;

const IconCss = css`
  height: 14px;
  fill: ${THEME.COLORS.ACCENT};
`;
export const SenderIcon = styled(ASSETS.FILLED_SEND)`
  ${IconCss};
`;

export const TimerIcon = styled(ASSETS.OUTLINED_TIMER)`
  ${IconCss};
`;

export const CalendarIcon = styled(ASSETS.FILLED_CALENDAR)`
  ${IconCss};
`;

export const AddStepButton = styled(BaseButton)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  width: 206px;
`;

export const SaveStepsButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 204px;
  height: 48px;
`;

export const RemoveStepButton = styled(BaseButton)`
  font-size: 12px;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.LOW)};
  height: 24px;
  ${StartIconContainer} {
    margin-right: 0;
  }
`;

export const AddStepIcon = styled(ASSETS.FILLED_PLUS)`
  fill: ${THEME.COLORS.ACCENT};
  width: 25px;
  height: 25px;
`;

export const RemoveStepIcon = styled(ASSETS.TRASH_BASKET)`
  fill: currentColor;
  width: 15px;
  height: 17px;
`;

export const AlertContainer = styled.div`
    width: calc(100% + ${THEME.SPACING.XLOOSE} + ${THEME.SPACING.XLOOSE});
    margin-left: -${THEME.SPACING.XLOOSE};
`;
