export const LOADER_DURATION_MS = 60 * 1000 * 5;
export const LOADER_INTERVAL_TIME_MS = 1000;
export const INITIAL_TITLE = '5:00';
export const END_TITLE = '00:00';
export const COUNTER_TIME_FORMAT = 'mm:ss';

export const STEP_DESCRIPTION_TIMES_MS = {
  STEP1: 60 * 1000 * 5,
  STEP2: 60 * 1000 * 3.75,
  STEP3: 60 * 1000 * 2.5,
  STEP4: 60 * 1000 * 1.25,
  STEP5: 1,
};
