import styled   from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import BaseInput from '../../../../../components/Common/perfect-base-input/perfect-base-input.component';
import { Subtitle1Font } from '../../../../../themes/fonts';

export const SearchContainer = styled.div<{ withGap: boolean }>`
  display: flex;
  gap: ${({ withGap }) => withGap ? THEME.SPACING.TINY : '0'};
  justify-content: space-between;
  width: 100%;
`;

export const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchTitleContainer = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ show }) => show ? '100%' : 0};
  opacity: ${({ show }) => show ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
`;

export const TitleContainer = styled.div`
  ${Subtitle1Font};
  opacity: ${THEME.OPACITY.MED};
  font-weight: 600;
`;

export const SearchIcon = styled(ASSETS.SEARCH)`
  width: 18px;
  height: 18px;
`;

export const InputAnimatedWrapper = styled.div<{ show: boolean }>`
  width: ${({ show }) => show ? '100%' : '40px'};
  cursor: ${({ show }) => !show ? 'pointer' : undefined};
  transition: width 0.5s ease;
  display: flex;
  justify-content: end;
`;

export const SearchInput = styled(BaseInput)<{ show: boolean }>`
  padding-left: ${({ show }) => show ? 0 : 11}px;
  border: unset;
  :focus-within {
    border: unset;
  }
  & input {
    z-index: ${({ show }) => show ? 95 : 1};
  }
`;

export const CloseChipIcon = styled(ASSETS.CLOSE_CHIP)`
  fill: ${THEME.COLORS.PRIMARY_DARK};
  height: 18px;
  object-fit: contain;
  opacity: 0.34;
  cursor: pointer;
`;
