import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import Search from '../search/seacrh.componet';
import ConversationGroup from '../conversation-group/conversation-group.component';
import { useGetConversationsByPositionQuery } from '../../../../../store/rtk-query/apis/outreach/hooks/get-conversations-by-position-id.query-hook';
import ConversationPreferences from '../conversation-preferences/conversation-preferences.component';
import { ConversationGroupsEnum } from '../../../../../enums/outreach/conversation-groups.enum';
import { SequencePreferencesDto } from '../../../../../store/rtk-query/apis/sequence/dto/sequence-preferences.dto';
import { SequenceModeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-mode.enum';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import { usePageFilters } from '../../../../../hooks/use-page-filters.hooks';
import { CONVERSATION_ITEMS_ID } from '../conversation-group/conversation-group-auto-mode/conversation-group-auto-mode.component';
import { useGetPositionSequencePreferencesSelector } from '../../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import {
  ArchivedIcon, CompletedIcon,
  ConversationsSection, EmailIcon,
  FilterContainer,
  ItemsContainer,
  PreferencesSection,
  RepliedIcon,
  SentIcon,
  SidePanelSection,
  SidePanelSectionLayout,
  ToFollowupIcon,
  ToOutreachIcon,
} from './conversation-side-panel.styled';
import { ConversationSidePanelProps } from './conversation-side-panel';

export const CONVERSATIONS_GROUP_ICONS: Record<ConversationGroupsEnum, JSX.Element> = {
  [ConversationGroupsEnum.Replied]: <RepliedIcon />,
  [ConversationGroupsEnum.ToOutreach]: <ToOutreachIcon />,
  [ConversationGroupsEnum.ToFollowup]: <ToFollowupIcon />,
  [ConversationGroupsEnum.Sent]: <SentIcon />,
  [ConversationGroupsEnum.Archived]: <ArchivedIcon />,
  [ConversationGroupsEnum.All]: <ToOutreachIcon />,
  [ConversationGroupsEnum.Completed]: <CompletedIcon />,
  [ConversationGroupsEnum.Step]: <EmailIcon />,
};

const ConversationSidePanel = ({ isBannerVisible }: ConversationSidePanelProps): JSX.Element => {
  const { positionId } = useParams<MatchUrlParams>();
  const {
    getFiltersByKey,
  } = usePageFilters();
  const {
    data: { conversations, sequenceLength }, isLoading, isFetching,
  } = useGetConversationsByPositionQuery({ positionId, refetchOnMountOrArgChange: true });
  const {
    data: { sequencePreferences = {} as SequencePreferencesDto },
  } = useGetPositionSequencePreferencesSelector(positionId);
  const { mode } = sequencePreferences;
  const isAutoMode = mode === SequenceModeEnum.Auto || mode === SequenceModeEnum.Custom;

  const isStepSelected = useMemo(() => {
    return !!getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP)[0];
  }, [getFiltersByKey]);

  return (
    <SidePanelSectionLayout
      isAutoMode={ isAutoMode }
      isLoading={ isLoading || isFetching }
      isStepSelected={ isStepSelected }
      isBannerVisible={ isBannerVisible }
    >
      <SidePanelSection>
        <PreferencesSection>
          <ConversationPreferences />
        </PreferencesSection>
        <ConversationsSection>
          { !isAutoMode && (
            <FilterContainer>
              <Search />
            </FilterContainer>
          )}
          <ConversationGroup
            isAutoMode={ isAutoMode }
            conversations={ conversations }
            isLoading={ isLoading || isFetching }
            sequenceLength={ sequenceLength }
          />
        </ConversationsSection>
      </SidePanelSection>
      <ItemsContainer
        isLoading={ isLoading || isFetching }
        isAutoMode={ isAutoMode }
        isStepSelected={ isStepSelected }
        isBannerVisible={ isBannerVisible }
        id={ CONVERSATION_ITEMS_ID }
      />
    </SidePanelSectionLayout>
  );
};

export default ConversationSidePanel;
