import {
  Bar, BarChartContainer, BarContainer, BarText, BarValue,
} from './horizontal-bar-chart.styled';

const INCREASE_BAR_THRESHOLD_VALUE = 80;

export interface BarChartData {
  data: BarChartDataItem[];
  valueTransformationMethod?: (value: number) => string;
  shouldIncreaseBarScale?: boolean;
}

export interface BarChartDataItem {
  value: number;
  text: string;
  backgroundColor: string;
  textColor: string;
  valueColor: string;
}

interface HorizontalBarChartProps {
  chartData: BarChartData;
}

export const HorizontalBarChart = ({
  chartData,
}:HorizontalBarChartProps): JSX.Element => {
  if (!chartData.data) {
    chartData.data = [];
  }

  chartData.data.sort((data1, data2) => data2.value - data1.value);

  const renderCompanyList = () => {
    let thresholdMultiplier = 1;
    const maxValue = chartData.data[0]?.value;

    if (chartData.shouldIncreaseBarScale && INCREASE_BAR_THRESHOLD_VALUE > maxValue) {
      thresholdMultiplier = INCREASE_BAR_THRESHOLD_VALUE / maxValue;
    }

    return chartData.data.map((barData: BarChartDataItem) => (
      <BarContainer key={ barData.text }>
        <Bar value={ barData.value * thresholdMultiplier } backgroundColor={ barData.backgroundColor }>
          <BarText textColor={ barData.textColor }>
            { barData.text }
          </BarText>
        </Bar>
        <BarValue valueColor={ barData.valueColor }>
          { chartData.valueTransformationMethod ? chartData.valueTransformationMethod(barData.value) : barData.value }
        </BarValue>
      </BarContainer>
    ));
  };

  return (
    <BarChartContainer>
      { renderCompanyList() }
    </BarChartContainer>
  );
};

HorizontalBarChart.defaultProps = {
};
