import { RefObject } from 'react';
import useGetCompanyById, { CompanyInsightData } from '../../../hooks/get-company-by-id.hook';
import CompanyModalContents from './ModalContents';
import {
  Modal,
} from './styled';

type CompanyInsightsModalProps = {
  companyData: CompanyInsightData,
  modalRef?: RefObject<HTMLDivElement> | null,
  modalPosition?: string,
}

function CompanyInsightsModal({
  companyData,
  modalRef,
  modalPosition,
} : CompanyInsightsModalProps): JSX.Element | null {
  const {
    data, isLoading, isError,
  } = useGetCompanyById(companyData);

  if (isError) {
    return null;
  }

  return (
    <Modal
      ref={ modalRef }
      modalPosition={ modalPosition }
    >
      <CompanyModalContents
        isLoading={ isLoading }
        companyInsightData={ data }
      />
    </Modal>
  );
}

export default CompanyInsightsModal;

CompanyInsightsModal.defaultProps = {
  modalRef: undefined,
  modalPosition: undefined,
};
