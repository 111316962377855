import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConversationMinimizedResponseDto } from '../../../store/rtk-query/apis/outreach/dto/response/conversation-minimized.response';
import { useOutreachState } from '../../../store/selectors/outreach.selectors';
import { conversationsSorted, resetConversationsSort } from '../../../store/slices/outreach-state/outreach-state.toolkit-slice';
import { ConversationGroupsEnum } from '../../../enums/outreach/conversation-groups.enum';

type ConversationsGrouped = {
  conversationIds: string[],
  bounceExists?: boolean,
}
const defaultSortedAutoConversations = (sequenceLength: number) => {
  const groups = new Map<string, []>(
    [
      [ConversationGroupsEnum.All, []],
    ],
  );
  if (sequenceLength) {
    for (let i = 0; i < sequenceLength; i++) {
      groups.set(`${ConversationGroupsEnum.Step}_${i + 1}`, []);
    }
  }

  groups.set(ConversationGroupsEnum.Replied, []);
  groups.set(ConversationGroupsEnum.Completed, []);
  groups.set(ConversationGroupsEnum.Archived, []);

  return groups;
};

export const useFilterAndGroupAutoConversations = (
  conversations: ConversationMinimizedResponseDto[],
  sequenceLength: number,
): Map<string, ConversationsGrouped> => {
  const dispatch = useDispatch();
  const [sortedConversations, setSortedConversations] = useState<Map<string, ConversationsGrouped>>(new Map());
  const { shouldSortConversations } = useOutreachState();

  useEffect(() => {
    dispatch(resetConversationsSort());
  }, [dispatch, conversations.length]);

  useEffect(() => {
    if (shouldSortConversations && conversations) {
      const conversationsMap = conversations.reduce<Map<string, ConversationMinimizedResponseDto[]>>((acc, conversation) => {
        acc.get(ConversationGroupsEnum.All)?.push(conversation);
        const sequenceNextStep =
          conversation?.nextScheduledMessage?.sequenceStep ||
          conversation?.nextOpenLinkedinTask?.sequenceStep;

        if (conversation.isArchived) {
          acc.get(ConversationGroupsEnum.Archived)?.push(conversation);

          return acc;
        }

        if (conversation?.latestMessage?.sequenceStep === sequenceLength) {
          acc.get(ConversationGroupsEnum.Completed)?.push(conversation);

          return acc;
        }

        if (conversation.wasReplied) {
          acc.get(ConversationGroupsEnum.Replied)?.push(conversation);

          return acc;
        }

        if (sequenceNextStep) {
          acc.get(`${ConversationGroupsEnum.Step}_${sequenceNextStep}`)?.push(conversation);

          return acc;
        }

        return acc;
      }, defaultSortedAutoConversations(sequenceLength));

      const clonedConversationMap = new Map<string, ConversationsGrouped>();
      conversationsMap.forEach((groupedConversations, key) => {
        const conversationIds = groupedConversations.map((conversation) => conversation.id);
        const bounceExists = groupedConversations.some((conversation) => conversation.latestMessage?.wasBounced);

        clonedConversationMap.set(key, { conversationIds, bounceExists });
      });
      
      setSortedConversations(clonedConversationMap);
      dispatch(conversationsSorted());
    }
  }, [conversations, dispatch, shouldSortConversations, sequenceLength]);

  return sortedConversations;
};
