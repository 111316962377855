export const PAGE_NAMES = {
  MATCH_LIMIT: 'match_limit',
  MATCH_PAGE: 'match_page',
  HOME_PAGE: 'home_page',
  LOGIN_PAGE: 'login_page',
  SIGN_IN_ONBOARDING_PAGE: 'sign_in_onboarding',
  START_ONBOARDING: 'start_onboarding',
  SECOND_FORM_ONBOARDING: 'second_form_onboarding',
  JOB_DESCRIPTION_ONBOARDING: 'job_description_onboarding',
  EMAIL_VERIFICATION_ONBOARDING: 'email_verification_onboarding',
  WAITING_MATCHES_PAGE: 'waiting_matches_page',
  POSITION_INSIGHTS_PAGE: 'position_insights_page_app',
  POSITION_INSIGHTS_PAGE_ONBOARDING: 'position_insights_page_ob',
  POSITION_INTRODUCTION_PAGE: 'position_introduction_page',
  POSITION_OVERVIEW_PAGE: 'position_overview_page',
  MATCH_TUNE: 'MATCH_TUNE',
  MATCH_TUNE_MANUAL: 'MATCH_TUNE_MANUAL',
  // onboarding and create position pages
  BASIC_INFO: 'basic_info',
  INDUSTRY_PAGE_VIEWED: 'industry',
  EDUCATION_PAGE_VIEWED: 'education',
  SKILLS_PAGE_VIEWED: 'skills',
  EXPERIENCE_PAGE_VIEWED: 'experince',
  FINISHED_FLOW: 'finished',
  TALENT_OUTREACH: 'talent_outreach',
  TALENT_OUTREACH_EMPTY: 'talent_outreach_empty',
};
