import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { TextEllipsis } from '../../../../themes/text';

export const LearnMoreButton = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${THEME.COLORS.ACCENT};
  letter-spacing: 1.5px;
  display: none;
  text-transform: uppercase;
`;

export const TextContainer = styled.div`
  margin-left: ${THEME.SPACING.TINO};
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.3;
  ${TextEllipsis};
  max-width: 240px;
`;

export const SubText = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  opacity: 0.6;
  line-height: 1.2;
`;

export const ListItem = styled.div<{activateHover?:boolean, isActive: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.BASE} ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.BASE};
  cursor: ${({ activateHover }) => activateHover ? 'pointer' : 'unset'};

  ${({ activateHover, isActive }) => {
    if (activateHover || isActive) {
      const activeItemStyle = `{
        background-color:  ${isActive ? THEME.COLORS_RGB.ACCENT_DIVIDER : THEME.COLORS_RGB.ACCENT_GHOST};

      & ${LearnMoreButton} {
          display: block;
        }
        
      & ${Text} {
          max-width: 150px;
        }

      & ${Text} {
          color: ${THEME.COLORS.ACCENT};
        }
      }`;
      
      return isActive ? activeItemStyle : `:hover ${activeItemStyle}`;
    }

    return '';
  }};
`;

export const ListItemStartWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ListItemIndex = styled.div`
  width: 10px;
  font-size: 12px;
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_LOW};
  font-weight: 500;
  margin-right: ${THEME.SPACING.TINO};
`;

export const Icon = styled.img`
  border-radius: ${THEME.RADIUS.BASE};
  height: 32px;
`;
