import styled, { css } from 'styled-components';
import { fadeInKeyFrames, shakeAnimation } from '../../../animations';
import { THEME } from '../../../themes';
import { InputStyle } from '../../../modules/onboarding/components/input/input.component';
import IconButton from '../Buttons/icon-button/icon-button.component';
import { ASSETS } from '../../../assets';
import { OptionList } from './option-list/option-list.component';

export const Container = styled.div<{
  isInputFocused: boolean,
  isValueEmpty: boolean | string,
  isError: boolean,
  errorMessage: string,
}>`
  position: relative;
  max-height: 100px;
  transition: background-color 100ms ease-in;
  font-size: 32px;
  ${({ isError }) => isError ? css`
  animation: ${shakeAnimation({
    animationDuration: 200,
    animationDelay: 0,
  })}
  ` : ''};
`;

export const StyledOptionList = styled(OptionList)<{ inputStyle: InputStyle }>`
  width: fit-content;
  z-index: 2000;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingIndicatorWrapper = styled.div<{
  isLoading: boolean,
}>`
  background-color: ${THEME.COLORS_RGB.PRIMARY_GHOSTLY};
  border-radius: ${THEME.RADIUS.CIRCLE};
  padding: ${THEME.SPACING.MICRO};
  margin-left: auto !important;
  margin-right: ${THEME.SPACING.TINY};
  width: 50px;
  visibility: ${({ isLoading }) => isLoading ? 'visible' : 'hidden'};
`;

export const TypingIndicatorWrapper = styled.div<{
    isLoading: boolean,
}>`
  background-color: ${THEME.COLORS_RGB.PRIMARY_GHOSTLY};
  border-radius: ${THEME.RADIUS.CIRCLE};
  padding: ${THEME.SPACING.MICRO};
  margin-left: auto !important;
  margin-right: 8px;
  width: 50px;
  visibility: ${({ isLoading }) => isLoading ? 'visible' : 'hidden'};
`;

export const opacityAnimationCss = css`
  opacity: 0;
  animation: ${fadeInKeyFrames} 200ms forwards 200ms;
`;

export const InstructionsMessageWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  top: 50px;
  width: 100%;
  z-index: 10;
  ${opacityAnimationCss};
`;

export const loaderStyle = {
  display: 'flex',
  marginLeft: THEME.SPACING.MICRO,
};

export const EndAdornmentContainer = styled.div`
  ${opacityAnimationCss};
  display: flex;
`;

export const StyledIconButton = styled(IconButton)`
  background-color: transparent;
`;

export const AddButtonIcon = styled(ASSETS.PLUS_SIGN_FILLED)`
  color: ${THEME.COLORS.ACCENT};
  width: 25px;
`;

export const RemoveButtonIcon = styled(ASSETS.X_SIGN_FILLED)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
  width: 25px;
`;
