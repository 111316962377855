import styled from 'styled-components';
import { TextInputCommonStyle } from '../message-additional-options.styled';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Input = styled.input`
  height: 48px;
  width: 50%;
  ${TextInputCommonStyle}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  gap: ${THEME.SPACING.TINY};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;
