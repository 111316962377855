import { QueryMethods } from '../../enums/query-methods.enums';
import { PostQuery, GetQuery, PatchQuery } from '../../@types/rtk-query';
import { POSITION_STATUS } from '../../../../consts';
import { PositionState } from '../../../../enums/position-state.enum';
import { API_V2 } from '../../consts/versions.consts';
import { POSITION_ID_PATH_PARAM, ROUTES } from './position.consts';
import { CreatePositionRequest } from './dto/request/create-position.request';
import { ClosePositionQueryArguments } from './dto/query-arguments/close-position.query-arguments';
import { LinkPositionToAtsQueryArguments } from './dto/query-arguments/link-position-to-ats.query-arguments';
import { ClosePositionRequest } from './dto/request/close-position.request';
import { LinkPositionToAtsRequest } from './dto/request/link-position-to-ats.request';
import { LinkedAtsJobRequest } from './dto/request/linked-ats-job.request';
import { UpdatePositionAtsIntegrationQueryArguments } from './dto/query-arguments/update-position-ats-integration.query-arguments';
import { UpdatePositionQueryArguments } from './dto/query-arguments/update-position.query-arguments';
import { UpdatePositionRequest } from './dto/request/update-position-request';
import { UpdatePositionUserRolesQueryArguments } from './dto/query-arguments/update-position-user-roles.query-arguments';
import { UpdatePositionUserRolesRequest } from './dto/request/update-position-user-roles-request';
import { CreatePositionFeedbackQueryArguments } from './dto/query-arguments/create-position-feedback.query-arguments';
import { CreatePositionFeedbackRequest } from './dto/request/create-position-feedback.request';
import { GetSimilarTitlesQueryArguments } from './dto/query-arguments/get-similar-titles.query-arguments';
import { SkipPositionAssistantQueryArguments } from './dto/query-arguments/skip-position-assistant.query-arguments';
import { SkipPositionAssistantRequest } from './dto/request/skip-position-assistant.request';

export const createPositionQuery = (data: CreatePositionRequest): PostQuery<CreatePositionRequest> => ({
  url: ROUTES.POSITIONS,
  method: QueryMethods.Post,
  data,
});

export const createPositionFeedbackQuery = ({ positionId, feedback }: CreatePositionFeedbackQueryArguments): PostQuery<CreatePositionFeedbackRequest> => ({
  url: ROUTES.POSITION_FEEDBACK.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Post,
  data: { feedback },
});

export const skipMatchTuneQuery = (positionId: string): PostQuery => ({
  url: ROUTES.SKIP_MATCH_TUNE.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Post,
});

export const getPositionOverviewQuery = (positionId: string): GetQuery => ({
  url: ROUTES.POSITION_OVERVIEW.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getOpenPositionsQuery = (): GetQuery => ({
  url: ROUTES.POSITIONS,
  method: QueryMethods.Get,
  params: { state: PositionState.Open },
  version: API_V2,
});

export const getPositionByIdQuery = (positionId: string): GetQuery => ({
  url: ROUTES.POSITION_BY_ID.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getPositionRegions = (): GetQuery => ({
  url: ROUTES.POSITION_REGIONS,
  method: QueryMethods.Get,
});

export const getSimilarTitles = ({ title, positionId }: GetSimilarTitlesQueryArguments): GetQuery => ({
  url: ROUTES.POSITION_SIMILAR_TITLES,
  method: QueryMethods.Get,
  params: { jobTitle: title, positionId },
});

export const getPositionInsightsQuery = (positionId: string): GetQuery => ({
  url: ROUTES.POSITION_INSIGHTS.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getPositionStatisticsQuery = (positionId: string): GetQuery => ({
  url: ROUTES.POSITION_STATISTICS.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getPositionAnalyticsQuery = (positionId: string): GetQuery => ({
  url: ROUTES.POSITION_ANALYTICS.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const closePositionQuery = ({ positionId, closureReason, freeText }: ClosePositionQueryArguments): PatchQuery<ClosePositionRequest> => {
  return {
    url: ROUTES.POSITION_STATE.replace(POSITION_ID_PATH_PARAM, positionId),
    data: {
      state: POSITION_STATUS.CLOSED,
      positionClosure: {
        closureReason,
        freeText,
      },
    },
    method: QueryMethods.Patch,
  };
};

export const skipPositionAssistantQuery = (args: SkipPositionAssistantQueryArguments): PostQuery<SkipPositionAssistantRequest> => {
  const {
    positionId,
    name,
    type,
  } = args;

  return {
    url: ROUTES.SKIP_POSITION_ASSISTANT.replace(POSITION_ID_PATH_PARAM, positionId),
    data: {
      name,
      type,
    },
    method: QueryMethods.Post,
  };
};

export const linkPositionToAtsQuery = (args: LinkPositionToAtsQueryArguments): PostQuery<LinkPositionToAtsRequest> => {
  const {
    positionId,
    atsJobId,
    atsJobTitle,
    atsIntegrationId,
    syncAutomatically,
  } = args;

  return {
    url: ROUTES.POSITION_ATS.replace(POSITION_ID_PATH_PARAM, positionId),
    data: {
      atsJobId,
      atsJobTitle,
      atsIntegrationId,
      syncAutomatically,
      isActive: true,
    },
    method: QueryMethods.Post,
  };
};

export const updatePositionAtsIntegrationQuery = (args: UpdatePositionAtsIntegrationQueryArguments): PatchQuery<LinkedAtsJobRequest> => {
  const {
    positionId,
    jobId,
    jobTitle,
    syncAutomatically,
  } = args;

  return {
    url: ROUTES.POSITION_ATS.replace(POSITION_ID_PATH_PARAM, positionId),
    data: {
      jobId,
      jobTitle,
      syncAutomatically,
    },
    method: QueryMethods.Patch,
  };
};

export const updatePositionQuery = (args: UpdatePositionQueryArguments): PatchQuery<UpdatePositionRequest> => {
  const {
    positionId,
  } = args;

  return {
    url: ROUTES.POSITION_BY_ID.replace(POSITION_ID_PATH_PARAM, positionId),
    data: {
      jobTitle: args.jobTitle,
      prioritizedTitles: args.prioritizedTitles,
      excludedTitles: args.excludedTitles,
      levelsOfExperience: args.levelsOfExperience,
      overallLevelsOfExperience: args.overallLevelsOfExperience,
      degrees: args.degrees,
      fieldOfStudies: args.fieldOfStudies,
      companyBackground: args.companyBackground,
      companySizes: args.companySizes,
      skills: args.skills,
      locations: args.locations,
      remotePosition: args.remotePosition,
      triggerType: args.triggerType,
    },
    method: QueryMethods.Patch,
  };
};

export const updatePositionUserRolesQuery = (args: UpdatePositionUserRolesQueryArguments): PatchQuery<UpdatePositionUserRolesRequest> => {
  const {
    positionId,
  } = args;

  return {
    url: ROUTES.POSITION_USER_ROLES.replace(POSITION_ID_PATH_PARAM, positionId),
    data: {
      hiringManagers: args.hiringManagers,
      recruiters: args.recruiters,
    },
    method: QueryMethods.Patch,
  };
};
