import styled from 'styled-components';
import { THEME } from '../../../themes';
import { OverlineFont, Title1Font } from '../../../themes/fonts';

const CIRCLE_DASH_ARRAY = 565;

export const Section = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  width: 120px;
`;

export const Title = styled.div`
  ${Title1Font};
  font-weight: 900;
  color: ${THEME.COLORS.ACCENT};
  margin-left: ${THEME.SPACING.NANO};
`;

export const Text = styled.div`
  ${OverlineFont};
  letter-spacing: 1px;
  opacity: ${THEME.OPACITY.MED};
  font-weight: 600;
  text-align: center;
`;

export const Svg = styled.svg`
  height: 200px;
  width: 200px;
  transform: rotate(-90deg);
  
  & > * {
    fill: transparent;
  }
`;
export const Progress = styled.circle<{ progress: number }>`
  stroke: ${THEME.COLORS.ACCENT};
  stroke-width: 14px;
  stroke-linecap: round;
  stroke-dasharray: ${CIRCLE_DASH_ARRAY};
  stroke-dashoffset: ${({ progress }) => CIRCLE_DASH_ARRAY - ((progress / 100) * CIRCLE_DASH_ARRAY)};
  transition: all 1000ms;
`;

export const Track = styled.circle`
  stroke: ${THEME.COLORS.LIGHT_GREY};
  stroke-width: 13px;
`;
