import i18n from 'i18next';
import { difference } from 'lodash';
import log from 'loglevel';
import STRING_KEYS from '../../../../../language/keys';
import { PositionInitialRunRecommendations, PositionResponse } from '../../../../../store/rtk-query/apis/position/dto/response/position.response';
import {
  CompanySizesIcon, JobTitleIcon, LevelOfExperienceIcon, LocationIcon, MustHavesIcon,
} from '../adjustment-item/adjustment-item.styled';
import { UpdatePositionQueryArguments } from '../../../../../store/rtk-query/apis/position/dto/query-arguments/update-position.query-arguments';
import { PositionRunTriggerTypeEnum } from '../../../../../enums/position-run-trigger-type.enum';
import { RecommendedAdjustmentsKeys } from './recommended-adjustments.enums';

const logger = log.getLogger('RECOMMENDED_ADJUSTMENTS_UTILS');

type AdjustmentItemType = AdjustmentItemProps & { key: RecommendedAdjustmentsKeys };

export const createAdjustmentList = (position: PositionResponse, adjustments: PositionInitialRunRecommendations): AdjustmentItemType[] => {
  const list: AdjustmentItemType[] = [];

  if (adjustments.locations) {
    list.push({
      key: RecommendedAdjustmentsKeys.Locations,
      icon: <LocationIcon />,
      title: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LOCATION.TITLE),
      description: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LOCATION.DESCRIPTION),
    });
  }
  if (adjustments.levelsOfExperience) {
    list.push({
      key: RecommendedAdjustmentsKeys.LevelsOfExperience,
      icon: <LevelOfExperienceIcon />,
      title: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LEVEL_OF_EXPERIENCE.TITLE),
      description: i18n.t(
        STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LEVEL_OF_EXPERIENCE.DESCRIPTION,
        {
          currentLevelsOfExperience: position.levelsOfExperience.map((levelOfExperience) => i18n.t(STRING_KEYS.LEVEL_OF_EXPERIENCE[levelOfExperience])).join(', '),
          recommendedLevelsOfExperience:
            difference(adjustments.levelsOfExperience, position.levelsOfExperience)
              .map((levelOfExperience) => i18n.t(STRING_KEYS.LEVEL_OF_EXPERIENCE[levelOfExperience]))
              .join(', '),
        },
      ),
    });
  }
  if (adjustments.companySizes) {
    list.push({
      key: RecommendedAdjustmentsKeys.CompanySizes,
      icon: <CompanySizesIcon />,
      title: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.COMPANY_SIZE.TITLE),
      description: i18n.t(
        STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.COMPANY_SIZE.DESCRIPTION,
        {
          currentCompanySizes: position.companySizes.map((companySize) => i18n.t(STRING_KEYS.COMPANY_SIZE[companySize])).join(', '),
          recommendedCompanySizes:
            difference(adjustments.companySizes, position.companySizes).map((companySize) => i18n.t(STRING_KEYS.COMPANY_SIZE[companySize])).join(', '),
        },
      ),
    });
  }
  if (adjustments.skills || adjustments.companyBackground) {
    list.push({
      key: RecommendedAdjustmentsKeys.MustHaves,
      icon: <MustHavesIcon />,
      title: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.MUST_HAVES.TITLE),
      description: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.MUST_HAVES.DESCRIPTION),
    });
  }
  if (adjustments.jobTitle) {
    list.push({
      key: RecommendedAdjustmentsKeys.JobTitle,
      icon: <JobTitleIcon />,
      title: i18n.t(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.JOB_TITLE.TITLE),
      description: i18n.t(
        STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.JOB_TITLE.DESCRIPTION,
        { recommendedJobTitle: adjustments.jobTitle },
      ),
    });
  }

  return list;
};

export const createUpdatePositionRequestFromSelections = (
  positionId: string,
  selectedRecommendations: RecommendedAdjustmentsKeys[],
  adjustments: PositionInitialRunRecommendations,
): UpdatePositionQueryArguments => {
  const updatePositionData: UpdatePositionQueryArguments = { positionId, triggerType: PositionRunTriggerTypeEnum.DeadEndRecommendation };

  selectedRecommendations.forEach((recommendationKey) => {
    switch (recommendationKey) {
      case RecommendedAdjustmentsKeys.Locations:
        updatePositionData.locations = adjustments.locations;
        break;
      case RecommendedAdjustmentsKeys.LevelsOfExperience:
        updatePositionData.levelsOfExperience = adjustments.levelsOfExperience;
        break;
      case RecommendedAdjustmentsKeys.JobTitle:
        updatePositionData.jobTitle = adjustments.jobTitle;
        break;
      case RecommendedAdjustmentsKeys.CompanySizes:
        updatePositionData.companySizes = adjustments.companySizes;
        break;
      case RecommendedAdjustmentsKeys.MustHaves:
        updatePositionData.companyBackground = adjustments.companyBackground;
        updatePositionData.skills = adjustments.skills;
        break;
      default:
        logger.warn('Unsupported position adjustment', recommendationKey);
    }
  });

  return updatePositionData;
};
