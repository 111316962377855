import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { PerfectButtonHoverVariants, PerfectButtonSize } from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import ConfettiAnimation from '../../../assets/lottie/confetti.json';
import STRING_KEYS from '../../../language/keys';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import {
  AnimationContainer,
  Description,
  Title,
  GoToHomeButton,
  Icon,
  TextContainer,
  Container,
} from './payment-success-dialog.styled';

export const PaymentSuccessDialog = ({
  goToHomepage,
  onCloseDialog,
  isDialogOpen,
}: PaymentSuccessDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Dialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialog }
      widthSize={ DialogWidthSize.Medium }
    >
      <Container>
        <AnimationContainer>
          <Lottie
            options={ {
              loop: true,
              autoplay: true,
              animationData: ConfettiAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            } }
          />
        </AnimationContainer>
        <Icon />
        <TextContainer>
          <Title>{ translate(STRING_KEYS.PAYMENT_SUCCESS_DIALOG.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.PAYMENT_SUCCESS_DIALOG.DESCRIPTION) }</Description>
        </TextContainer>
        <GoToHomeButton
          size={ PerfectButtonSize.Large }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ goToHomepage }
        >
          { translate(STRING_KEYS.PAYMENT_SUCCESS_DIALOG.BUTTON_TITLE) }
        </GoToHomeButton>
      </Container>
    </Dialog>
  );
};
