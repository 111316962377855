import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { THEME } from '../../../../../../themes';

export const LabelSkeleton = styled(Skeleton)`
  width: 150px;
  height: 15px;
  display: flex;
`;

export const LineContainer = styled.div`
  margin-top: 74px;
  display:flex;
  flex-direction: row;
`;

export const YearsLine = styled(LabelSkeleton)`
  width: 135px;
  height: 12px;
  margin-bottom: -10px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const CompanyNameLine = styled(LabelSkeleton)`
  width: 306px;
  height: 24px;
  margin-bottom: -10px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;
  
export const CompanyDescription = styled(LabelSkeleton)`
  width: 110px;
  height: 16px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  margin-bottom: -15px;
`;

export const IconSkeleton = styled(Skeleton)`
  width: 64px;
  height: 64px;
  margin: 0 ${THEME.SPACING.MEDIUM} 0 0;
  border-radius: ${THEME.RADIUS.BASE};
  display: flex;
`;
export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  height: 100px;
`;

export const RolesSkeleton = styled(Skeleton)`
  width: 612px;
  height: 8px;
  display: flex;
  align-self: center;
  margin-top: -10px;
`;

export const RolesLastSkeleton = styled(Skeleton)`
  width: 385px;
  height: 8px;
  display: flex;
  margin-top: -10px;
  margin-bottom: 15px;
`;

export const RolesWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const LineWrapper = styled.div`
  width:64px;
  height: 64px;
  display: flex;
  justify-content: center;
  margin-top: -20px;
`;

export const Line = styled(Skeleton)`
  width: 3px;
  height: 124px;
  display: flex;
`;
