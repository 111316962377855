import { BarLegendProps } from '@nivo/bar/dist/types/types';
import { THEME } from '../../../themes';

export const medianExperienceChartSettings = {
  thisRoleKey: 'thisRole',
  otherRolesKey: 'otherTechRole',
  width: 736,
  height: 200,
  enableLabel: false,
  axisBottom: { tickSize: 0 },
  axisLeft: { tickSize: 0, format: (label: string): string => `${label}%` },
  indexBy: 'years',
  groupMode: 'grouped' as 'grouped' | 'stacked',
  isInteractive: false,
  borderRadius: 4,
  innerPadding: 5,
  padding: 0.3,
  barColors: ({ id, data: barData }: { id: string | number; data: Record<string, unknown> }): string => String(barData[`${id}Color`]),
  margin: {
    top: 10, right: 0, bottom: 45, left: 30,
  },
  theme: {
    grid: { line: { stroke: THEME.COLORS_RGB.PRIMARY_GHOST, strokeWidth: 1 } },
    legends: { text: { fontSize: 10, letterSpacing: 1.5 } },
    axis: {
      ticks: {
        text: {
          fontSize: 10, fill: THEME.COLORS_RGB.PRIMARY_DARK_LOW, letterSpacing: 1.5, fontFamily: 'Poppins',
        },
      },
    },
  },
  legends: [{
    anchor: 'bottom-left',
    direction: 'row',
    dataFrom: 'keys',
    itemWidth: 90,
    itemHeight: 20,
    translateX: -24,
    translateY: 50,
    symbolShape: 'circle',
    symbolSize: 10,
    itemsSpacing: 10,
  }] as BarLegendProps[],
};
