import { GuidanceCardProps } from './guidance-card/guidance-card';
import { GuidanceCardType } from './guidance-card/guidance-card.enums';

// TODO: put in translations
export const BACKGROUND_GUIDANCE_MODAL_DATA: GuidanceCardProps[] = [
  {
    type: GuidanceCardType.DO,
    quote: 'Fin-tech',
    title: 'Specify Relevant Sectors',
    text: 'Example: For a role in financial analysis, mention "Fin-tech" to indicate a preference for candidates with relevant sector  experience.',
  },
  {
    type: GuidanceCardType.DONT,
    quote: 'Small Fin-tech startups',
    title: 'Avoid Over-Specificity:',
    text: 'Correct Approach: Broaden to “Fin-tech” to encompass a wider range of company experiences within the sector.',
  },
  {
    type: GuidanceCardType.DO,
    quote: 'Healthcare',
    title: 'Focus on Industry Relevance',
    text: 'Example: For a healthcare management role, stating “Healthcare” highlights the desired industry experience.',
  },
  {
    type: GuidanceCardType.DONT,
    quote: 'Experience in large Healthcare corporations',
    title: 'Exclude Company Size References:',
    text: 'Correct Approach: Use “Healthcare” to include candidates from all company sizes in that sector.',
  },
];

export const SKILLS_GUIDANCE_MODAL_DATA: GuidanceCardProps[] = [
  {
    type: GuidanceCardType.DO,
    quote: 'JavaScript',
    title: 'List Specific Required Skills',
    text: 'Example: For a programming role, clearly state “Python” or “JavaScript” to identify exact coding skills needed.',
  },
  {
    type: GuidanceCardType.DONT,
    quote: 'Good communication skills',
    title: 'Avoid Vague or General Skills',
    text: 'Correct Approach: Be specific, like “Public speaking” or “Client communication.”',
  },
  {
    type: GuidanceCardType.DO,
    quote: 'Excel',
    title: 'Include Relevant Tool Proficiency',
    text: 'Example: For a digital marketing role, specify tools like “Hubspot” or “Google Analytics” that are essential for the job.',
  },
  {
    type: GuidanceCardType.DONT,
    quote: 'Experience in Fin-tech sector.',
    title: 'Confuse Industry Experience with Skills',
    text: 'Correct Approach: Focus on actual skills like “Risk analysis” or “Blockchain technology,” not industry experience.',
  },
];
