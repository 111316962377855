import { TalentNetworkDialogData } from './reducers/talents-network-modal-open.reducers';

export type MatchState = {
  filteredMatchesIds: Array<string>,
  talentsNetworkDialogData: TalentNetworkDialogData,
}

export const initialMatchState: MatchState = {
  filteredMatchesIds: [],
  talentsNetworkDialogData: {
    isOpen: false,
    selectedExperienceIds: new Set(),
    selectedEducationIds: new Set(),
  },
};
