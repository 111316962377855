import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { OutreachContentPreferencesFormikKeys } from '../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import {
  Container, Title, TitleContainer, TitleIcon,
} from './message-additional-options.styled';
import { MessageAdditionalOptionsProps } from './message-additional-options';
import TextInputItem from './text-input-item/text-input-item.component';

const MessageAdditionalOptions = ({
  preferredCompanyName,
  preferredJobTitle,
  preferredPositionLocation,
  onChangeHandler,
}: MessageAdditionalOptionsProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const onPreferredCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.PreferredCompanyName, e.currentTarget.value);
  };

  const onPreferredJobTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.PreferredJobTitle, e.currentTarget.value);
  };

  const onPreferredPositionLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.PreferredPositionLocation, e.currentTarget.value);
  };

  return (
    <Container>
      <TitleContainer>
        <TitleIcon />
        <Title>
          { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.TITLE) }
        </Title>
      </TitleContainer>
      <TextInputItem
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.ITEMS_TITLE.COMPANY_NAME) }
        value={ preferredCompanyName }
        description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.ITEMS_DESCRIPTION.COMPANY_NAME) }
        changeHandler={ onPreferredCompanyNameChange }
      />
      <TextInputItem
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.ITEMS_TITLE.JOB_TITLE) }
        value={ preferredJobTitle }
        description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.ITEMS_DESCRIPTION.JOB_TITLE) }
        changeHandler={ onPreferredJobTitleChange }
      />
      <TextInputItem
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.ITEMS_TITLE.POSITION_LOCATION) }
        value={ preferredPositionLocation }
        description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_ADDITIONAL_OPTIONS.ITEMS_DESCRIPTION.POSITION_LOCATION) }
        changeHandler={ onPreferredPositionLocationChange }
      />
    </Container>
  );
};
export default MessageAdditionalOptions;
