import styled from 'styled-components';
import { THEME } from '../../../themes';

const MAX_SMALL_CARD_WIDTH = '384px';

export const InsightsWidgetsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${THEME.SPACING.GIANT};
`;

export const InsightsWidgetsWrapper = styled.div`
  max-width: 800px;
`;

export const WidgetRow = styled.div<{ isSmallCardsRow?: boolean }>`
  display: flex;
  gap: 32px;

  & > * {
  flex: 1;

  ${({ isSmallCardsRow }) => {
    if (isSmallCardsRow) {
      return `max-width: ${MAX_SMALL_CARD_WIDTH}`;
    }

    return '';
  }};
  }
  
  &:not(:first-child) {
    margin-top: ${THEME.SPACING.MEDIUM};
  }
`;

export const InsightsDisclaimer = styled.div`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
  margin-top: ${THEME.SPACING.LOOSE};
`;
