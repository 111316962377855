import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body1Font, ButtonFont, Title2Font } from '../../../themes/fonts';
import Dialog from '../base-dialog/base-dialog.component';
import BaseButton from '../../Common/Buttons/perfect-base-button/perfect-base-button.component';
import { FLUENT_UI_EMOJIS } from '../../../assets';

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.LOOSE};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${THEME.SPACING.LOOSE};
`;

export const AnimationContainer = styled(Dialog.Header)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
  justify-content: center;
  
`;

export const DialogHeaderTitle = styled.div`
  ${ButtonFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Icon = styled(FLUENT_UI_EMOJIS.PINIATA)`
  width: 96px;
  height: 96px;
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${Body1Font};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
  text-align: center;
  line-height: 1.5;
  white-space: pre-line;
`;

export const GoToHomeButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 240px;
`;
