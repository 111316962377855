import { PreferencesContentSectionProps } from './preferences-content-section';
import { Container, Divider } from './preferences-content-section.styled';

const PreferencesContentSection = ({ children, ...otherProps }: PreferencesContentSectionProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps } >
      <Divider />
      { children }
    </Container>
  );
};
export default PreferencesContentSection;
