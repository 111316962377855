import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import SidePanel from '../../../../../components/Common/side-panel/side-panel.component';
import STRING_KEYS from '../../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import {
  ANALYTICS_EVENTS, COMPONENT_NAME, EVENT_GROUP, PRODUCT_AREA,
} from '../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { SidePanelVariant } from '../../../../../components/Common/side-panel/side-panel.enums';
import { ConversationPageSizeEnum, useConversationPageSize } from '../../../hooks/conversations-page-size.hook';
import { TalentInfoSentence } from '../../../../../components/match/match-content/talent-bio/talent-info-sentence/talent-info-sentence.component';
import { useShouldLockFeature } from '../../../../../hooks/should-lock-feature.hook';
import AtsButton from '../../../../../components/match/ats-button/ats-button.component';
import { THEME } from '../../../../../themes';
import { Divider } from '../../../../../components/Common/Divider';
import { OnInsightWidgetIntersectionHandlerArguments } from '../../../../../hooks/useInsightWidgetIntersection';
import { AlertSnackBar } from '../../../../../components/Common/CustomSnackBar/AlertSnackBar';
import { isPreviousFamiliarityValid } from '../../../../../components/match/match.utils';
import MatchCardModal from '../../match-card-modal/match-card-modal.component';
import { ExportMatchesIcon } from '../../../../../components/match/ats-button/ats-button.styled';
import { useExportMatchCSV } from '../../../hooks/export-csv.hook';
import { InfoSnackBar } from '../../../../../components/Common/CustomSnackBar/InfoSnackBar';
import { UpgradePlanDialog } from '../../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import {
  AboutContainer,
  AboutSentenceContainer,
  AboutTitle,
  AnimationWrapper,
  ContactDetailsColumnContainer,
  ContactDetailsContainer,
  ContactDetailsInfoContainer,
  ContactHeaderContainer,
  ContactProBadge,
  ContactProIcon,
  ContactTitle,
  DividerWrapper,
  ExportCSVButton,
  GoToMatchButton,
  Header,
  HeaderTitle,
  MatchEngagementSidePanelContainer,
  MissingContacts,
  ProfileIcon,
  SidePanelContainer,
  SidePanelHeaderContainer,
  StyledTalentAtsIndication,
  TalentNetworkContainer,
} from './match-side-panel.styled';
import ContactsDetails from './contact-details-list/contact-details-list.component';
import { Contact } from './contact-details-list/contact-details-list';
import { ContactType } from './contact-details-list/contact-details-list.enums';
import { SocialLink } from './social-links/social-links';
import SocialLinks from './social-links/social-links.component';
import { MatchSidePanelProps } from './match-side-panel';
import TalentNetworkCardsComponent from './talent-network-cards/talent-network-cards.component';
import MatchSidePanelLoader from './match-side-panel-loader/match-side-panel-loader.component';
import ContactDetailsSkeleton from './contact-details-skeleton/contact-details-skeleton.component';
import {
  AccentText,
  AtsConnected,
  AtsIcon,
  ConnectPositionToAts,
} from '../../../pages/position-overview-page/position-overview.styled';

const MatchSidePanel = ({
  isOpen, onClose, match, onSocialLinkClick, position, isLoading, isAutoMode, isStepSelected,
}: MatchSidePanelProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const isLocked = useShouldLockFeature();
  const pageSize = useConversationPageSize();
  const [isAccountUpgradeDialogOpen, setIsAccountUpgradeDialogOpen] = useState(false);
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { contactDetails = {} as MatchContactDetailsResponse, talent } = match;
  const [isMatchModalOpen, setIsMatchModalOpen] = useState<boolean>(false);
  const [isAlertSnackbarOpen, setIsAlertSnackbarOpen] = useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  const [isInfoSnackBarOpen, setIsInfoSnackBarOpen] = useState(false);
  const [infoSnackBarText, setInfoSnackBarText] = useState('');
  const exportCSV = useExportMatchCSV(position?.id, match?.id, match?.talent?.fullName);

  const baseEventPayloadData = useMemo(() => ({
    pageName: PAGE_NAMES.TALENT_OUTREACH,
    productArea: PRODUCT_AREA.MATCH_PAGE,
    matchEngagementStatus: match?.engagement?.status,
    matchId: match?.id,
    matchTalentId: match?.talent?.id,
    pagePositionId: position?.id,
    pagePositionName: position?.jobTitle,
    pagePositionStatus: position?.status,
  }), [position, match]);

  const contacts = useMemo(() => {
    const phones = contactDetails?.phoneNumbers?.reduce((acc, phone) => {
      acc.push({
        type: ContactType.Phone,
        value: phone,
      });

      return acc;
    }, [] as Contact[]) || [] as Contact[];

    const emails = contactDetails?.emails?.reduce((acc, email) => {
      acc.push({
        type: ContactType.Email,
        value: email,
      });

      return acc;
    }, [] as Contact[]) || [] as Contact[];

    return [...emails, ...phones] as Contact[];
  }, [contactDetails]);

  const socialLinks = useMemo(() => {
    return talent?.socialLinks.map((social) => ({
      type: social.type,
      url: social.url,
    }) as SocialLink);
  }, [talent]);

  useEffect(() => {
    if (isOpen) {
      const eventData = {
        eventName: ANALYTICS_EVENTS.MATCH_ENGAGE_PANEL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
        pageName: PAGE_NAMES.TALENT_OUTREACH,
        productArea: PRODUCT_AREA.MATCH_PAGE,
        componentName: COMPONENT_NAME.ENGAGEMENT_POP_UP,
        matchId: match.id,
        pagePositionId: position.id,
      };
      analyticsTrackEvent(eventData);
      if (contacts.length) {
        const contactInfoEventData = {
          eventName: ANALYTICS_EVENTS.MATCH_CONTACT_INFO_SHOWN,
          eventGroup: EVENT_GROUP.IMP,
          pageName: PAGE_NAMES.TALENT_OUTREACH,
          productArea: PRODUCT_AREA.MATCH_PAGE,
          componentName: COMPONENT_NAME.ENGAGEMENT_POP_UP,
          matchId: match.id,
          pagePositionId: position.id,
        };
        analyticsTrackEvent(contactInfoEventData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onContactInfoClick = () => {
    if (isLocked) {
      setIsAccountUpgradeDialogOpen(true);

      return;
    }

    analyticsTrackEvent({
      ...baseEventPayloadData,
      eventName: ANALYTICS_EVENTS.CLICKED_ON_CONTACT_INFO,
      eventGroup: EVENT_GROUP.CLICK,
      componentName: COMPONENT_NAME.ENGAGEMENT_POP_UP,
    });
  };

  const handleExportCSVClick = async () => {
    setIsExportLoading(true);
    await exportCSV();
    setIsExportLoading(false);
    // todo: @din @vova - reduce code duplication
    setInfoSnackBarText(translate(STRING_KEYS.EXPORT_MATCHES.SUCCESS_MESSAGE));
    setIsInfoSnackBarOpen(true);
  };

  const renderContactsInfo = (): JSX.Element | null => {
    if (match.contactDetails?.lookupInProgress) {
      return <ContactDetailsSkeleton />;
    }

    if (contacts.length) {
      return (
        <ContactDetailsInfoContainer>
          <ContactsDetails contacts={ contacts } isLocked={ isLocked } onClick={ onContactInfoClick } />
          { isLocked && (
            <ContactProBadge onClick={ () => setIsAccountUpgradeDialogOpen(true) } >
              <ContactProIcon />
              <div>{ translate(STRING_KEYS.PRO) }</div>
            </ContactProBadge>
          ) }
        </ContactDetailsInfoContainer>
      );
    }

    return (
      <ContactDetailsInfoContainer>
        <MissingContacts>
          {translate(STRING_KEYS.ENGAGE_PANEL.MISSING_CONTACTS.BOLD_TEXT)}
          <span>{translate(STRING_KEYS.ENGAGE_PANEL.MISSING_CONTACTS.TEXT)}</span>
        </MissingContacts>
      </ContactDetailsInfoContainer>
    );
  };

  const renderSocialLinks = (): JSX.Element | null => {
    if (socialLinks?.length) {
      return (
        <SocialLinks socialLinks={ socialLinks.slice(0, 7) } onSocialLinkClick={ onSocialLinkClick } />
      );
    }

    return null;
  };

  const onAccountUpgradeDialogCloseButtonClick = () => {
    setIsAccountUpgradeDialogOpen(false);
  };

  const panelVariant = useMemo(() => {
    if (!isAutoMode && pageSize === ConversationPageSizeEnum.Small) {
      return SidePanelVariant.Temporary;
    }

    if (isAutoMode) {
      if (isStepSelected) {
        if (pageSize === ConversationPageSizeEnum.Large) {
          return SidePanelVariant.Persistent;
        }
      } else if (pageSize !== ConversationPageSizeEnum.Small) {
        return SidePanelVariant.Persistent;
      }

      return SidePanelVariant.Temporary;
    }

    return SidePanelVariant.Persistent;
  }, [isAutoMode, pageSize, isStepSelected]);

  const onTalentNetworkCardsComponentIntersect = (args: OnInsightWidgetIntersectionHandlerArguments) => {
    analyticsTrackEvent({
      ...baseEventPayloadData,
      eventName: ANALYTICS_EVENTS.ENGAGEMENT_PANEL_TALENT_NETWORK_LOADED,
      eventGroup: EVENT_GROUP.IMP,
      componentMetadata: args.metadata,
      componentMetadataType: args.metadataType,
    });
  };

  const onTalentNetworkCardsSeeMoreClick = () => {
    analyticsTrackEvent({
      ...baseEventPayloadData,
      eventName: ANALYTICS_EVENTS.ENGAGEMENT_PANEL_TALENT_NETWORK_SEE_MORE,
      eventGroup: EVENT_GROUP.IMP,
    });
  };

  const renderTalentAtsIndication = () => {
    if (!match.previousFamiliarity || !isPreviousFamiliarityValid(match?.previousFamiliarity)) {
      return null;
    }

    return (
      <StyledTalentAtsIndication previousFamiliarity={ match.previousFamiliarity } />
    );
  };

  return (
    <>
      <SidePanel
        isOpen={ isOpen }
        onClose={ onClose }
        variant={ panelVariant }
      >
        <SidePanelContainer>
          { isLoading && (
            <MatchSidePanelLoader />
          ) }
          { !isLoading && (
            <AnimationWrapper>
              <SidePanelHeaderContainer
                closeButtonClickHandler={ panelVariant === SidePanelVariant.Temporary ? onClose : undefined }
              >
                <Header>
                  <HeaderTitle>
                    {translate(STRING_KEYS.ENGAGE_PANEL.TITLE, { name: match.talent?.firstName })}
                  </HeaderTitle>
                </Header>
              </SidePanelHeaderContainer>
              <MatchEngagementSidePanelContainer>
                { renderTalentAtsIndication() }
                <AboutContainer>
                  <AboutTitle>{translate(STRING_KEYS.ENGAGE_PANEL.ABOUT_TITLE)}</AboutTitle>
                  { renderSocialLinks() }
                </AboutContainer>
                <AboutSentenceContainer>
                  <div>
                    <TalentInfoSentence
                      firstName={ match.talent?.firstName }
                      currentJobTitle={ match.talentInfo?.currentJobTitle }
                      highlightWords={ match.talent?.employmentHistory.map((history) => history.companyName) }
                      currentJobExperienceInMonths={ match.talentInfo?.currentJobExperienceInMonths }
                      currentCompanyName={ match.talentInfo?.currentCompanyName }
                      matchSummary={ match.summary?.text }
                    />
                  </div>
                  <ExportCSVButton
                    onClick={ handleExportCSVClick }
                    StartIcon={ ExportMatchesIcon }
                    hoverVariant={ PerfectButtonHoverVariants.Grow }
                    isLoading={ isExportLoading }
                  >
                    { translate(STRING_KEYS.EXPORT_MATCHES.SIDE_PANEL_BUTTON) }
                  </ExportCSVButton>
                  <GoToMatchButton
                    variant={ PerfectButtonVariants.Fill }
                    size={ PerfectButtonSize.Medium }
                    StartIcon={ ProfileIcon }
                    onClick={ () => setIsMatchModalOpen(true) }
                  >
                    { translate(STRING_KEYS.ENGAGE_PANEL.CONTACT_DETAILS_GO_TO_MATCH_BUTTON) }
                  </GoToMatchButton>
                </AboutSentenceContainer>
                <DividerWrapper><Divider /></DividerWrapper>
                <ContactDetailsContainer>
                  <ContactDetailsColumnContainer>
                    <ContactHeaderContainer>
                      <ContactTitle>{translate(STRING_KEYS.ENGAGE_PANEL.CONTACT_DETAILS_TITLE)}</ContactTitle>
                      <AtsButton
                        color={ THEME.COLORS.ACCENT }
                        size={ PerfectButtonSize.Small }
                        matchId={ match.id }
                        positionId={ position.id }
                        errorHandler={ () => setIsAlertSnackbarOpen(true) }
                      />
                    </ContactHeaderContainer>
                    { renderContactsInfo() }
                  </ContactDetailsColumnContainer>
                </ContactDetailsContainer>
                <DividerWrapper><Divider /></DividerWrapper>
                { !!match.networkedTalents?.length && (
                  <TalentNetworkContainer>
                    <TalentNetworkCardsComponent
                      networkedTalents={ match.networkedTalents || [] }
                      matchTalentName={ match.talent?.fullName }
                      intersectionHandler={ onTalentNetworkCardsComponentIntersect }
                      seeMoreClickHandler={ onTalentNetworkCardsSeeMoreClick }
                    />
                  </TalentNetworkContainer>
                ) }
              </MatchEngagementSidePanelContainer>
            </AnimationWrapper>
          ) }
        </SidePanelContainer>
      </SidePanel>
      { isAccountUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isAccountUpgradeDialogOpen }
          onCloseDialog={ onAccountUpgradeDialogCloseButtonClick }
        />
      )}
      <MatchCardModal
        positionId={ position.id }
        matchId={ match.id }
        isOpen={ isMatchModalOpen }
        closeHandler={ () => setIsMatchModalOpen(false) }
      />
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
        isOpen={ isAlertSnackbarOpen }
        onClose={ () => setIsAlertSnackbarOpen(false) }
        shouldAutoDismiss
      />
      <InfoSnackBar
        title={ infoSnackBarText }
        isOpen={ isInfoSnackBarOpen }
        onClose={ () => setIsInfoSnackBarOpen(false) }
        shouldAutoDismiss
        icon={ ExportMatchesIcon }
      />
    </>
  );
};

export default MatchSidePanel;
