import { Divider } from '../../../../../Common/Divider';
import {
  IconSkeleton,
  RolesSkeleton,
  RolesLastSkeleton,
  CompanyDescription,
  CompanyNameLine,
  LineContainer,
  TitlesContainer,
  YearsLine,
  RolesWrapper,
  LineWrapper,
  Line,
} from './experience-information-chapter.styled';

export const ExperienceChapter = (): JSX.Element => {
  return (
    <>
      <LineContainer>
        <IconSkeleton />
        <TitlesContainer>
          <YearsLine />
          <CompanyNameLine />
          <CompanyDescription />
          <RolesWrapper>
            <RolesSkeleton />
            <RolesSkeleton />
            <RolesLastSkeleton />
            <Divider />
          </RolesWrapper>
        </TitlesContainer>
      </LineContainer>
      <LineWrapper>
        <Line />
      </LineWrapper>
    </>
  );
};
