import Lottie from 'react-lottie';
import loaderAnimation from '../../../../../../assets/lottie/fab_loader.json';
import { SidePanelLoaderContainer } from './match-side-panel-loader.styled';

const MatchSidePanelLoader = ():JSX.Element => {
  return (
    <SidePanelLoaderContainer>
      <Lottie
        options={ {
          animationData: loaderAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        } }
        height={ 56 }
        width={ 200 }
      />
    </SidePanelLoaderContainer>
  );
};

export default MatchSidePanelLoader;
