import { useDispatch } from 'react-redux';
import { TalentNetworkTimelineBase } from '../../../talent-network-timeline-base/talent-network-timeline-base.component';
import { setTalentsNetworkDialogData } from '../../../../../../store/slices/match-state/match-state.toolkit-slice';
import { useShouldLockFeature } from '../../../../../../hooks/should-lock-feature.hook';
import { MutualEducationSentence } from '../../../../../../modules/application/components/talent-network/mutual-education-sentence/mutual-education-sentence.component';
import { EducationItemTalentNetworkProps } from './education-item-talent-network';

export const EducationItemTalentNetwork = ({ institutionName, networkedTalents }: EducationItemTalentNetworkProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const isLocked = useShouldLockFeature();

  if (!networkedTalents?.length) {
    return null;
  }

  const onClick = () => {
    const educationIds = networkedTalents.map((networkedTalent) => networkedTalent.education.educationId);
    dispatch(setTalentsNetworkDialogData({ isOpen: true, selectedEducationIds: educationIds.length ? new Set(educationIds) : undefined }));
  };

  return (
    <TalentNetworkTimelineBase
      onClickHandler={ onClick }
      isLocked={ isLocked }
      talentsInfo={ networkedTalents.map((networkedTalent) => networkedTalent.talent) }
    >
      <span>
        <MutualEducationSentence institutionName={ institutionName } networkedTalents={ networkedTalents } />
      </span>
    </TalentNetworkTimelineBase>
  );
};
