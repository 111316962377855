import React, { useRef } from 'react';
import { CompanyInsightData } from '../../../../../hooks/get-company-by-id.hook';
import { Body1Font } from '../../../../../themes/fonts';
import { getMonthsDifference } from '../../../../../utils/dates';
import { useIntersection } from '../../../../../hooks/useIntersection';
import { NO_OVERLAP_ID } from '../../talent-network-timeline-base/talent-network-timeline-base.consts';
import { ANALYTICS_EVENTS } from '../../../../../consts/analytics';
import { TalentNetworkExperienceDto } from '../../../../../modules/application/components/talent-network/mutual-experience-sentence/mutual-experience-sentence';
import { CompanyLogo } from './experience-item-company-logo/experience-item-company-logo.component';
import { RoleTitle } from './experience-item-role-title/experience-item-role-title.component';
import { RoleDecorator } from './experience-item-role-decorator/experience-item-role-decorator.component';
import {
  ExperienceItemContainer,
  DescriptionSection,
  ExperienceLogoDivider,
  Divider,
  RoleItem,
  RoleYearsContainer,
  SmallDivider,
  IconAndDividerWrapper,
  RoleTitleWrapper,
  TalentNetworkWrapper,
} from './experience-item.styled';
import { YearsRange } from './experience-item-years-range/experience-item-years-range.component';
import { PromotedBadge } from './experience-item-promoted-badge/experience-item-promoted-badge.component';
import { ExperienceItemTalentNetwork } from './experience-item-talent-network/experience-item-talent-network.component';

const COMPONENT_ELEMENT_TYPE = 'experience';

type ExperienceItemProps = {
  companyData: CompanyInsightData,
  data: Array<RoleInformation>,
  isLastExperienceChapter: boolean,
  showCompanyInsights: boolean,
  keyInsights: string,
  trackCompanyInsightsClick?: (
    matchExperiencePeriod: string,
    matchExperienceCompanyId: string,
    matchExperienceCompanyName: string,
  ) => void,
  trackMatchElementViewed?: (eventName: string, componentElementType: string, componentElement?: string) => void,
  talentNetworkExperienceMap?: Map<number, TalentNetworkExperienceDto[]>,
  talentSkills: SkillData[],
}

// TODO: delete
const ExperienceItemComponent = ({
  companyData,
  data,
  isLastExperienceChapter,
  showCompanyInsights,
  keyInsights,
  trackCompanyInsightsClick,
  trackMatchElementViewed,
  talentNetworkExperienceMap,
  talentSkills,
}: ExperienceItemProps): JSX.Element => {
  const lastExperienceItemRef = useRef<HTMLDivElement>(null);
  useIntersection({
    element: lastExperienceItemRef,
    callback: () => {
      if (isLastExperienceChapter) {
        trackMatchElementViewed?.(ANALYTICS_EVENTS.MATCH_ELEMENT_VIEWED, COMPONENT_ELEMENT_TYPE);
      }
    },
  });
  const latDataObject: RoleInformation = data.length ?
    data[data.length - 1] : {} as RoleInformation;
  const lastDate = latDataObject && latDataObject.endDateObject;

  const isSingleRole = data.length === 1;

  const rolesToShow = isSingleRole ? [] : [...data].reverse();

  const isPromoted = (roleIndex:number) => {
    if (roleIndex >= rolesToShow.length - 1) {
      // the oldest role (last in the list)
      return false;
    }

    const laterRoleSeniorityLevel = rolesToShow[roleIndex].seniorityLevel;
    const olderRoleSeniorityLevel = rolesToShow[roleIndex + 1].seniorityLevel;

    if ((typeof laterRoleSeniorityLevel === undefined) ||
      (typeof olderRoleSeniorityLevel === undefined)) {
      return false;
    }

    return (Number(laterRoleSeniorityLevel) > Number(olderRoleSeniorityLevel));
  };

  const renderTalentNetworkCompanyLevel = () => {
    if (!talentNetworkExperienceMap) {
      return null;
    }

    let experienceData: TalentNetworkExperienceDto[] = [];
    if (data.length === 1) {
      experienceData = [];
      talentNetworkExperienceMap.forEach((networkedTalent) => {
        experienceData.push(...networkedTalent);
      });
    } else {
      experienceData = talentNetworkExperienceMap.get(NO_OVERLAP_ID) || [];
    }

    if (!experienceData.length) {
      return null;
    }

    return (
      <TalentNetworkWrapper>
        <ExperienceItemTalentNetwork companyName={ companyData.companyName } networkedTalents={ experienceData }  />
      </TalentNetworkWrapper>
    );
  };

  const renderTalentNetworkExperienceLevel = (role: RoleInformation) => {
    if (!talentNetworkExperienceMap) {
      return null;
    }

    const experienceData = talentNetworkExperienceMap.get(role.experienceId);

    if (!experienceData) {
      return null;
    }

    const experienceNetworkedTalents = talentNetworkExperienceMap.get(role.experienceId);

    if (!experienceNetworkedTalents) {
      return null;
    }

    return (
      <TalentNetworkWrapper>
        <ExperienceItemTalentNetwork companyName={ companyData.companyName } networkedTalents={ experienceData }  />
      </TalentNetworkWrapper>
    );
  };

  return (
    <ExperienceItemContainer ref={ isLastExperienceChapter ? lastExperienceItemRef : undefined }>
      <IconAndDividerWrapper>
        <CompanyLogo
          companyLogo={ companyData.companyLogo }
          companyName={ companyData.companyName }
        />
        { !isLastExperienceChapter && <ExperienceLogoDivider /> }
      </IconAndDividerWrapper>
      <DescriptionSection>
        <YearsRange
          startDate={ data[0].startDateObject }
          endDate={ lastDate }
          font={ Body1Font }
        />
        <CompanyHeader
          title={ data[0].title }
          companyData={ companyData }
          showCompanyInsights={ showCompanyInsights }
          keyInsights={ keyInsights }
          isSingleRole={ data.length === 1 }
          trackCompanyInsightsClick={ trackCompanyInsightsClick }
          employmentPeriod={ getMonthsDifference(data[0].startDateObject, lastDate) }
          talentSkills={ talentSkills }
        />
        { renderTalentNetworkCompanyLevel() }
        {
          rolesToShow.map((roleItem: RoleInformation, index: number) => {
            const isRoleItemPromoted = isPromoted(index);
            const isLastRole = index === rolesToShow.length - 1;

            return (
              <RoleItem key={ roleItem.title }>
                <RoleDecorator isPromoted={ isRoleItemPromoted } />
                <RoleYearsContainer>
                  <RoleTitleWrapper>
                    <RoleTitle
                      title={ roleItem.title }
                    />
                    {isRoleItemPromoted && <PromotedBadge />}
                  </RoleTitleWrapper>
                  <YearsRange
                    startDate={ roleItem.startDateObject }
                    endDate={ roleItem.endDateObject }
                    font={ Body1Font }
                  />
                </RoleYearsContainer>
                { renderTalentNetworkExperienceLevel(roleItem) }
                {!isLastRole && <SmallDivider />}
              </RoleItem>
            );
          })
        }
        <Divider />
      </DescriptionSection>

    </ExperienceItemContainer>
  );
};

ExperienceItemComponent.defaultProps = {
  trackCompanyInsightsClick: undefined,
  trackMatchElementViewed: undefined,
  talentNetworkExperienceMap: undefined,
};

export default React.memo(ExperienceItemComponent);
