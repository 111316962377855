import { useTranslation } from 'react-i18next';
import React from 'react';
import STRING_KEYS from '../../../../language/keys';
import { CompanyIndustry } from '../../../../@types/match';
import { CompanyEnrichedData } from '../../../../@types/api';
import {
  ListItem,
  ListItemIndex,
  LearnMoreButton,
  Icon,
  Text,
  ListItemStartWrapper,
  SubText,
  TextContainer,
} from './insight-list.styled';

export interface InsightListItem {
  id: string;
  text: string;
  industries?: CompanyIndustry[];
  companyEnrichedData?: CompanyEnrichedData;
  subText?: string;
  icon: string;
}

interface InsightListProps {
  items: InsightListItem[];
  activeItem?: string;
  iconFallback?: string;
  onLearnMoreClick?: (selectedItem: InsightListItem, targetElement: HTMLElement) => void;
}

export const InsightList = ({
  items,
  activeItem,
  onLearnMoreClick,
  iconFallback,
}:InsightListProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const onLearnMoreButtonClick = (selectedItem: InsightListItem, event: React.MouseEvent<HTMLElement>) => {
    if (onLearnMoreClick) {
      onLearnMoreClick(selectedItem, event.currentTarget);
    }
  };

  const onIconError = (target: EventTarget & HTMLImageElement) => {
    target.onerror = null; // prevents looping
    if (iconFallback) {
      target.src = iconFallback;
    }
  };

  const renderCompanyList = () => {
    return items.map((item: InsightListItem, index: number) => (
      <ListItem
        key={ item.text }
        onClick={ (event: React.MouseEvent<HTMLElement>) => onLearnMoreButtonClick(item, event) }
        activateHover={ !!onLearnMoreClick }
        isActive={ item.id === activeItem }
      >
        <ListItemStartWrapper>
          <ListItemIndex>{ index + 1 }.</ListItemIndex>
          <Icon
            src={ item.icon || iconFallback }
            alt="logo"
            onError={ ({ currentTarget }) => onIconError(currentTarget) }
          />
          <TextContainer>
            <Text>{ item.text }</Text>
            { item.subText && <SubText>{ item.subText }</SubText> }
          </TextContainer>
        </ListItemStartWrapper>
        {onLearnMoreClick && (
          <div>
            <LearnMoreButton>
              {translate(STRING_KEYS.LEARN_MORE)}
            </LearnMoreButton>
          </div>
        )}
      </ListItem>
    ));
  };

  return (
    <>
      { renderCompanyList() }
    </>
  );
};

InsightList.defaultProps = {
  onLearnMoreClick: undefined,
  activeItem: undefined,
  iconFallback: undefined,
};
