import { SectionItemTitle } from '../message-additional-options.styled';
import {
  Container, Description, Input, TitleContainer,
} from './text-input-item.styled';
import { TextInputItemProps } from './text-input-item';

const TextInputItem = ({
  title,
  value,
  description,
  placeholder,
  changeHandler,
}: TextInputItemProps): JSX.Element => {
  return (
    <Container>
      <TitleContainer>
        <SectionItemTitle>{ title }</SectionItemTitle>
        <Description>{ description }</Description>
      </TitleContainer>
      <Input
        placeholder={ placeholder }
        onChange={ changeHandler }
        value={ value }
      />
    </Container>
  );
};

export default TextInputItem;
