import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import Dialog from '../base-dialog/base-dialog.component';
import STRING_KEYS from '../../../language/keys';
import lightningAnimation from '../../../assets/lottie/lightning.json';
import {
  Description,
  HeaderContainer,
  TextContainer,
  Title,
} from './automating-campaign-dialog.styled';

const AutomatingCampaignDialog = ({ isOpen, closeHandler }: AutomatingCampaignDialog): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ () => closeHandler?.() }
      widthSize="800px"
      portalUniqueId={ AutomatingCampaignDialog.name }
      disableCloseButton
    >
      <HeaderContainer>
        <Lottie
          options={ {
            animationData: lightningAnimation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          } }
          height={ 144 }
          width={ 123 }
        />
        <TextContainer>
          <Title>{ translate(STRING_KEYS.AUTOMATING_CAMPAIGN_DIALOG.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.AUTOMATING_CAMPAIGN_DIALOG.DESCRIPTION) }</Description>
        </TextContainer>
      </HeaderContainer>
    </Dialog>
  );
};

export default AutomatingCampaignDialog;
