import React, { useRef } from 'react';
import { useTextWidth } from '@tag0/use-text-width';
import { KEYBOARD_KEYS } from '../../../../consts/keyboard_keys';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import {
  OnboardingInput,
  EndAdornmentContainer,
  StartAdornmentContainer,
  DefaultTextField,
  DefaultTextFieldWrapper,
} from './input.styled';

export enum InputStyle {
  Regular = 'REGULAR',
  Onboarding = 'ONBOARDING',
}

export enum InputSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
}

type InputProps = {
  id?: string;
  placeholder: string,
  label?: React.ReactNode,
  value: string,
  style?: InputStyle,
  onValueChange: null | ((value: string) => void),
  onArrowDown?: () => void,
  onArrowUp?: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onEnter?: () => void,
  alternativeEnterChars?: string[],
  alternativeEnterCharClickHandler?: () => void,
  onTransitionEnd?: () => void,
  isDisableTyping?: boolean,
  autoFocus: boolean,
  isInputFocused?: boolean,
  isListOpened?: boolean,
  isError?: boolean,
  inputSize?: InputSize,
  StartAdornment?: JSX.Element,
  EndAdornment?: JSX.Element,
}

export const Input = ({
  id,
  placeholder,
  label,
  value,
  style,
  onValueChange,
  onArrowDown,
  onArrowUp,
  onFocus,
  onBlur,
  onTransitionEnd,
  onEnter,
  alternativeEnterChars,
  alternativeEnterCharClickHandler,
  isDisableTyping,
  autoFocus,
  isInputFocused = false,
  isListOpened,
  isError,
  inputSize,
  StartAdornment,
  EndAdornment,
}: InputProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const { height } = useWindowDimensions();
  const fontsSize = height <= 560 ? 24 : 32;

  const width = useTextWidth({ text: value || placeholder, font: `${fontsSize}px Merriweather` });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const isAlternativeEnterChar = alternativeEnterChars?.includes(event.key);
    if (event.key === KEYBOARD_KEYS.ENTER || isAlternativeEnterChar) {
      onEnter?.();
      (event.target as HTMLElement).blur();
      if (isAlternativeEnterChar) {
        alternativeEnterCharClickHandler?.();
      }
    }
    if (event.key === KEYBOARD_KEYS.ARROW_DOWN) {
      onArrowDown?.();
    }

    if (event.key === KEYBOARD_KEYS.ARROW_UP) {
      onArrowUp?.();
    }

    // todo: handle escape click
    // if (event.key === ESCAPE) {
    //   (event.target as HTMLElement).blur();
    //   closeOptionList();
    // }

    return false;
  };

  const handleValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof (onValueChange) === 'function') {
      onValueChange(event.target.value);
    }
  };

  const isPreFilled = (value === '' || value === placeholder) && !isInputFocused;
  const isHovered =  !isInputFocused && !isError;
  const emptyActive =  isInputFocused && value === '';
  const typingState = isInputFocused && value !== '';

  const renderOnboardingInput = () => {
    return (
      <OnboardingInput
        id={ id }
        ref={ ref }
        autoFocus={ autoFocus }
        onKeyDown={ handleKeyDown }
        width={ width }
        onChange={ handleValueChanged }
        value={ value }
        placeholder={ placeholder }
        onFocus={ onFocus }
        onBlur={ onBlur }
        isDisableTyping={ !!isDisableTyping }
        isInputFocused={ isInputFocused }
        readOnly={ isDisableTyping }
        isListOpened={ !!isListOpened }
        isError={ !!isError }
        isPreFilled={ isPreFilled }
        isHovered={ isHovered }
        emptyActive={ emptyActive }
        typingState={ typingState }
        onTransitionEnd={ () => onTransitionEnd?.() }
        autoComplete="one-time-code"
      />
    );
  };

  const renderDefaultInput = () => {
    return (
      <DefaultTextFieldWrapper>
        <DefaultTextField
          id={ id }
          label={ label }
          variant="filled"
          autoFocus={ autoFocus }
          onKeyDown={ handleKeyDown }
          onChange={ handleValueChanged }
          value={ value }
          placeholder={ placeholder }
          onFocus={ onFocus }
          onBlur={ onBlur }
          size="small"
          isError={ !!isError }
          inputSize={ inputSize }
          hasLabel={ !!label }
          InputProps={ {
            disableUnderline: true,
            fullWidth: true,
          } }
          InputLabelProps={ {
            shrink: true,
          } }
        />
      </DefaultTextFieldWrapper>
    );
  };

  const renderInput = () => {
    switch (style) {
      case InputStyle.Onboarding:
        return renderOnboardingInput();
      case InputStyle.Regular:
      default:
        return renderDefaultInput();
    }
  };

  return (
    <>
      {
        StartAdornment && (
          <StartAdornmentContainer>
            { StartAdornment }
          </StartAdornmentContainer>
        )
      }
      { renderInput() }
      {
        EndAdornment && (
          <EndAdornmentContainer>
            { EndAdornment }
          </EndAdornmentContainer>
        )
      }
    </>
  );
};

Input.defaultProps = {
  id: undefined,
  StartAdornment: undefined,
  EndAdornment: undefined,
  onArrowDown: undefined,
  onArrowUp: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onEnter: undefined,
  onTransitionEnd: undefined,
  isDisableTyping: undefined,
  isListOpened: undefined,
  isInputFocused: undefined,
  isError: undefined,
  style: InputStyle.Onboarding,
  label: undefined,
  alternativeEnterCharClickHandler: undefined,
  alternativeEnterChars: undefined,
  inputSize: InputSize.Medium,
};
