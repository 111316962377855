/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import log from 'loglevel';
import { useLocation } from 'react-router-dom';
import { useAppState } from '../store/selectors';
import { addSubDomainToUrl, getSubDomain, replaceSubDomain } from '../utils/url';
import { AppRouting } from '../services/appRoutingResolver';
import { removeInitialLoadings } from '../store/slices/app-state/app-state.toolkit-slice';
import { SubDomainType } from '../store/slices/app-state/app-state.enums';
import { useGetAccountByIdQuery } from '../store/rtk-query/apis/account/hooks/get-account-by-id.query-hook';
import { useGetMyUserQuery } from '../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import UserLoggingInfoManger from '../services/userLoggingInfoManger';
import RudderStackAnalyticsAgent from '../services/rudderStackAnalyticsAgent';
import { useLoadRudderstackState } from '../store/selectors/app-loader.selectors';
import { PricingPlanEnum } from '../enums/user-plan.enum';
import { AccountStatusEnum } from '../enums/account-status.enum';
import { IntercomManager } from '../services/intercom-manager';
import { useFronteggWatcher } from './frontegg-watcher.hooks';
import { useInitializeRudderStack } from './initialize-rudderstack.hook';
import { useIsSuperUser } from './superuser';

const logger = log.getLogger('PERFECT_AUTHENTICATION_HOOK');

type UsePerfectAuthentication = {
  requiredApisLoading: boolean,
  isAuthenticated: boolean,
  isOnboarding: boolean,
  shouldCompleteUserActivationFlow: boolean,
  isPlanExpired: boolean;
}

export const usePerfectAuthentication = (): UsePerfectAuthentication => {
  const currentSubDomain = getSubDomain(window.location.hostname);
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const { isAuthenticated } = useFronteggWatcher();
  const { subDomainType } = useAppState();
  const isSuperUser = useIsSuperUser();
  const isRudderstackLoadingCompleted = useLoadRudderstackState();
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
  } = useGetMyUserQuery({ skip: !isAuthenticated });
  const {
    data: account,
    isLoading: isAccountLoading,
    isError: isAccountError,
    isSuccess: isAccountSuccess,
  } = useGetAccountByIdQuery(user.accountId);

  const {
    isOnboarding, accountId, role: userRole, id: userId, email: userEmail, firstName,
  } = user;

  const {
    subDomainName: userSubDomain, displayName: accountName, status: accountStatus, plan,
  } = account;

  useInitializeRudderStack(isSuperUser);

  useEffect(() => {
    if (!isAuthenticated) {
      logger.debug(
        'The user is not authenticated, the user will be redirected to the Login page',
        {
          subDomain: getSubDomain(window.location.hostname),
          url: window.location.href,
        },
      );
      dispatch(removeInitialLoadings());
    }

    if (
      subDomainType === SubDomainType.GlobalActivation &&
      window.location.pathname !== AppRouting.ROUTES.ACTIVATE_ACCOUNT
    ) {
      logger.warn(
        'Found Global-Activation sub-domain but with an unexpected trailing path. The user will be redirected to the Login page',
        {
          path: window.location.pathname,
          currentSubDomain,
        },
      );
      AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
    }

    if (isOnboarding && subDomainType !== SubDomainType.Onboarding) {
      logger.warn(
        'The user has not completed onboarding but is trying to use a non-onboarding subdomain. The user will be redirected to onboarding subdomain',
        {
          path: window.location.pathname,
          currentSubDomain,
          isOnboarding,
        },
      );
      AppRouting.navigateToUrl(AppRouting.buildGlobalOnboardingUrl(window.location));
    }

    if (userSubDomain && currentSubDomain !== userSubDomain?.toLowerCase()) {
      const { location } = window;
      logger.warn(
        'The user was authenticated, but not in the correct account subdomain. The user will be redirected to his account subdomain',
        {
          path: window.location.pathname,
          userSubDomain,
          currentSubDomain,
        },
      );

      const newUrl = !currentSubDomain ?
        addSubDomainToUrl(location.origin, location.host, userSubDomain) :
        replaceSubDomain(location.origin, currentSubDomain, userSubDomain);

      const urlWithQueryParams = newUrl + queryParams;

      AppRouting.navigateToUrl(urlWithQueryParams);
    }
  }, [userSubDomain, subDomainType, accountId, isAccountLoading, isAccountError, isAuthenticated, isUserError, isOnboarding, dispatch, currentSubDomain]);

  const userAuthenticatedCompletely = useMemo(() => {
    if (userSubDomain === currentSubDomain) {
      return subDomainType === SubDomainType.Account && isUserSuccess && isAccountSuccess;
    }

    return false;
  }, [userSubDomain, currentSubDomain, subDomainType, isUserSuccess, isAccountSuccess]);

  useEffect(() => {
    if (userAuthenticatedCompletely && isRudderstackLoadingCompleted) {
      RudderStackAnalyticsAgent.group(accountId, {
        name: accountName,
        status: accountStatus,
        plan: plan.pricingPlan,
      });

      UserLoggingInfoManger.saveUserLoggingInfoData({
        userId,
        userEmail,
        userIsOnboarding: isOnboarding,
        accountId,
        accountName,
        accountStatus,
        maximumOpenPositions: plan.maximumOpenPositions,
      });

      if (user.intercomUserHash) {
        IntercomManager.init(user.intercomUserHash);
      }
    }

    logger.info(
      'User was identified successfully', {
        displayName: `${firstName}`,
        accountName,
        accountId,
        accountStatus,
        userId,
        userEmail,
        plan,
      },
    );
  }, [userAuthenticatedCompletely, isRudderstackLoadingCompleted]);

  return {
    requiredApisLoading: isAccountLoading || isUserLoading,
    isAuthenticated: userAuthenticatedCompletely,
    isOnboarding: isOnboarding || subDomainType === SubDomainType.Onboarding,
    shouldCompleteUserActivationFlow: userAuthenticatedCompletely && !isOnboarding && !userRole,
    isPlanExpired: (plan?.isExpired || plan?.pricingPlan === PricingPlanEnum.LITE) && accountStatus !== AccountStatusEnum.Onboarding,
  };
};
