/* eslint-disable react/jsx-props-no-spreading */
import { MouseEvent } from 'react';
import { Container, Toggle } from './base-switch.styled';
import { BaseSwitchProps } from './base-switch';
import { BaseSwitchSize } from './base-switch.enums';

const BaseSwitch = ({
  checked = false,
  disabled = false,
  switchChangeHandler,
  checkedColor,
  size = BaseSwitchSize.Medium,
  CheckedIcon,
  UnCheckedIcon,
  ...otherProps
}: BaseSwitchProps): JSX.Element => {
  const renderIcon = () => {
    if (checked && CheckedIcon) {
      return CheckedIcon;
    }
    if (!checked && UnCheckedIcon) {
      return UnCheckedIcon;
    }

    return null;
  };

  const onSwitchClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!disabled) {
      switchChangeHandler(!checked);
    }
  };

  return (
    <Container
      checked={ checked }
      checkedColor={ checkedColor }
      disabled={ disabled }
      onClick={ onSwitchClick }
      size={ size }
      { ...otherProps }
    >
      <Toggle checked={ checked } size={ size }>
        { renderIcon() }
      </Toggle>
    </Container>
  );
};

export default BaseSwitch;
