import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Subtitle2Font } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';

export const PromotedBadgeContainer = styled.div`
  height: 24px;
  line-height: 24px;
  width: 104px;
  min-width: 104px;
  border-radius: ${THEME.RADIUS.TIDY};
  border: solid 1px rgba(251, 229, 91, ${THEME.OPACITY.LOW});
  background-color: rgba(251, 229, 91, 0.18);
  text-align: center;
  :before {
    content: url(${ASSETS.CHART_UP});
    margin-right: ${THEME.SPACING.MICRO};
}
`;

export const PromotionLabel = styled.span`
  ${Subtitle2Font};
`;
