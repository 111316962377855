import { useTranslation } from 'react-i18next';
import { FlattenSimpleInterpolation } from 'styled-components';
import STRING_KEYS from '../../../../../../language/keys';
import { getYearsAndMonthsDiffString } from '../../../../../../utils/dates';

import { TimePeriodContainer } from './experience-item-years-range.styled';

type YearsRangeProps = {
    startDate: DateObject,
    endDate: DateObject | undefined,
    font: FlattenSimpleInterpolation,
}

export const YearsRange = ({ startDate, endDate, font }: YearsRangeProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const startDateString = `${startDate ?
    `${startDate.year}` :
    ''}`;

  if (!startDateString) {
    return null;
  }

  const rangeToDisplay = getYearsAndMonthsDiffString(startDate, endDate);

  const endDateString = `${endDate ?
    `${endDate.year}` :
    `${translate(STRING_KEYS.PRESENT)}`}`;

  return (
    <TimePeriodContainer font={ font }>
      {`${rangeToDisplay && `${rangeToDisplay} • `}${startDateString} - ${endDateString}`}
    </TimePeriodContainer>
  );
};
