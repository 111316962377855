import { MatchResponse } from '../../../store/rtk-query/apis/match/dto/response/match.response';
import { MatchPageContent } from './match-page-content/match-page-content.component';

type MatchBodyProps = {
  data: MatchResponse,
  isLoading: boolean,
  trackSocialLinkClick?: (socialChannel: string) => void,
  trackCompanyInsightsClick?: (
    matchExperiencePeriod: string,
    matchExperienceCompanyId: string,
    matchExperienceCompanyName: string,
  ) => void,
  trackMatchElementViewed?: (componentElementType: string) => void,
  trackSkillsComponentExpand?: (componentName: string) => void,
}

// TODO [refactor]: delete
export const MatchBody = ({
  data,
  isLoading,
  trackSocialLinkClick,
  trackCompanyInsightsClick,
  trackMatchElementViewed,
  trackSkillsComponentExpand,
}: MatchBodyProps): JSX.Element => {
  const {
    talent, talentInfo, smartWidgets = [], smartBadges,
  } = data || {};

  const totalYearsOfExperience = talent?.totalMonthsTenure && parseFloat((talent?.totalMonthsTenure / 12).toFixed(1));

  return (
    <MatchPageContent
      matchId={ data.id }
      positionId={ data.id }
      talent={ talent }
      talentInfo={ talentInfo || {} }
      isLoading={ isLoading }
      engagementStatus={ data?.engagement?.status || '' }
      educationData={ talent?.educationHistory }
      smartWidgets={ smartWidgets }
      smartBadges={ smartBadges || [] }
      experienceData={ talent?.employmentHistory }
      accountCompanyName={ data.accountCompanyName || '' }
      trackSocialLinkClick={ trackSocialLinkClick }
      trackCompanyInsightsClick={ trackCompanyInsightsClick }
      trackMatchElementViewed={ trackMatchElementViewed }
      trackSkillsComponentExpand={ trackSkillsComponentExpand }
      networkedTalents={ data?.networkedTalents }
      totalYearsOfExperience={ totalYearsOfExperience }
      matchViewed
      previousCompanyNames={ [] }
    />
  );
};

MatchBody.defaultProps = {
  trackSocialLinkClick: undefined,
  trackCompanyInsightsClick: undefined,
  trackMatchElementViewed: undefined,
  trackSkillsComponentExpand: undefined,
};
