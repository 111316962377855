import styled from 'styled-components';
import Dialog from '../base-dialog/base-dialog.component';
import { THEME } from '../../../themes';
import { Title2Font } from '../../../themes/fonts';

export const HeaderContainer = styled(Dialog.Header)`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.LARGE} ${THEME.SPACING.LARGE} ${THEME.SPACING.LARGE};
`;

export const BodyContainer = styled(Dialog.Body)`
  display: flex;
  flex-direction: row;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.LOOSE} ${THEME.SPACING.XLOOSE};
  overflow: hidden;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${THEME.COLORS.CONTENT_A};
  gap: ${THEME.SPACING.MEDIUM};
  align-items: center;
  text-align: center;
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 600;
`;

export const Description = styled.div`
  opacity: ${THEME.OPACITY.MED};
  line-height: 24px;
`;
