import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Body1Font, Title1Font } from '../../../../themes/fonts';

const MAX_SCHEDULE_MEETING_CONTAINER_WIDTH_PX = 640;

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MEDIUM};
  flex-direction: column;
  align-items: center;
  padding: ${THEME.SPACING.LOOSE} 0;
  max-width: ${MAX_SCHEDULE_MEETING_CONTAINER_WIDTH_PX}px;
`;

export const Title = styled.div`
  ${Title1Font};
  font-weight: 600;
  line-height: 1.5;
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BodyText = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  line-height: 1.5;
  text-align: center;
`;
