import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import PreferencesBodyHeaderComponent
  from '../../components/outreach/preferences-body-header/preferences-body-header.component';
import STRING_KEYS from '../../../../language/keys';
import PreferencesContentSection
  from '../../components/outreach/preferences-content-section/preferences-content-section.component';
import CampaignModeButton from '../../components/outreach/campaign-mode-button/campaign-mode-button.component';
import CampaignPageAutoModeFollowupsNumberSection
  from '../../components/outreach/campaign-auto-mode-followups-number-section/campaign-auto-mode-followups-number-section.component';
import CampaignPageAutoModeSendTimeSection
  from '../../components/outreach/campaign-auto-mode-send-time-section/campaign-auto-mode-send-time-section.component';
import CampaignPageAutoModeEmailSenderSection
  from '../../components/outreach/campaign-auto-mode-email-sender-section/campaign-auto-mode-email-sender-section.component';
import { OutreachPageBodyContent } from '../../components/outreach/outreach-layout/outreach.styled';
import { SequenceModeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-mode.enum';
import DotsLoader from '../../components/dots-loader/dots-loader.component';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import {
  useUpdatePositionSequencePreferencesMutation,
} from '../../../../store/rtk-query/apis/sequence/hooks/update-position-sequesnce-preferences.mutation-hook';
import PreferencesPageContentComponent
  from '../../components/outreach/preferences-page-content/preferences-page-content.component';
import {
  useGetPositionSequencePreferencesSelector,
} from '../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import AutomatingCampaignDialog
  from '../../../../components/dialogs/automating-campaign-dialog/automating-campaign-dialog.component';
import { setSequenceMode } from '../../../../store/slices/outreach-state/outreach-state.toolkit-slice';
import { useOutreachState } from '../../../../store/selectors/outreach.selectors';
import { useConfirmPageNavigation } from '../../hooks/leave-without-saving.hook';
import CampaignCustomMode from '../../components/outreach/campaign-custom-mode/campaign-custom-mode.component';
import { outreachQuery } from '../../../../store/rtk-query/apis/outreach/outreach.toolkit-api';
import CampaignArchiveConversation
  from '../../components/outreach/campaign-archive-conversation/campaign-archive-conversation.component';
import {
  AutoButtonIcon,
  AutoModeOptionsTitle,
  CustomButtonIcon,
  DescriptionText,
  LeftArrowIcon,
  LightningIcon,
  ManualButtonIcon,
  ModeButtonItemContainer,
  ModeOptionsContainer,
  ModesButtonsContainer,
  ModesContainer,
  OptionListContainer,
  SaveButton,
  SignatureIcon,
  StarIcon,
  Title,
  TitleIcon,
  WobbleRightAnimationContainer,
} from './campaign.styled';
import { useOutreachSequencePreferencesFormik } from './campaign.formik';
import { OutreachSequencePreferencesFormikKeys } from './campaign.enums';

const UPDATE_POSITION_SEQUENCE_PREFERENCES_MIN_LOADING_TIME_MS = 3000;

const CampaignPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const dispatch = useDispatch();
  const [refetchPositionConversations] = outreachQuery.useLazyGetConversationsByPositionIdQuery();
  const { sequenceMode: sliceSequenceMode } = useOutreachState();
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [isUpdatePositionSequencePreferencesDelay, setIsUpdatePositionSequencePreferencesDelay] = useState(false);

  const {
    data: preferences,
    isFetching: isGetPreferencesFetching,
    isLoading: isGetPreferencesLoading,
  } = useGetPositionSequencePreferencesSelector(positionId);

  const [updatePositionSequencePreferences, {
    isLoading: isUpdatePositionSequencePreferencesLoading,
    isError: isUpdatePositionSequencePreferencesError,
  }] = useUpdatePositionSequencePreferencesMutation();

  const arePreferencesDefined = useMemo(() => preferences?.contentPreferences, [preferences?.contentPreferences]);

  const {
    mode,
    primarySenderId,
    scheduleMode,
    scheduleDaysType,
    scheduleTime,
    followupsMode,
    followupsNumber,
    autoArchiveConversationsWithoutEmail,
    setValueByKey,
    save,
    dirty,
    isValid,
    resetForm,
  } = useOutreachSequencePreferencesFormik(updatePositionSequencePreferences, preferences?.sequencePreferences, sliceSequenceMode);

  useConfirmPageNavigation(translate(STRING_KEYS.OUTREACH_PAGE.PREFERENCES_CONFIRMATION_MESSAGE), dirty);

  useEffect(() => {
    return () => {
      if (sliceSequenceMode) {
        dispatch(setSequenceMode());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showAutoModeButtonAnimation = useMemo(() => mode !== SequenceModeEnum.Auto, []);

  const isAutomatingCampaignDialogOpen = useMemo(
    () => {
      if (isUpdatePositionSequencePreferencesError) {
        return false;
      }

      return isUpdatePositionSequencePreferencesDelay ||
        (isUpdatePositionSequencePreferencesLoading && mode === SequenceModeEnum.Auto && preferences.sequencePreferences?.mode !== SequenceModeEnum.Auto);
    },
    [
      isUpdatePositionSequencePreferencesLoading,
      isUpdatePositionSequencePreferencesError,
      isUpdatePositionSequencePreferencesDelay,
      mode,
      preferences.sequencePreferences?.mode,
    ]);

  useEffect(() => {
    if (isUpdatePositionSequencePreferencesError) {
      setIsErrorSnackbarOpen(true);
    }
  }, [isUpdatePositionSequencePreferencesError]);

  const onSaveButtonClick = async () => {
    if (mode === SequenceModeEnum.Auto && preferences.sequencePreferences?.mode !== SequenceModeEnum.Auto) {
      setIsUpdatePositionSequencePreferencesDelay(true);
      setTimeout(() => setIsUpdatePositionSequencePreferencesDelay(false), UPDATE_POSITION_SEQUENCE_PREFERENCES_MIN_LOADING_TIME_MS);
    }
    await save();
    refetchPositionConversations({ positionId });
  };

  const onChangeArchiveConversation = (archive: boolean) => {
    setValueByKey(OutreachSequencePreferencesFormikKeys.AutoArchiveConversationsWithoutEmail, archive);
  };

  const renderAutoModeOptions = () => {
    if (mode === SequenceModeEnum.Manual) {
      return (
        <PreferencesContentSection>
          <ModeOptionsContainer>
            <CampaignArchiveConversation
              active={ autoArchiveConversationsWithoutEmail }
              changeHandler={ onChangeArchiveConversation }
            />
          </ModeOptionsContainer>
        </PreferencesContentSection>
      );
    }

    if (mode === SequenceModeEnum.Custom) {
      return (
        <PreferencesContentSection>
          <ModeOptionsContainer>
            <OptionListContainer>
              <CampaignPageAutoModeEmailSenderSection
                selectedUserId={ primarySenderId }
                changeHandler={ (selectedUserId: string) => setValueByKey(OutreachSequencePreferencesFormikKeys.PrimarySenderId, selectedUserId) }
              />
              <CampaignCustomMode
                onCloseDialogHandler={ resetForm }
                customSteps={ preferences?.sequencePreferences?.customSteps }
              />
              <CampaignArchiveConversation
                active={ autoArchiveConversationsWithoutEmail }
                changeHandler={ onChangeArchiveConversation }
              />
            </OptionListContainer>
          </ModeOptionsContainer>
        </PreferencesContentSection>
      );
    }

    return (
      <PreferencesContentSection>
        <ModeOptionsContainer>
          <AutoModeOptionsTitle>{ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.TITLE) }</AutoModeOptionsTitle>
          <OptionListContainer>
            <CampaignPageAutoModeEmailSenderSection
              selectedUserId={ primarySenderId }
              changeHandler={ (selectedUserId: string) => setValueByKey(OutreachSequencePreferencesFormikKeys.PrimarySenderId, selectedUserId) }
            />
            <CampaignPageAutoModeSendTimeSection
              mode={ scheduleMode }
              daysType={ scheduleDaysType }
              sendTime={ scheduleTime }
              changeHandler={ setValueByKey }
            />
            <CampaignPageAutoModeFollowupsNumberSection
              mode={ followupsMode }
              followupsNumber={ followupsNumber }
              changeHandler={ setValueByKey }
            />
            <CampaignArchiveConversation
              active={ autoArchiveConversationsWithoutEmail }
              changeHandler={ onChangeArchiveConversation }
            />
          </OptionListContainer>
        </ModeOptionsContainer>
      </PreferencesContentSection>
    );
  };

  const renderBody = () => {
    return (
      <>
        <OutreachPageBodyContent disabled={ !arePreferencesDefined || isUpdatePositionSequencePreferencesLoading }>
          <PreferencesContentSection>
            <ModesContainer>
              <Title>{ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.TITLE) }</Title>
              <DescriptionText>
                { translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.DESCRIPTION) }
              </DescriptionText>
              <ModesButtonsContainer>
                <ModeButtonItemContainer>
                  <WobbleRightAnimationContainer showAnimation={ showAutoModeButtonAnimation }>
                    <CampaignModeButton
                      title={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.AUTO.TITLE) }
                      subtitle={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.AUTO.SUBTITLE) }
                      Icon={ AutoButtonIcon }
                      badgeText={ translate(STRING_KEYS.RECOMMENDED) }
                      BadgeIcon={ StarIcon }
                      isSelected={ mode === SequenceModeEnum.Auto }
                      isActive={ preferences.sequencePreferences?.mode === SequenceModeEnum.Auto }
                      InfoTextIcon={ LightningIcon }
                      infoText={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.AUTO.INFO_TEXT) }
                      clickHandler={ () => setValueByKey(OutreachSequencePreferencesFormikKeys.Mode, SequenceModeEnum.Auto) }
                    />
                  </WobbleRightAnimationContainer>
                </ModeButtonItemContainer>
                <ModeButtonItemContainer>
                  <CampaignModeButton
                    title={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.TITLE) }
                    subtitle={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.SUBTITLE) }
                    Icon={ CustomButtonIcon }
                    isSelected={ mode === SequenceModeEnum.Custom }
                    isActive={ preferences.sequencePreferences?.mode === SequenceModeEnum.Custom }
                    InfoTextIcon={ SignatureIcon }
                    infoText={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.INFO_TEXT) }
                    clickHandler={ () => setValueByKey(OutreachSequencePreferencesFormikKeys.Mode, SequenceModeEnum.Custom) }
                  />
                </ModeButtonItemContainer>
                <ModeButtonItemContainer>
                  <CampaignModeButton
                    title={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.MANUAL.TITLE) }
                    subtitle={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.MANUAL.SUBTITLE) }
                    Icon={ ManualButtonIcon }
                    isSelected={ mode === SequenceModeEnum.Manual }
                    isActive={ preferences.sequencePreferences?.mode === SequenceModeEnum.Manual }
                    clickHandler={ () => setValueByKey(OutreachSequencePreferencesFormikKeys.Mode, SequenceModeEnum.Manual) }
                    infoText={ translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.MANUAL.INFO_TEXT) }
                    InfoTextIcon={ LeftArrowIcon }
                  />
                </ModeButtonItemContainer>
              </ModesButtonsContainer>
            </ModesContainer>
          </PreferencesContentSection>
          { renderAutoModeOptions() }
        </OutreachPageBodyContent>
      </>
    );
  };

  if (isGetPreferencesFetching || isGetPreferencesLoading) {
    return <DotsLoader />;
  }

  return (
    <>
      <PreferencesPageContentComponent>
        <PreferencesBodyHeaderComponent
          title={ translate(STRING_KEYS.CAMPAIGN_PAGE.TITLE) }
          Icon={ TitleIcon }
          ActionButtons={ (
            <SaveButton
              onClick={ onSaveButtonClick }
              size={ PerfectButtonSize.Large }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              disabled={ !dirty || !isValid || !arePreferencesDefined }
              isLoading={ isUpdatePositionSequencePreferencesLoading }
            >
              { translate(STRING_KEYS.SAVE) }
            </SaveButton>
          ) }
        />
        { renderBody() }
        <AlertSnackBar
          title={ translate(STRING_KEYS.ALERT) }
          description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
          isOpen={ isErrorSnackbarOpen }
          shouldAutoDismiss
          onClose={ () => setIsErrorSnackbarOpen(false) }
        />
      </PreferencesPageContentComponent>
      { isAutomatingCampaignDialogOpen && <AutomatingCampaignDialog isOpen /> }
    </>
  );
};

export default CampaignPage;
