import { Trans, useTranslation } from 'react-i18next';
import React, { useMemo, useRef } from 'react';
import { ClickAwayListener, Popper } from '@mui/material';
import { InsightList, InsightListItem } from '../base-widget-card/insight-list/insight-list.component';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import CompanyInsightsModal from '../../Common/CompanyInsightsModal';
import STRING_KEYS from '../../../language/keys';
import { ASSETS } from '../../../assets';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { Bold, AccentBold } from './employer-by-industry-widget.styled';

export interface EmployerByIndustryWidgetProps {
  companyName: string;
  industryName: string;
  companies: InsightListItem[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const EmployerByIndustryWidget = ({
  companyName,
  industryName,
  companies,
  onIntersectionHandler,
}:EmployerByIndustryWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [companyModalAnchorEl, setCompanyModalAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = React.useState<null | InsightListItem>(null);
  const isCompanyModalOpen = Boolean(companyModalAnchorEl);
  const componentRef = useRef(null);
  useInsightWidgetIntersection({
    componentRef,
    onIntersectionHandler,
    metadata: companies?.map((company) => company.text).join(','),
    metadataType: 'top companies array',
  });

  const memoizedCompanyData = useMemo(() => (selectedItem ? {
    companyId: selectedItem?.id,
    companyName: selectedItem?.text,
    companyLogo: selectedItem?.icon,
    industries: selectedItem?.industries || [],
    companyEnrichedData: selectedItem?.companyEnrichedData || {},
  } : null), [selectedItem]);

  const onCompanyItemClick = (item: InsightListItem, currentTarget: HTMLElement) => {
    setSelectedItem(item);
    setCompanyModalAnchorEl(currentTarget);
  };

  const closeCompanyModal = () => {
    setSelectedItem(null);
    setCompanyModalAnchorEl(null);
  };

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.EMPLOYER_BY_INDUSTRY_CARD_TEXT }>
        <AccentBold />
        <Bold />
        {{ companyName }}
        {{ industryName }}
      </Trans>
    );
  };
  const renderCardBody = () => {
    return (
      <div ref={ componentRef }>
        { isCompanyModalOpen && memoizedCompanyData && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={ closeCompanyModal }
          >
            <Popper
              placement="bottom-start"
              open={ isCompanyModalOpen }
              anchorEl={ companyModalAnchorEl }
              modifiers={ [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ] }
            >
              <CompanyInsightsModal
                companyData={ memoizedCompanyData }
              />
            </Popper>
          </ClickAwayListener>
        )}
        <InsightList
          items={ companies }
          onLearnMoreClick={ onCompanyItemClick }
          iconFallback={ ASSETS.EXPERIENCE_FALLBACK }
        />
      </div>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="🏢"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.COMPANY_INSIGHTS_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

EmployerByIndustryWidget.defaultProps = {
  onLearnMoreClick: undefined,
};
