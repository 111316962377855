import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { TalentNetworkExperienceDto } from '../../../../modules/application/components/talent-network/mutual-experience-sentence/mutual-experience-sentence';
import { ExperienceItem } from '../../../../@types/match';
import ExperienceItemComponent from './experience-information-item/experience-item.component';
import ExperienceSkeleton from './experience-information-skeleton/experience-information-skeleton.component';
import { Header, ExperienceContainer } from './experience-information.styled';

type ExperienceProps = {
  data: Array<ExperienceItem>,
  isLoading: boolean,
  trackCompanyInsightsClick?: (
    matchExperiencePeriod: string,
    matchExperienceCompanyId: string,
    matchExperienceCompanyName: string,
  ) => void,
  trackMatchElementViewed?: (eventName: string, componentElementType: string, componentElement?: string) => void,
  companyExperienceMap?: Map<string, Map<number, TalentNetworkExperienceDto[]>>,
}

// TODO: delete
export const Experience = ({
  data,
  isLoading,
  trackCompanyInsightsClick,
  trackMatchElementViewed,
  companyExperienceMap,
}:ExperienceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  if (isLoading) {
    return (
      <ExperienceSkeleton />
    );
  }

  return (
    <ExperienceContainer>
      <Header>
        {translate(STRING_KEYS.EXPERIENCE)}
      </Header>
      {
        data?.map((employmentHistoryItem, index: number) => {
          const {
            companyId,
            companyName,
            companyEnrichedData,
            companyLogo,
            companyDescription,
            industries,
            isCompanyEnriched,
            keyInsights,
            data: itemData,
            talentSkills,
          } = employmentHistoryItem;
          const companyData = {
            companyId, industries, companyName, companyLogo, companyDescription, companyEnrichedData,
          };
          const totalRoles = itemData?.length || 0;

          // key cannot be only companyId,
          // because it can be null and it is not unique!
          // person can work at the same company multiple times.
          let key = `${companyId}-${companyName}`;
          if (totalRoles > 0) {
            key += `-${itemData[0].startDateObject?.year}-
              ${itemData[totalRoles - 1].endDateObject?.year}`;
          }

          let talentNetworkExperienceMap;
          if (companyExperienceMap && companyId) {
            talentNetworkExperienceMap = companyExperienceMap.get(companyId);
          }

          return (
            <ExperienceItemComponent
              key={ key }
              keyInsights={ keyInsights || '' }
              showCompanyInsights={ isCompanyEnriched }
              companyData={ companyData }
              data={ itemData }
              isLastExperienceChapter={ index === data.length - 1 }
              trackCompanyInsightsClick={ trackCompanyInsightsClick }
              trackMatchElementViewed={ trackMatchElementViewed }
              talentNetworkExperienceMap={ talentNetworkExperienceMap }
              talentSkills={ talentSkills }
            />
          );
        })
      }
    </ExperienceContainer>
  );
};

Experience.defaultProps = {
  trackCompanyInsightsClick: undefined,
  trackMatchElementViewed: undefined,
  companyExperienceMap: undefined,
};
