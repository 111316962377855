export enum OutreachSequencePreferencesFormikKeys {
  Mode = 'mode',
  PrimarySenderId = 'primarySenderId',
  ScheduleMode = 'scheduleMode',
  ScheduleDaysType = 'scheduleDaysType',
  ScheduleTime = 'scheduleTime',
  FollowupsMode = 'followupsMode',
  FollowupsNumber = 'followupsNumber',
  CustomSteps = 'steps',
  AutoArchiveConversationsWithoutEmail = 'autoArchiveConversationsWithoutEmail',
}
