/* eslint-disable react/jsx-props-no-spreading */
import {
  ChangeEvent, forwardRef, MouseEvent, Ref, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { usePageFilters } from '../../../../../hooks/use-page-filters.hooks';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import { SearchProps } from './seacrh';
import {
  CloseChipIcon, InputAnimatedWrapper,
  SearchContainer,
  SearchIcon,
  SearchInput,
  SearchTitleContainer,
  TitleContainer,
} from './seacrh.styled';

const Search = forwardRef((
  props: SearchProps,
  ref: Ref<HTMLDivElement>,
) => {
  const [searchText, setSearchText] = useState<string | null>(null);
  const [isInputShown, setIsInputShown] = useState(false);
  const [showInputCloseButton, setShowInputCloseButton] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t: translate } = useTranslation();
  const {
    setFilterByKey,
    deleteFiltersByKey,
  } = usePageFilters();

  useEffect(() => {
    if (searchText !== null) {
      if (!searchText) {
        deleteFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SEARCH);
      } else {
        setFilterByKey(AppRouting.URL_PARAMS.OUTREACH.SEARCH, searchText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (isInputShown) {
      setTimeout(() => {
        setShowInputCloseButton(isInputShown);
      }, 100);
    } else {
      setShowInputCloseButton(isInputShown);
    }
  }, [isInputShown, showInputCloseButton]);

  const handleCloseButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setSearchText('');
    setIsInputShown(false);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = () => {
    setIsInputShown(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <SearchContainer
      { ...props }
      withGap={ !isInputShown }
      ref={ ref }
    >
      <SearchTitleContainer show={ !isInputShown } >
        <TitleContainer>
          {translate(STRING_KEYS.OUTREACH_PAGE.SEARCH.TITLE)}
        </TitleContainer>
      </SearchTitleContainer >
      <InputAnimatedWrapper
        show={ isInputShown }
        onClick={ onSearchClick }
      >
        <SearchInput
          ref={ inputRef }
          show={ isInputShown }
          type="text"
          value={ searchText || '' }
          onChange={ handleSearchInputChange }
          placeholder={ translate(STRING_KEYS.OUTREACH_PAGE.SEARCH.TITLE) }
          StartAdornment={ <SearchIcon /> }
          EndAdornment={ showInputCloseButton ? <CloseChipIcon /> : undefined }
          endAdornmentClickHandler={ handleCloseButtonClick }
          fullWidth
        />
      </InputAnimatedWrapper>
    </SearchContainer>
  );
});

Search.displayName = 'Search';

export default Search;
