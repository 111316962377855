import React from 'react';
import { InitialsAvatar } from '../InitialsAvatar';
import { WithSkeletonLoader } from '../../HOC/WithSkeletonLoader';
import { PRIVACY_CLASS } from '../../../consts/fullstory';
import { getInitials } from '../../../utils';
import { Container } from './styled-avatar.styled';

const StyledAvatar = ({
  image,
  name,
  outlineWidthPx = 1,
  outlineColor,
  borderWidthPx = 8,
  borderRadiusPx,
  initialsFontSizePx,
}: StyledAvatarProps): JSX.Element => {
  return (
    <>
      {
        WithSkeletonLoader(
          false,
          <Container
            $outlineWidth={ outlineWidthPx }
            $outlineColor={ outlineColor }
            $borderWidth={ borderWidthPx }
            $borderRadiusPx={ borderRadiusPx }
            className={ PRIVACY_CLASS.EXCLUDE }
            alt={ name }
            src={ image }
            sx={ { width: '100%', height: '100%' } }
            title={ name }
          >
            <InitialsAvatar
              alt={ name }
              message={ getInitials(name) }
              fontSize={ `${initialsFontSizePx}px` }
            />
          </Container>,
          null,
          'circle',
        )
      }
    </>
  );
};

export default StyledAvatar;
