import { useTranslation } from 'react-i18next';
import { PerfectButtonHoverVariants, PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import { SectionItemTitle } from '../message-content.styled';
import {
  AutoFillButton, ButtonIcon,
  Container,
  Description,
  Message,
  MessageContainer,
  TitleContainer,
} from './message-item.styled';
import { MessageItemProps } from './message-item';

const MessageItem = ({
  message, messageChangeHandler, title, description, autoFillClickHandler, isAutoFillButtonLoading, showAutoFillButton,
}: MessageItemProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <TitleContainer>
        <SectionItemTitle>{ title }</SectionItemTitle>
        <Description>{ description }</Description>
      </TitleContainer>
      <MessageContainer>
        <Message
          placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.PLACEHOLDER) }
          value={ message }
          onChange={ messageChangeHandler }
        />
        {showAutoFillButton && (
          <AutoFillButton
            isLoading={ isAutoFillButtonLoading }
            variant={ PerfectButtonVariants.Ghost }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            StartIcon={ ButtonIcon }
            onClick={ () => autoFillClickHandler?.() }
          >
            { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.AUTO_FILL_BUTTON) }
          </AutoFillButton>
        )}
      </MessageContainer>
    </Container>
  );
};
export default MessageItem;
