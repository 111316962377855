import { Trans, useTranslation } from 'react-i18next';
import { Pie } from '@nivo/pie';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import {
  AccentBold,
  Bold,
  ChartWrapper,
  ValueItem,
  ValueItemLabel,
  ValueItemWrapper,
} from './education-degree-widget.styled';

const chartColors: string[] = [
  THEME.COLORS.ACCENT,
  THEME.COLORS.PRIMARY,
  THEME.COLORS_RGB.PRIMARY_DARK_LOW,
  THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER,
];

export interface EducationDegreeData {
  text: string;
  value: number;
}

export interface EducationDegreeWidgetProps {
  jobTitle: string;
  data: EducationDegreeData[],
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const EducationDegreeWidget = ({
  jobTitle,
  data,
  onIntersectionHandler,
}:EducationDegreeWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);

  data.sort((item1, item2) => item2.value - item1.value);
  const mostCommonEducationDegree = data[0];

  useInsightWidgetIntersection({
    componentRef, onIntersectionHandler, metadata: mostCommonEducationDegree?.text, metadataType: 'most common level',
  });

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.EDUCATION_DEGREE_CARD_TEXT }>
        <AccentBold />
        <Bold />
        {{ jobTitle }}
        {{ degreeName: mostCommonEducationDegree?.text }}
      </Trans>
    );
  };

  const createChartData = () => {
    return data.map((item: EducationDegreeData, index: number) => {
      return {
        id: item.text?.toLowerCase(),
        label: item.text,
        value: item.value,
        color: chartColors[index],
      };
    });
  };

  const chartData = createChartData();

  const renderChart = () => {
    return (
      <ChartWrapper>
        <Pie
          width={ 128 }
          height={ 128 }
          data={ chartData }
          isInteractive={ false }
          colors={ { datum: 'data.color' } }
          enableArcLabels={ false }
          enableArcLinkLabels={ false }
          animate
          activeOuterRadiusOffset={ 8 }
          innerRadius={ 0.6 }
          padAngle={ 2 }
        />
      </ChartWrapper>
    );
  };

  const renderValueList = () => {
    return (
      <div>
        { chartData.map((item) => (
          <ValueItem key={ item.label } color={ item.color }>
            <ValueItemWrapper>
              <ValueItemLabel>{ item.label }</ValueItemLabel>
              <div>{ item.value }%</div>
            </ValueItemWrapper>
          </ValueItem>
        ))}
      </div>
    );
  };

  const renderCardBody = () => {
    return (
      <div ref={ componentRef }>
        { renderChart() }
        { renderValueList() }
      </div>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="🎓"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.EDUCATION_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};
