import styled from 'styled-components';
import { THEME } from '../../../themes';

export const CardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CardTitle = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: #010408;
  opacity: 0.6;
  letter-spacing: 1.5px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const AccentBold = styled.span`
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
`;
