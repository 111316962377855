import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Bold = styled.span`
  font-weight: bold;
`;

export const AccentBold = styled.span`
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
`;
