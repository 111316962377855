import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import {
  ActionContainer,
  AdjustmentItemsContainer,
  AdjustmentItemsContainerHint,
  AdjustmentItemsContainerTopBar,
  AdjustmentItemsList,
  ConfirmButton,
  SelectAllButton,
  SelectAllButtonIcon,
} from '../position-adjustments.styled';
import STRING_KEYS from '../../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import AdjustmentItem from '../adjustment-item/adjustment-item.component';
import { RecommendedAdjustmentsProps } from './recommended-adjustments';
import { createAdjustmentList, createUpdatePositionRequestFromSelections } from './recommended-adjustments.utils';
import { RecommendedAdjustmentsKeys } from './recommended-adjustments.enums';

const RecommendedAdjustments = ({
  position, adjustments, confirmHandler, isEditPositionLoading,
}: RecommendedAdjustmentsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [selectedRecommendations, setSelectedRecommendations] = useState(new Set<RecommendedAdjustmentsKeys>());
  const adjustmentList = useMemo(() => createAdjustmentList(position, adjustments), [position, adjustments]);

  const onSelectAllButtonClick = () => {
    setSelectedRecommendations(new Set(adjustmentList.map((adjustment) => adjustment.key)));
  };

  const onAdjustmentItemActionButtonClick = (key: RecommendedAdjustmentsKeys) => {
    const newSet = new Set(selectedRecommendations);

    if (selectedRecommendations.has(key)) {
      newSet.delete(key);
    } else {
      newSet.add(key);
    }

    setSelectedRecommendations(newSet);
  };

  const onConfirmButtonClick = () => {
    confirmHandler(
      createUpdatePositionRequestFromSelections(
        position.id,
        Array.from(selectedRecommendations),
        adjustments,
      ),
    );
  };

  return (
    <div>
      <AdjustmentItemsContainer>
        <AdjustmentItemsContainerTopBar>
          <AdjustmentItemsContainerHint>{ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.ITEMS_CONTAINER_HINT) }</AdjustmentItemsContainerHint>
          <SelectAllButton
            onClick={ onSelectAllButtonClick }
            StartIcon={ SelectAllButtonIcon }
            size={ PerfectButtonSize.Medium }
            variant={ PerfectButtonVariants.Ghost }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            disabled={ selectedRecommendations.size === adjustmentList.length }
          >
            { translate(STRING_KEYS.SELECT_ALL) }
          </SelectAllButton>
        </AdjustmentItemsContainerTopBar>
        <AdjustmentItemsList>
          { adjustmentList.map((adjustment) => (
            <AdjustmentItem
              key={ adjustment.key }
              icon={ adjustment.icon }
              title={ adjustment.title }
              description={ adjustment.description }
              isSelected={ selectedRecommendations.has(adjustment.key) }
              actionButtonClickHandler={ () => onAdjustmentItemActionButtonClick(adjustment.key) }
            />
          )) }
        </AdjustmentItemsList>
      </AdjustmentItemsContainer>
      <ActionContainer>
        <ConfirmButton
          onClick={ onConfirmButtonClick }
          size={ PerfectButtonSize.Medium }
          variant={ PerfectButtonVariants.Outline }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          disabled={ !selectedRecommendations.size }
          isLoading={ isEditPositionLoading }
        >
          { translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.CONFIRM_BUTTON_TEXT) }
        </ConfirmButton>
      </ActionContainer>
    </div>
  );
};

export default RecommendedAdjustments;
