import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import {
  Container,
  HeaderContainer,
  StyledDialog,
  SubTitle,
  Title,
} from './guidance-modal.styled';
import { GuidanceModalProps } from './guidance-modal';
import GuidanceCard from './guidance-card/guidance-card.component';

const GuidanceModal = ({
  isOpen,
  closeHandler,
  zIndex,
  title,
  data,
}: GuidanceModalProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <StyledDialog
      isOpen={ isOpen }
      onClose={ closeHandler }
      widthSize="800px"
      zIndex={ zIndex }
    >
      <HeaderContainer>
        <SubTitle>{ translate(STRING_KEYS.GUIDANCE_MODAL.TITLE) }</SubTitle>
        <Title>{ title }</Title>
      </HeaderContainer>
      <Container>
        { data.map((cardProps) => (
          <GuidanceCard
            key={ cardProps.title }
            type={ cardProps.type }
            quote={ cardProps.quote }
            title={ cardProps.title }
            text={ cardProps.text }
          />
        )) }
      </Container>
    </StyledDialog>
  );
};

export default GuidanceModal;
