import {
  RoleDecoratorWrapper,
  RoleDecoratorOutside,
  RoleDecoratorInside,
} from './experience-item-role-decorator.styled';

type RoleDecoratorProps = {
    isPromoted?: boolean,
}

export const RoleDecorator = ({ isPromoted = false }: RoleDecoratorProps): JSX.Element => {
  return (
    <RoleDecoratorWrapper isPromoted={ isPromoted }>
      <RoleDecoratorOutside>
        <RoleDecoratorInside />
      </RoleDecoratorOutside>
    </RoleDecoratorWrapper>
  );
};

RoleDecorator.defaultProps = {
  isPromoted: false,
};
