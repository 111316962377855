import styled from 'styled-components';
import { THEME } from '../../../themes';
import { TextEllipsis } from '../../../themes/text';

export const Bold = styled.span`
  font-weight: bold;
`;

export const RolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
`;

export const RolesContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
`;

export const LeftRolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
`;

export const RightRolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${THEME.SPACING.LOOSE};
  overflow: hidden;
`;

export const RightRolesContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
`;

export const BodyContainer = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const CurrentRoleContainer = styled.span`
  color: ${THEME.COLORS.ACCENT};
  background-color: ${THEME.COLORS_RGB.ACCENT_GHOST};
  border-radius: ${THEME.RADIUS.BASE};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  align-self: center;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const NodesGroupLine = styled.div<{ invertSide?: boolean }>`
  ${({ invertSide }) => `${invertSide ? 'border-right' : 'border-left'}: 1px solid ${THEME.COLORS_RGB.PRIMARY_DIVIDER}`};
  width: 30px;
  position: relative;
  align-self: center;
  top: 0.6px;
  height: calc(100% - 31.3px);

  ${({ invertSide }) => {
    const side = invertSide ? '&:before' : '&:after';

    return `
    ${side} {
      content:'';
      width: 290px;
      border-bottom:1px solid;
      position:absolute;
      top: calc(50% - 0.4px);
      color: ${THEME.COLORS_RGB.PRIMARY_DIVIDER}
    }`;
  }}

  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }
`;

export const RoleNode = styled.span<{ invertSide?: boolean }>`
  color: ${THEME.COLORS.PRIMARY};
  background-color: ${THEME.COLORS_RGB.PRIMARY_GHOST};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.BASE};
  width: fit-content;
  position: relative;
  font-size: 14px;
  font-weight: 500;

  &:not(:first-child) {
    margin-top: ${THEME.SPACING.TINY};
  }
  
  ${({ invertSide }) => {
    const side = invertSide ? '&:before' : '&:after';
    
    return `
    ${side} {
      content:'';
      width: 290px;
      border-bottom:1px solid;
      position:absolute;
      top:50%;
      color: ${THEME.COLORS_RGB.PRIMARY_DIVIDER}
    }`;
  }}

  &:after {
    left:100%;
  }

  &:before {
    right:100%;
  }
`;

export const RoleNodeTextWrapper = styled.div<{ invertSide?: boolean }>`
  ${TextEllipsis};
  max-width: 200px;
`;

export const CurrentRoleNodeTextWrapper = styled.div<{ invertSide?: boolean }>`
  ${TextEllipsis};
  max-width: 142px;
`;
