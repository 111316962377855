import STRING_KEYS from '../../language/keys';
import { ASSETS } from '../../assets';
import { MATCH_STATUSES } from '../../consts';
import { MatchFilter } from '../../@types/match';
import { MatchFilterId } from './match.page.enum';

export const BADGE_TYPE = {
  CAREER_PROGRESSION: 'CAREER_PROGRESSION',
  ACADEMIC_EXCELLENCE: 'ACADEMIC_EXCELLENCE',
  ACTIVELY_LOOKING: 'ACTIVELY_LOOKING',
  CRUNCHBASE_RANKING: 'CRUNCHBASE_RANKING',
  TOTAL_YEARS_OF_EXPERIENCE: 'TOTAL_YEARS_OF_EXPERIENCE',
  LANGUAGES: 'LANGUAGES',
  PAST_EXPERIENCE_COMPANY_LIST: 'PAST_EXPERIENCE_COMPANY_LIST',
  UPCOMING_TENURE_PERIOD: 'UPCOMING_TENURE_PERIOD',
  COMPANY_TREND: 'COMPANY_TREND',
  REACHABILITY: 'REACHABILITY',
};

export const WIDGET_TYPE = {
  TOTAL_YEARS_OF_EXPERIENCE: 'TOTAL_YEARS_OF_EXPERIENCE',
  TALENT_SKILLS: 'TALENT_SKILLS',
  LANGUAGES: 'LANGUAGES',
  SOCIAL_LINKS: 'SOCIAL_LINKS',
  EXPERIENCE_BY_INDUSTRY: 'EXPERIENCE_BY_INDUSTRY',
  WORKED_WITH: 'WORKED_WITH',
  PAST_EXPERIENCE_COMPANY_LIST: 'PAST_EXPERIENCE_COMPANY_LIST',
  LIKELIHOOD_TO_MOVE_METER: 'LIKELIHOOD_TO_MOVE_METER',
  PERSONAL_TENURE_COMPARISON: 'PERSONAL_TENURE_COMPARISON',
  WTM_REDUCTION_ALERT: 'WTM_REDUCTION_ALERT',
  WTM_COMPANY_LAYOFFS: 'WTM_COMPANY_LAYOFFS',
  WTM_COMPANY_LAYOFF_TALENT: 'WTM_COMPANY_LAYOFF_TALENT',
  TALENT_NETWORK: 'TALENT_NETWORK',
};

// List of all available matches filters for match page
export const DEFAULT_MATCH_FILTERS: Array<MatchFilter> = [
  {
    id: MatchFilterId.ALL_MATCHES,
    titleKey: STRING_KEYS.ALL_MATCHES,
    engagementStatus: null,
    icon: ASSETS.ALL_MATCHES_FILTER_ICON,
    analyticsTitle: 'all_matches',
  },
  {
    id: MatchFilterId.TO_REVIEW,
    titleKey: STRING_KEYS.TO_REVIEW,
    engagementStatus: MATCH_STATUSES.TO_REVIEW,
    icon: ASSETS.STARS_FILLED,
    analyticsTitle: 'to_review',
  },
  {
    id: MatchFilterId.ENGAGED,
    titleKey: STRING_KEYS.ENGAGED,
    engagementStatus: MATCH_STATUSES.ENGAGED,
    icon: ASSETS.HEART_OUTLINE,
    analyticsTitle: 'engaged',
  },
  {
    id: MatchFilterId.DECLINED,
    titleKey: STRING_KEYS.DECLINED,
    engagementStatus: MATCH_STATUSES.DECLINED,
    icon: ASSETS.REJECT,
    analyticsTitle: 'declined',
  },
];
