import { ButtonContainer, IconWrapper, TextWrapper } from './base-button.styled';
import { BaseButtonProps } from './base-button';

export const BaseButton = ({
  buttonText,
  Icon,
  onClick,
  disabled,
  style,
  buttonRef,
  type,
}: BaseButtonProps): JSX.Element => {
  return (
    <ButtonContainer
      ref={ buttonRef }
      disabled={ disabled }
      border={ style?.border }
      onClick={ onClick }
      textColor={ style?.textColor }
      hoveredBackgroundColor={ style?.hoveredBackgroundColor }
      backgroundColor={ style?.backgroundColor }
      activeBackgroundColor={ style?.activeBackgroundColor }
      activeTextColor={ style?.activeTextColor }
      hoveredTextColor={ style?.hoveredTextColor }
      uppercase={ style?.uppercase }
      type={ type }
    >
      {
        Icon && (
          <IconWrapper
            fill={ style?.textColor }
            hoverFill={ style?.hoveredTextColor }
          >
            <Icon />
          </IconWrapper>
        )
      }
      <TextWrapper>
        { buttonText }
      </TextWrapper>
    </ButtonContainer>
  );
};

BaseButton.defaultProps = {
  buttonRef: undefined,
  Icon: undefined,
  border: undefined,
  uppercase: false,
  disabled: false,
  style: {},
  type: 'button',
};
