import { RefObject } from 'react';
import { apiObject } from 'rudder-sdk-js';
import { useIntersection } from './useIntersection';

export type onInsightWidgetIntersectionHandler = ((args: OnInsightWidgetIntersectionHandlerArguments) => void) | undefined;

export type OnInsightWidgetIntersectionHandlerArguments = {
  metadata?: string,
  metadataStruct?: apiObject,
  metadataType?: string,
}

type UseInsightWidgetIntersectionArguments = {
  componentRef: RefObject<HTMLElement>,
  onIntersectionHandler: onInsightWidgetIntersectionHandler,
  metadata?: string,
  metadataStruct?: apiObject,
  metadataType?: string,
}

// TODO: remove this hook and use 'useIntersection' instead
export const useInsightWidgetIntersection = ({
  componentRef,
  onIntersectionHandler,
  metadata,
  metadataStruct,
  metadataType,
}: UseInsightWidgetIntersectionArguments): void => {
  useIntersection({
    element: componentRef,
    callback: () => onIntersectionHandler?.({ metadata, metadataStruct, metadataType }),
  });
};
