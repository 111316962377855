import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import BackButton from '../back-button/back-button.component';
import STRING_KEYS from '../../../../language/keys';
import { SkipButton } from '../../../../components/Common/Buttons/skip-button/skip-button.styled';
import animationData from '../../../../assets/lottie/verification_loader.json';
import { PerfectButtonSize } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ButtonWrapper, ContinueButton } from './actions-footer.styled';

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type ActionsFooterComponentProps = {
  handleBackButton: () => void;
  handleOnContinueClick: () => void;
  handleOnSkipClick: () => void;
  showButtonLoader: boolean,
  showContinueButton: boolean,
  isContinueButtonEnabled: boolean,
  isContinueButtonAnimation: boolean,
  showSkipButton: boolean,
  isSkipButtonDisabled: boolean,
}

const ActionsFooterComponent = ({
  handleBackButton,
  handleOnSkipClick,
  handleOnContinueClick,
  showButtonLoader,
  showContinueButton,
  isContinueButtonEnabled,
  isContinueButtonAnimation,
  showSkipButton,
  isSkipButtonDisabled,
}: ActionsFooterComponentProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <>
      <BackButton onClick={ handleBackButton } />
      {showButtonLoader && (
        <ButtonWrapper enabled>
          <Lottie
            options={ loaderOptions }
            width={ 100 }
          />
        </ButtonWrapper>
      )}
      {showContinueButton && (
        <ButtonWrapper enabled={ isContinueButtonAnimation }>
          <ContinueButton
            size={ PerfectButtonSize.ExtraLarge }
            onClick={ handleOnContinueClick }
            disabled={ !isContinueButtonEnabled }
          >
            { translate(STRING_KEYS.ONBOARDING.ONBOARDING_CONTINUE_BUTTON) }
          </ContinueButton>
        </ButtonWrapper>
      )}
      {showSkipButton && (
        <SkipButton
          isDisabled={ isSkipButtonDisabled }
          onClick={ handleOnSkipClick }
        >
          { translate(STRING_KEYS.SKIP) }
        </SkipButton>
      )}
    </>
  );
};

export default ActionsFooterComponent;
