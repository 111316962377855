import styled, { css } from 'styled-components';
import { THEME } from '../../../themes';
import {
  Body2Font, CaptionFont, Subtitle1Font, Subtitle2Font,
} from '../../../themes/fonts';
import { temporaryElevation } from '../../../themes/elevations';
import { PerfectMenuSize } from './perfect-base-menu.enums';

const LargeCss = css`
  li {
    ${Subtitle1Font};
    min-width: 240px;
    height: 48px;
  }
`;

const MediumCss = css`
  li {
    ${Subtitle2Font};
    min-width: 240px;
    height: 40px;
  }
`;

const SmallCss = css`
  li {
    ${CaptionFont};
    min-width: 124px;
    height: 32px;
  }
`;

const DenseCss = css`
  li {
    ${Body2Font};
    height: 32px;
  }
`;

export const MenuContainer = styled.div`
  ${temporaryElevation};
  position: absolute;
  z-index: 302;
  outline: none;
  border-radius: ${THEME.RADIUS.ROUND};
`;

export const TitleContainer = styled.div`
  padding: 0 ${THEME.SPACING.BASE};
`;

export const Menu = styled.ul<{ size: PerfectMenuSize, isDense: boolean }>`
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  color: ${THEME.COLORS.CONTENT_A};
  fill: currentColor;
  padding: ${THEME.SPACING.BASE} 0;
  margin: 0;
  ${({ isDense, size }) => {
    if (isDense) {
      return DenseCss;
    }
    if (size === PerfectMenuSize.Large) {
      return LargeCss;
    }
    if (size === PerfectMenuSize.Medium) {
      return MediumCss;
    }
    
    return SmallCss;
  }}
`;
