import styled from 'styled-components';
import { THEME } from '../../../themes';

export const BarChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Bar = styled.div<{ value: number, backgroundColor: string }>`
  height: 32px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0 ${THEME.RADIUS.TIDY} ${THEME.RADIUS.TIDY} 0;
  width: ${({ value }) => `${value}%`};
  position: relative;
  display: flex;
  align-items: center;
  padding-left: ${THEME.SPACING.TINY};
`;

export const BarText = styled.div<{ textColor: string }>`
  font-size: 14px;
  color: ${({ textColor }) => textColor};
  position: absolute;
  font-weight: 500;
`;

export const BarValue = styled.div<{ valueColor: string }>`
  font-size: 14px;
  color: ${({ valueColor }) => valueColor};
`;
