import { useDispatch } from 'react-redux';
import { TalentNetworkTimelineBase } from '../../../talent-network-timeline-base/talent-network-timeline-base.component';
import { setTalentsNetworkDialogData } from '../../../../../../store/slices/match-state/match-state.toolkit-slice';
import { useShouldLockFeature } from '../../../../../../hooks/should-lock-feature.hook';
import { MutualExperienceSentence }
  from '../../../../../../modules/application/components/talent-network/mutual-experience-sentence/mutual-experience-sentence.component';
import { ExperienceItemTalentNetworkProps } from './experience-item-talent-network';

export const ExperienceItemTalentNetwork = ({ companyName, networkedTalents }: ExperienceItemTalentNetworkProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const isLocked = useShouldLockFeature();

  if (!networkedTalents?.length) {
    return null;
  }

  const onClick = () => {
    const experienceIds = networkedTalents.map((networkedTalent) => networkedTalent.experience.experienceId);
    dispatch(setTalentsNetworkDialogData({ isOpen: true, selectedExperienceIds: experienceIds?.length ? new Set(experienceIds) : undefined }));
  };

  return (
    <TalentNetworkTimelineBase
      onClickHandler={ onClick }
      talentsInfo={ networkedTalents.map((networkedTalent) => networkedTalent.talent) }
      isLocked={ isLocked }
    >
      <span>
        <MutualExperienceSentence companyName={ companyName } networkedTalents={ networkedTalents } />
      </span>
    </TalentNetworkTimelineBase>
  );
};
