import { CheckedIcon, UnCheckedIcon } from '../message-spices.styled';
import BaseSwitch from '../../../../../components/Common/base-switch/base-switch.component';
import {
  LeftContainer, Description, Header, Message, MessageContainer, MessageIcon, Title, TitleContainer, Container,
} from './message-spice-item.styled';
import { MessageSpiceItemProps } from './message-spice-item';

const MessageSpiceItem = ({
  title, description, message, checked, checkedHandler,
}: MessageSpiceItemProps): JSX.Element => {
  return (
    <Container>
      <LeftContainer>
        <Header>
          <TitleContainer>
            <Title>{ title }</Title>
            <Description>{ description }</Description>
          </TitleContainer>
        </Header>
        <MessageContainer>
          <MessageIcon />
          <Message>...{ message }...</Message>
        </MessageContainer>
      </LeftContainer>
      <BaseSwitch
        checked={ checked }
        switchChangeHandler={ (value) => checkedHandler(value) }
        CheckedIcon={ <CheckedIcon /> }
        UnCheckedIcon={ <UnCheckedIcon /> }
      />
    </Container>
  );
};
export default MessageSpiceItem;
