import styled from 'styled-components';
import { THEME } from '../../../../../../themes';

export const RoleDecoratorWrapper = styled.div<{isPromoted: boolean}>`
  display: contents;
  div {background-color: ${(props) => props.isPromoted ? THEME.COLORS.WARNING : '#eeeef5'}};
`;

// TO DO Color from theme?
export const RoleDecoratorOutside = styled.div`
  width: 24px;
  height: 24px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  border: solid 3px ${THEME.COLORS.PURE_WHITE};
  background-color: #eeeef5;
  position: absolute;
  margin-left: -76px;
`;

// TO DO Color from theme?
export const RoleDecoratorInside = styled.div`
  width: 12px;
  height: 12px;
  transform: translate(25%, 25%);
  border-radius: ${THEME.RADIUS.CIRCLE};
  border: solid 3px ${THEME.COLORS.PURE_WHITE};
  background-color: #eeeef5;
`;
