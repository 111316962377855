import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import CampaignPageBaseOption from '../campaign-base-option/campaign-base-option.component';
import SelectionGroupSwitch
  from '../../../../../components/Common/selection-group-switch/selection-group-switch.component';
import { Container, Icon } from './campaign-archive-conversation.styled';
import { CampaignArchiveConversationEnum } from './campaign-archive-conversation.enums';

const CampaignArchiveConversation = ({ active, changeHandler }: CampaignArchiveConversationProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const selectedValue = useMemo(() => {
    if (active) {
      return CampaignArchiveConversationEnum.Active;
    }

    return CampaignArchiveConversationEnum.Inactive;
  }, [active]);

  const onChangeSelection = (value: string) => {
    changeHandler(value === CampaignArchiveConversationEnum.Active);
  };

  const Description = useMemo(() => (
    <Trans
      defaults={ STRING_KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.DESCRIPTION }
      components={ { Underline: <u /> } }
    />
  ), []);

  return (
    <Container>
      <CampaignPageBaseOption
        title={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.TITLE) }
        description={ Description }
        Icon={ Icon }
        EndAdornment={ (
          <SelectionGroupSwitch
            changeHandler={ onChangeSelection }
            option1={ { id: CampaignArchiveConversationEnum.Inactive, title: translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.SWITCH.INACTIVE) } }
            option2={ { id: CampaignArchiveConversationEnum.Active, title: translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.SWITCH.ACTIVE) } }
            selectedValueId={ selectedValue }
          />
        ) }
      />
    </Container>
  );
};

export default CampaignArchiveConversation;
