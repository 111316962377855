import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import SelectItem from '../../../../components/Common/select/select-item/select-item.component';
import { ToneOfVoiceEnum } from '../../../../store/rtk-query/apis/sequence/enums/tone-of-voice.enum';
import {
  Container, Description, Icon, LeftContainer, StyledSelect, Title, TitleContainer,
} from './tone-of-voice.styled';
import { ToneOfVoiceProps } from './tone-of-voice';
import { TONE_OF_VOICE_TO_EMOJI_MAP } from './tone-of-voice.consts';

const ToneOfVoice = ({ selectedValue, changeHandler }: ToneOfVoiceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <LeftContainer>
        <Icon />
        <TitleContainer>
          <Title>{ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.DESCRIPTION) }</Description>
        </TitleContainer>
      </LeftContainer>
      <StyledSelect
        placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.SELECTION_LABEL) }
        value={ selectedValue }
        onChangeHandler={ changeHandler }
      >
        { Object.values(ToneOfVoiceEnum).map((toneOfVoice) => (
          <SelectItem
            key={ toneOfVoice }
            value={ toneOfVoice }
          >
            <>{`${TONE_OF_VOICE_TO_EMOJI_MAP[toneOfVoice]} ${translate(STRING_KEYS.OUTREACH_PAGE.TONE_OF_VOICE[toneOfVoice])}`}</>
          </SelectItem>
        )) }
      </StyledSelect>
    </Container>
  );
};
export default ToneOfVoice;
