import styled  from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body2Font, Title2Font } from '../../../../../themes/fonts';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  margin-right: ${THEME.SPACING.MEDIUM};
  position: relative;
`;

export const TalentInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${THEME.COLORS.CONTENT_A}
`;

export const TalentName = styled.div`
  ${Title2Font};
  font-weight: 600;
  width: fit-content;
`;

export const TalentDescription = styled.div`
  ${Body2Font};
  margin-top: ${THEME.SPACING.TINY};
  max-width: 480px;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  
  & strong {
    font-weight: 500;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const EngageButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  color: ${THEME.COLORS.PURE_WHITE};
  width: 235px;
`;

export const EngageIcon = styled(ASSETS.HEART)`
  width: 28px;
  height: fit-content;
`;

export const SkipButton = styled(BaseButton)`
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.GHOSTLY)};
  color: ${THEME.COLORS.ACCENT};
  width: 109px;

    :before {
      content: '▶▶';
      letter-spacing: -6px;
      margin-right: ${THEME.SPACING.TINO};
    }
  `;

export const TalentInfoContainer = styled.div`
  display: flex; 
  flex-direction: column;
  padding-top: ${THEME.SPACING.MICRO};
`;

export const TalentNameContainer = styled.div`
  display: flex; 
  max-width: 480px;
`;

export const LinkedinIcon = styled(ASSETS.LINKEDIN_LETTERS)`
  width: 13px;
  height: 12px;
  fill: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const LinkedinButton = styled.a`
  align-self: end;
  margin-left: ${THEME.SPACING.MICRO};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.TINY} 0 ${THEME.SPACING.TINY};
`;
