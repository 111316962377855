import { ChangeEvent, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetAccountByIdQuery } from '../../../../store/rtk-query/apis/account/hooks/get-account-by-id.query-hook';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import STRING_KEYS from '../../../../language/keys';
import { OutreachContentPreferencesFormikKeys } from '../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import { DividerSection } from '../../../../components/Common/Divider/styled';
import { getCompanyByIdQuery } from '../../../../store/rtk-query/apis/company/hooks/get-company-by-id.query-hook';
import PositionLocation from '../../../../components/Common/position-location/position-location.component';
import MessageItem from './message-item/message-item.component';
import {
  Container, Title, TitleContainer, TitleIcon,
} from './message-content.styled';
import { MessageContentProps } from './message-content';

const MessageContent = ({
  aboutTheCompany,
  aboutTheRole,
  extras,
  onChangeHandler,
}: MessageContentProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const [autoFillAboutRoleLoading, setAutoFillAboutRoleLoading] = useState(false);

  const {
    data: { accountId },
  } = useGetMyUserQuery();
  const { data: position } = useGetPositionByIdQuery(positionId);
  const { data: { companyId } } = useGetAccountByIdQuery(accountId);
  const { data: company } = getCompanyByIdQuery(companyId);

  const aboutTheRoleTitle = useMemo(() => (
    <Trans
      i18nKey={ STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.ITEMS_TITLE.ROLE }
      values={ { positionName: position.jobTitle } }
    >
      <PositionLocation remotePosition={ !!position.remotePosition } locations={ position.locations } />
    </Trans>
  ), [position]);

  const onCompanyMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AboutTheCompany, e.target.value);
  };

  const onRoleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AboutTheRole, e.target.value);
  };

  const onExtraMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.Extras, e.target.value);
  };

  const onAboutCompanyAutoFillClick = async () => {
    if (company?.fullDescription) {
      onChangeHandler(OutreachContentPreferencesFormikKeys.AboutTheCompany, company.fullDescription);
    }
  };

  const onAboutTheRoleAutoFillClick = async () => {
    setAutoFillAboutRoleLoading(true);
    setTimeout(() => {
      if (position.aboutTheRole) {
        onChangeHandler(OutreachContentPreferencesFormikKeys.AboutTheRole, position.aboutTheRole);
      }
      setAutoFillAboutRoleLoading(false);
    }, 1000);
  };

  return (
    <Container>
      <TitleContainer>
        <TitleIcon />
        <Title>
          { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.TITLE) }
        </Title>
      </TitleContainer>
      <MessageItem
        message={ aboutTheCompany }
        messageChangeHandler={ onCompanyMessageChange }
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.ITEMS_TITLE.COMPANY) }
        description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.ITEMS_DESCRIPTION.COMPANY) }
        autoFillClickHandler={ onAboutCompanyAutoFillClick }
        showAutoFillButton={ !aboutTheCompany && !!company?.fullDescription }
      />
      <DividerSection />
      <MessageItem
        message={ aboutTheRole }
        messageChangeHandler={ onRoleMessageChange }
        title={ aboutTheRoleTitle }
        description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.ITEMS_DESCRIPTION.ROLE) }
        autoFillClickHandler={ onAboutTheRoleAutoFillClick }
        isAutoFillButtonLoading={ autoFillAboutRoleLoading }
        showAutoFillButton={ !aboutTheRole && !!position?.aboutTheRole }
      />
      <MessageItem
        message={ extras }
        messageChangeHandler={ onExtraMessageChange }
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.ITEMS_TITLE.EXTRA) }
        description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.ITEMS_DESCRIPTION.EXTRA) }
      />
    </Container>
  );
};
export default MessageContent;
