import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import {
  BarChartData,
  BarChartDataItem,
  HorizontalBarChart,
} from '../horizontal-bar-chart/horizontal-bar-chart.component';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { AccentBold, Bold } from './industries-insight-widget.styled';

const chartTheme: Record<string, Partial<BarChartDataItem>> = {
  firstElement: {
    backgroundColor: THEME.COLORS.ACCENT, textColor: THEME.COLORS.PURE_WHITE, valueColor: THEME.COLORS.ACCENT,
  },
  middleElement: {
    backgroundColor: THEME.COLORS_RGB.ACCENT_DIVIDER, textColor: THEME.COLORS.ACCENT, valueColor: THEME.COLORS.ACCENT,
  },
  lastElement: {
    backgroundColor: THEME.COLORS_RGB.PRIMARY_DIVIDER, textColor: THEME.COLORS.PRIMARY, valueColor: THEME.COLORS.PRIMARY,
  },
};

export interface IndustryInsight {
  value: number;
  text: string;
}

export interface TopIndustriesWidgetProps {
  jobTitle: string;
  data: IndustryInsight[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const IndustriesInsightWidget = ({
  jobTitle,
  data,
  onIntersectionHandler,
}:TopIndustriesWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);

  data.sort((insight1: IndustryInsight, insight2: IndustryInsight) => insight2.value - insight1.value);
  const mostCommonIndustry = data[0];

  useInsightWidgetIntersection({
    componentRef, onIntersectionHandler, metadata: `${mostCommonIndustry?.value}%`, metadataType: mostCommonIndustry?.text,
  });

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.INDUSTRIES_INSIGHT_CARD_TEXT }>
        <AccentBold />
        <Bold />
        {{ percentage: mostCommonIndustry?.value }}
        {{ jobTitle }}
        {{ industryName: mostCommonIndustry?.text }}
      </Trans>
    );
  };

  const createChartData = (): BarChartDataItem[] => {
    return data.map((insight: IndustryInsight, index: number) => {
      let barStyle: Partial<BarChartDataItem>;
      if (index === 0) {
        barStyle = chartTheme.firstElement;
      } else if (index === data.length - 1) {
        barStyle = chartTheme.lastElement;
      } else {
        barStyle = chartTheme.middleElement;
      }

      return { ...insight, ...barStyle } as BarChartDataItem;
    });
  };

  const createChartObject = (): BarChartData => {
    return {
      data: createChartData(),
      valueTransformationMethod: (value: number) => {
        if (value < 1) {
          return '< 1%';
        }

        return `${value}%`;
      },
      shouldIncreaseBarScale: true,
    };
  };

  const renderCardBody = () => {
    return (
      <div ref={ componentRef }>
        <HorizontalBarChart chartData={ createChartObject() } />
      </div>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="🏭"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.INDUSTRY_INSIGHTS_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

IndustriesInsightWidget.defaultProps = {
  onIntersectionHandler: undefined,
};
