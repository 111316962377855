/* eslint-disable react/jsx-props-no-spreading */
import {
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import Portal from '../Common/Portal/portal.commponent';
import { TooltipContainer, TooltipTip } from './tooltip.styled';
import { TooltipProps } from './tooltip';

// TODO: BUG has issues on multiple tooltips.
const Tooltip = ({
  content,
  children,
  placement = 'top',
  customTooltipStyle,
  ...otherProps
}: TooltipProps): JSX.Element => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [isTooltipHover, setIsTooltipHover] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 6] } }],
  });

  const handleMouseEnter = () => {
    if (!isTooltipVisible) {
      setIsTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (isTooltipVisible) {
      setTimeout(() => {
        setIsTooltipVisible(false);
      }, 500);
    }
  };

  return (
    <>
      <TooltipContainer
        onMouseEnter={ handleMouseEnter }
        onMouseLeave={ handleMouseLeave }
        ref={ setReferenceElement }
        { ...otherProps }
      >
        {children}
        {(isTooltipVisible || isTooltipHover) && (
          <Portal containerId="tooltip-container" isGlobalPortal>
            <TooltipTip
              onMouseEnter={ () => setIsTooltipHover(true) }
              onMouseLeave={ () => setIsTooltipHover(false) }
              ref={ setPopperElement }
              style={ {
                ...customTooltipStyle,
                ...styles.popper,
              } }
              { ...attributes.popper }
            >
              {content}
            </TooltipTip>
          </Portal>
        )}
      </TooltipContainer>
    </>
  );
};

export default Tooltip;
