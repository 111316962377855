import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import STRING_KEYS from '../../../language/keys';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import {
  BodyContainer,
  Bold,
  CurrentRoleContainer,
  CurrentRoleNodeTextWrapper,
  LeftRolesWrapper,
  NodesGroupLine,
  RightRolesContainer,
  RightRolesWrapper,
  RoleNode,
  RoleNodeTextWrapper,
  RolesContainer,
  RolesContainerWrapper,
} from './role-journey-widget.styled';

export interface RoleJourneyWidgetProps {
  currentTitle: string;
  previousTitles: string[];
  nextTitles: string[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const RoleJourneyWidget = ({
  currentTitle,
  previousTitles,
  nextTitles,
  onIntersectionHandler,
}:RoleJourneyWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);

  useInsightWidgetIntersection({
    componentRef,
    onIntersectionHandler,
    metadataStruct: {
      previous: previousTitles,
      next: nextTitles,
    },
  });

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.ROLE_JOURNEY_CARD_TEXT }>
        <Bold />
        {{ jobTitle: currentTitle }}
      </Trans>
    );
  };

  const renderCardBody = () => {
    return (
      <BodyContainer ref={ componentRef }>
        <RolesContainer>
          <RolesContainerWrapper>
            <LeftRolesWrapper>
              { previousTitles.map((title: string) => (
                <RoleNode key={ title } title={ title }>
                  <RoleNodeTextWrapper>
                    { title }
                  </RoleNodeTextWrapper>
                </RoleNode>
              )) }
            </LeftRolesWrapper>
            <NodesGroupLine />
          </RolesContainerWrapper>
        </RolesContainer>
        <CurrentRoleContainer title={ currentTitle }>
          <CurrentRoleNodeTextWrapper>
            { currentTitle }
          </CurrentRoleNodeTextWrapper>
        </CurrentRoleContainer>
        <RightRolesContainer>
          <NodesGroupLine invertSide />
          <RightRolesWrapper>
            { nextTitles.map((title: string) => (
              <RoleNode invertSide key={ title } title={ title }>
                <RoleNodeTextWrapper>
                  { title }
                </RoleNodeTextWrapper>
              </RoleNode>
            )) }
          </RightRolesWrapper>
        </RightRolesContainer>
      </BodyContainer>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="💡"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.ROLE_INSIGHT_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

RoleJourneyWidget.defaultProps = {
  jobTitle: undefined,
};
