import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex:1;
  padding: 0;
  margin-bottom: 40px;
`;

export const LabelSkeleton = styled(Skeleton)`
  width: 150px;
  height: 15px;
  display: flex;
`;
  
export const Title = styled(LabelSkeleton)`
  width: 100px;
  margin-bottom: -40px;
  height: 24px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;
