import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';

export const Container = styled.div<{ isAutoMode?: boolean }>`
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${({ isAutoMode }) => isAutoMode ? undefined : THEME.COLORS.LIGHT_GREY};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.MEDIUM} ${THEME.SPACING.TINY} 0;
  transition: 500ms;
`;

export const AutoModeIcon = styled(ASSETS.LIGHTNING)`
  height: 12px;
`;

export const Icon = styled(ASSETS.FILLED_PLUS)`
  height: 24px;
`;
