import log from 'loglevel';
import { convertMonthsToYears } from '../../../../utils/dates';
import { THEME } from '../../../../themes';
import { WIDGET_TYPE } from '../../../../pages/match-page/match.page.const';
import { Experience } from '../experience-information/experience-information.component';
import { TalentBio } from '../talent-bio/talent-bio.component';
import { WidgetWrapper } from '../smart-widgets/widget-wrapper/widget-wrapper.component';
import { MoveMeterWidget } from '../smart-widgets/move-meter-widget/move-meter-widget.component';
import { SkillsWidget } from '../smart-widgets/skills-widget/skills-widget.component';
import { EducationInformation } from '../education-information/education-information.component';
import { WorkedWithWidget } from '../smart-widgets/worked-with-widget/worked-with-widget.component';
import { SocialLinksWidget } from '../smart-widgets/social-links-widget/social-links-widget-component';
import { SmartWidgetsSkeleton } from '../smart-widgets/smart-widgets-skeleton/smart-widgets-skeleton.component';
import { TenureMeterTimeWidget } from '../smart-widgets/tenure-meter-time-widget/tenure-meter-time-widget.component';
import { experienceAggregation, getFormattedWorkedWithData } from '../match-content.utils';
import { SmartBadge } from '../../../../@types/smartBadges';
import { NetworkedTalentResponseDto } from '../../../../store/rtk-query/apis/match/dto/response/match.response';
import { ANALYTICS_EVENTS } from '../../../../consts/analytics';
import { createTalentNetworkCompanyEducationMap, createTalentNetworkCompanyExperienceMap } from '../../match.utils';
import { PageContent, WidgetsContainer, TalentInfoContainer } from './match-page-content.styled';

const logger = log.getLogger('MatchPageContent');

type MatchPageContentProps = {
  matchId: string,
  positionId: string,
  talentInfo: TalentInfo,
  engagementStatus: string,
  isLoading: boolean,
  educationData?: MatchEducationHistory[],
  smartWidgets: SmartWidget[],
  smartBadges: SmartBadge[],
  talent: Talent,
  experienceData?: MatchEmploymentHistoryRecord[],
  accountCompanyName: string,
  trackSocialLinkClick?: (socialChannel: string) => void,
  trackCompanyInsightsClick?: (
    matchExperiencePeriod: string,
    matchExperienceCompanyId: string,
    matchExperienceCompanyName: string,
  ) => void,
  trackMatchElementViewed: (eventName: string, componentElementType: string, componentMetadata?: string) => void,
  trackSkillsComponentExpand?: (componentName: string) => void,
  matchSummary?: string;
  networkedTalents?: NetworkedTalentResponseDto[];
  totalYearsOfExperience: number,
  matchViewed: boolean;
  previousCompanyNames: string[];
}

// TODO: delete
export const MatchPageContent = ({
  matchId,
  positionId,
  talent,
  talentInfo,
  isLoading,
  engagementStatus,
  educationData,
  smartWidgets,
  smartBadges,
  experienceData,
  accountCompanyName,
  trackSocialLinkClick,
  trackCompanyInsightsClick,
  trackMatchElementViewed,
  trackSkillsComponentExpand,
  matchSummary,
  networkedTalents,
  totalYearsOfExperience,
  matchViewed,
  previousCompanyNames,
}: MatchPageContentProps): JSX.Element => {
  const sortedWidgets = smartWidgets.slice().sort((widgetA, widgetB) => widgetB.order - widgetA.order);

  const onWidgetIntersect = (widgetType: string) => {
    trackMatchElementViewed?.(ANALYTICS_EVENTS.MATCH_PAGE_WIDGET_VIEWED, widgetType.toLowerCase());
  };

  const defineWidgetType = (widget: SmartWidget) => {
    const widgetKey = `${matchId}-${widget.type}-${widget.order}`;

    switch (widget.type) {
      case WIDGET_TYPE.LIKELIHOOD_TO_MOVE_METER:
        return (
          <WidgetWrapper
            key={ widgetKey }
            width="344px"
            height="auto"
            backgroundColor={ THEME.COLORS.SURFACE_PRIMARY }
            padding={ THEME.SPACING.MEDIUM }
            intersectionHandler={ () => onWidgetIntersect(widget.type) }
          >
            <MoveMeterWidget
              data={ (widget.data as MoveMeterData) || {} }
              childWidgets={ widget?.childWidgets || [] }
            />
          </WidgetWrapper>
        );
      case WIDGET_TYPE.TALENT_SKILLS:
        return (
          <WidgetWrapper
            key={ widgetKey }
            width="342px"
            height="auto"
            backgroundColor={ THEME.COLORS.SURFACE_PRIMARY }
            padding={ THEME.SPACING.MEDIUM }
            intersectionHandler={ () => onWidgetIntersect(widget.type) }
          >
            <SkillsWidget
              skills={ (widget.data as SkillData[]) || [] }
              trackSkillsComponentExpand={ trackSkillsComponentExpand }
            />
          </WidgetWrapper>
        );
      case WIDGET_TYPE.PERSONAL_TENURE_COMPARISON: {
        const { averagePersonalTenureInMonths, currentPersonalTenureInMonths, type } =
          widget?.data as TenureData;

        return (
          <WidgetWrapper
            key={ widgetKey }
            width="345px"
            height="251px"
            backgroundColor={ THEME.COLORS.SURFACE_PRIMARY }
            padding={ THEME.SPACING.MEDIUM }
            intersectionHandler={ () => onWidgetIntersect(widget.type) }
          >
            <TenureMeterTimeWidget
              average={ convertMonthsToYears(averagePersonalTenureInMonths) }
              experience={ convertMonthsToYears(currentPersonalTenureInMonths) }
              talentName={ talent?.firstName || '' }
              type={ type }
              currentCompanyName={ talentInfo.currentCompanyName }
            />
          </WidgetWrapper>
        );
      }
      case WIDGET_TYPE.WORKED_WITH: {
        const workedWithData = widget.data as WorkedWithData;

        if (!workedWithData || !workedWithData.workedWith) {
          logger.error('missing data in WORKED_WITH widget. hiding this widget', workedWithData);

          return null;
        }

        return (
          <WidgetWrapper
            key={ widgetKey }
            width="342px"
            height="auto"
            backgroundColor={ THEME.COLORS.SURFACE_PRIMARY }
            padding={ THEME.SPACING.MEDIUM }
            intersectionHandler={ () => onWidgetIntersect(widget.type) }
          >
            <WorkedWithWidget
              workedWithList={ getFormattedWorkedWithData(workedWithData.workedWith) }
              companyName={ accountCompanyName }
            />
          </WidgetWrapper>

        );
      }
      case WIDGET_TYPE.SOCIAL_LINKS: {
        return (
          <SocialLinksWidget
            key={ widgetKey }
            socialLinks={ widget.data as SocialLinkData[] || [] }
            trackSocialLinkClick={ trackSocialLinkClick }
          />
        );
      }
      default:
        return null;
    }
  };

  const isSocialLinkWidgetExist = sortedWidgets
    .some((widget) => widget.type === WIDGET_TYPE.SOCIAL_LINKS);

  const companyExperienceMap = createTalentNetworkCompanyExperienceMap(networkedTalents);
  const companyEducationMap = createTalentNetworkCompanyEducationMap(networkedTalents);

  return (
    <>
      <TalentBio
        matchId={ matchId }
        positionId={ positionId }
        profileImage={ talent?.profileImage }
        firstName={ talent?.firstName }
        lastName={ talent?.lastName }
        engagementStatus={ engagementStatus }
        isLoading={ isLoading }
        currentJobTitle={ talentInfo.currentJobTitle }
        location={ talentInfo.location }
        currentCompanyName={ talentInfo.currentCompanyName }
        currentJobExperienceInMonths={ talentInfo.currentJobExperienceInMonths }
        smartBadges={ smartBadges }
        matchSummary={ matchSummary }
        totalYearsOfExperience={ totalYearsOfExperience }
        matchViewed={ matchViewed }
        previousCompanyNames={ previousCompanyNames }
        trackMatchElementViewed={ trackMatchElementViewed }
      />
      <PageContent>
        <TalentInfoContainer>
          <Experience
            data={ experienceAggregation(experienceData).reverse() || [] }
            isLoading={ isLoading }
            trackCompanyInsightsClick={ trackCompanyInsightsClick }
            trackMatchElementViewed={ trackMatchElementViewed }
            companyExperienceMap={ companyExperienceMap }
          />

          {educationData && educationData.length > 0 && (
            <EducationInformation
              isLoading={ isLoading }
              data={ educationData }
              trackMatchElementViewed={ trackMatchElementViewed }
              companyEducationMap={ companyEducationMap }
            />
          )}
        </TalentInfoContainer>
        <WidgetsContainer>
          {
            isLoading ? <SmartWidgetsSkeleton /> :
              sortedWidgets.map((widget:SmartWidget) => {
                return defineWidgetType(widget);
              })
          }
          {!isSocialLinkWidgetExist && talent?.socialLinks &&
          (
            <SocialLinksWidget
              socialLinks={ talent.socialLinks }
            />
          )}
        </WidgetsContainer>
      </PageContent>
    </>
  );
};

MatchPageContent.defaultProps = {
  educationData: undefined,
  experienceData: undefined,
  trackSocialLinkClick: undefined,
  trackCompanyInsightsClick: undefined,
  trackSkillsComponentExpand: undefined,
  matchSummary: undefined,
  networkedTalents: [],
};
