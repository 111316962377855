/* eslint-disable react/no-array-index-key */
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInsightData } from '../../../../../../hooks/get-company-by-id.hook';
import { TimelineLabel } from '../timeline.styled';
import CompanyDialog from '../../../../../../components/dialogs/company-dialog/company-dialog.component';
import STRING_KEYS from '../../../../../../language/keys';
import { ExperienceItem } from '../../../../../../@types/match';
import DynamicHeightContainer
  from '../../../../../../components/Common/dynamic-height-container/dynamic-height-container.component';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import MatchCardContext from '../../context/match-card.context';
import ExperienceContainerItem from './experience-container-item/experience-container-item.component';
import { ShowMoreButton } from './experience-container.styled';

const MAX_ITEMS_IN_CLOSED_STATE = 3;

const ExperienceContainer = ({ items, viewChangeHandler }: ExperienceContainerProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const { actions } = useContext(MatchCardContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<null | ExperienceItem>(null);

  const createMinifiedExperiences = () => {
    let remainingRolesCount = MAX_ITEMS_IN_CLOSED_STATE;
    const experienceItems: ExperienceItem[] = [];
    let hasMoreExperiences = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const renderItemsAmount = Math.min(remainingRolesCount, item.data.length);
      experienceItems.push({ ...item, renderRolesAmount: renderItemsAmount });
      remainingRolesCount -= renderItemsAmount;

      if (remainingRolesCount <= 0) {
        hasMoreExperiences = true;
        break;
      }
    }

    if (!hasMoreExperiences) {
      setIsExpanded(true);
    }

    return experienceItems;
  };

  const itemsForRender = useMemo(() => {
    if (isExpanded) {
      return items;
    }

    const totalExperiences = items.reduce((sum, item) => sum + item.data.length, 0);
    if (totalExperiences <= MAX_ITEMS_IN_CLOSED_STATE) {
      setIsExpanded(true);

      return items;
    }

    return createMinifiedExperiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, isExpanded]);

  const selectedCompanyData: CompanyInsightData | null = useMemo(() => (selectedItem ? {
    companyId: selectedItem?.companyId,
    companyName: selectedItem?.companyName,
    companyLogo: selectedItem?.companyLogo,
    industries: selectedItem?.industries || [],
    companyDescription: selectedItem?.companyDescription,
    companyEnrichedData: selectedItem?.companyEnrichedData || {},
  } : null), [selectedItem]);

  const closeCompanyModal = () => {
    setSelectedItem(null);
  };

  const onItemClick = (item: ExperienceItem) => {
    setSelectedItem(item);
  };

  if (!items?.length) {
    return null;
  }

  const onSeeMoreButtonClick = () => {
    actions.setIsExpanded(true);
    setIsExpanded(true);
  };

  return (
    <div>
      <TimelineLabel>
        { translate(STRING_KEYS.MATCH_CARD.EXPERIENCE.LABEL) }
      </TimelineLabel>
      <DynamicHeightContainer>
        <div>
          { itemsForRender.map((item, index) => (
            <ExperienceContainerItem
              key={ item.companyName + index }
              experienceItem={ item }
              clickHandler={ () => onItemClick(item) }
              viewChangeHandler={ viewChangeHandler }
              isLastItem={ items.length - 1 === index }
            />
          )) }
        </div>
      </DynamicHeightContainer>
      { !isExpanded && (
        <ShowMoreButton
          type="button"
          variant={ PerfectButtonVariants.Outline }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ onSeeMoreButtonClick }
        >
          { translate(STRING_KEYS.SEE_MORE) }
        </ShowMoreButton>
      ) }
      { !!selectedCompanyData && (
        <CompanyDialog
          isOpen
          closeHandler={ closeCompanyModal }
          companyData={ selectedCompanyData }
        />
      ) }
    </div>
  );
};

export default ExperienceContainer;
