import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';
import AvatarsList from '../AvatarsList';
import StyledAvatar from '../styled-avatar/styled-avatar.component';
import { useGetAccountUsersQuery } from '../../../store/rtk-query/apis/user/hooks/get-account-users.query-hook';
import { PerfectMenuSize } from '../perfect-base-menu/perfect-base-menu.enums';
import { UserResponse } from '../../../store/rtk-query/apis/user/dto/response/invited-users.response';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import useDebounce from '../../../hooks/debounce';
import UserMenuItem from '../user-menu-item/user-menu-item.component';
import {
  AddUserIcon,
  AddUserIconWrapper,
  AssignIcon,
  AVATAR_SIZE_PX,
  Container,
  EmptyStateContainer,
  InviteTeammateMenuItem,
  InviteTeammateMenuItemIcon,
  Label,
  MenuTitle,
  MultipleUsersContainer,
  SingularAvatarContainer,
  SingularUserContainer,
  SingularUserName,
  StyledMenuItem,
  UsersMenu,
} from './user-picker.styled';

const STYLED_AVATAR_INITIALS_FONT_SIZE_PX = 16;
const STYLED_AVATAR_BORDER_WIDTH_PX = 0;
const STYLED_AVATAR_OUTLINE_WIDTH_PX = 0;
const CHANGE_DEBOUNCE_MS = 1000;

const logger = log.getLogger('USER_PICKER');

const UserPicker = ({
  label,
  menuTitle,
  selectedUsers,
  changeHandler,
  maxDisplayAvatars,
  showAddUserIcon,
  openInviteUsersModalHandler,
}: UserPickerProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const { data: users, isLoading: isGetUsersLoading, isSuccess } = useGetAccountUsersQuery();
  const { data: currentUser } = useMyUserSelector();
  const { t: translate } = useTranslation();
  const [internalSelectedUsers, setInternalSelectedUsers] = useState(selectedUsers);
  const [isTouched, setIsTouched] = useState(false);
  const debouncedSelectedUsers = useDebounce(internalSelectedUsers, CHANGE_DEBOUNCE_MS);

  useEffect(() => {
    if (isTouched) {
      changeHandler(debouncedSelectedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(debouncedSelectedUsers)]);

  const selectedUsersSet = useMemo(() => new Set(internalSelectedUsers), [internalSelectedUsers]);
  const usersMapById: Map<string, UserResponse> = useMemo(() => {
    if (!isSuccess) {
      return new Map();
    }

    return new Map(users.map((user) => [user.id, user]));
    // TODO temporary fix, should discus with Din
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length, isSuccess]);

  useEffect(() => {
    const filteredUsers = selectedUsers?.filter((id) => usersMapById?.has(id));
    setInternalSelectedUsers(filteredUsers);
  }, [selectedUsers, usersMapById]);

  const renderEmptyState = () => {
    return (
      <EmptyStateContainer isActive={ !!anchorElement } >
        <AssignIcon />
        { translate(STRING_KEYS.USER_PICKER.ASSIGN) }
      </EmptyStateContainer>
    );
  };

  const renderSingular = (): JSX.Element | null => {
    const selectedUser = usersMapById.get(internalSelectedUsers[0]);

    if (!selectedUser) {
      logger.warn(`Has selected user at ${label} but the user not found in account users`, { accountId: currentUser.accountId });
    }

    return (
      <SingularUserContainer isActive={ !!anchorElement } >
        <SingularAvatarContainer>
          <StyledAvatar
            name={ selectedUser?.fullName || selectedUser?.email || translate(STRING_KEYS.UNKNOWN) }
            image={ selectedUser?.profilePictureUrl }
            initialsFontSizePx={ STYLED_AVATAR_INITIALS_FONT_SIZE_PX }
            borderWidthPx={ STYLED_AVATAR_BORDER_WIDTH_PX }
            outlineWidthPx={ STYLED_AVATAR_OUTLINE_WIDTH_PX }
          />
        </SingularAvatarContainer>
        <SingularUserName>
          { selectedUser?.fullName || translate(STRING_KEYS.UNKNOWN) }
        </SingularUserName>
      </SingularUserContainer>
    );
  };

  const avatarListIcons = useMemo(() => {
    return internalSelectedUsers.filter((userId) => usersMapById.has(userId)).map((userId) => {
      const user = usersMapById.get(userId);

      return {
        userName: user?.fullName || user?.email || '',
        profileImage: user?.profilePictureUrl,
      };
    });
  }, [usersMapById, internalSelectedUsers]);

  const renderMultiple = () => {
    return (
      <MultipleUsersContainer>
        <AvatarsList
          maxNumberInGroup={ maxDisplayAvatars || 11 }
          iconSize={ AVATAR_SIZE_PX }
          iconBorderWidthPx={ 0 }
          iconOutlineWidthPx={ 2 }
          spacing={ 3 }
          iconOutlineColor={ THEME.COLORS.LIGHT_GREY }
          icons={ avatarListIcons }
        />
        { showAddUserIcon && (
          <AddUserIconWrapper>
            <AddUserIcon />
          </AddUserIconWrapper>
        ) }
      </MultipleUsersContainer>
    );
  };

  const renderContent = () => {
    if (!internalSelectedUsers.length) {
      return renderEmptyState();
    }

    if (internalSelectedUsers.length === 1) {
      return renderSingular();
    }

    return renderMultiple();
  };

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      anchorElement ? setAnchorElement(null) : setAnchorElement(anchorElementRef?.current);
    },
    [anchorElement]);

  const handleMenuClose = () => setAnchorElement(null);

  const onMenuItemClick = (userId: string) => {
    const checked = selectedUsersSet.has(userId);

    let selectedUsersArray = Array.from(selectedUsersSet);

    if (checked) {
      selectedUsersArray = selectedUsersArray.filter((selectedUserId: string) => selectedUserId !== userId);
    } else {
      selectedUsersArray.push(userId);
    }

    setInternalSelectedUsers(selectedUsersArray);
    setIsTouched(true);
  };

  const onInviteTeammateButtonClick = () => {
    openInviteUsersModalHandler?.();
    setAnchorElement(null);
  };

  const renderMenuItems = (): JSX.Element | undefined => {
    if (isGetUsersLoading) {
      return undefined;
    }

    return (
      <>
        { users.map((user) => (
          <StyledMenuItem
            key={ user.id }
          >
            <UserMenuItem
              user={ user }
              showCheckbox
              checked={ selectedUsersSet.has(user.id) }
              isCurrentUser={ currentUser.id === user.id }
              clickHandler={ () => onMenuItemClick(user.id) }
              subtitleOptions={ { showUserVerifiedIndication: true } }
            />
          </StyledMenuItem>
        ))}
        <InviteTeammateMenuItem onClick={ onInviteTeammateButtonClick } >
          <InviteTeammateMenuItemIcon />
          <div>{ translate(STRING_KEYS.USER_PICKER.INVITE_TEAMMATE_TEXT) }</div>
        </InviteTeammateMenuItem>
      </>
    );
  };

  return (
    <>
      <Container ref={ anchorElementRef } onClick={ (event) => handleClick(event) }>
        <Label>{ label }</Label>
        { renderContent() }
      </Container>
      <UsersMenu
        isOpen={ !!anchorElement }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
        onClose={ handleMenuClose }
        Title={ <MenuTitle>{ menuTitle }</MenuTitle> }
        size={ PerfectMenuSize.Large }
      >
        { renderMenuItems() }
      </UsersMenu>
    </>
  );
};

export default UserPicker;
