import { createSlice } from '@reduxjs/toolkit';
import { initialMatchState } from './match-state.initial-state';
import { filteredMatchesReducer } from './reducers/filtered-matches.reducers';
import {
  closeTalentNetworkDialogReducer,
  talentNetworkDialogDataReducer,
} from './reducers/talents-network-modal-open.reducers';

const MatchStateSlice = createSlice({
  name: 'match-state',
  initialState: initialMatchState,
  reducers: {
    filteredMatches: filteredMatchesReducer,
    talentsNetworkDialogData: talentNetworkDialogDataReducer,
    closeTalentNetworkDialog: closeTalentNetworkDialogReducer,
  },
});

export const {
  filteredMatches: setFilteredMatches,
  talentsNetworkDialogData: setTalentsNetworkDialogData,
  closeTalentNetworkDialog,
} = MatchStateSlice.actions;

export default MatchStateSlice.reducer;
