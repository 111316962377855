import { Bar } from '@nivo/bar';
import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { AccentBold, BodyWrapper, Bold } from './median-experience-widget.styled';
import { medianExperienceChartSettings } from './chart-settings.consts';

const barColors: {[key: string]: string } = {
  thisRoleColor: THEME.COLORS.PRIMARY,
  otherTechRoleColor: THEME.COLORS_RGB.PRIMARY_DIVIDER,
  thisRoleMedianColor: THEME.COLORS.ACCENT,
  otherTechRoleMedianColor: THEME.COLORS_RGB.ACCENT_DIVIDER,
};

export interface MedianExperienceData {
  years: string;
  thisRole: number;
  otherTechRole: number;
  isMedian?: boolean;
}

export interface MedianExperienceWidgetProps {
  jobTitle: string;
  medianYearTenure: number;
  data: MedianExperienceData[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

const chartBarKeyToTranslationKey: Record<string, unknown> = {
  [medianExperienceChartSettings.thisRoleKey]: STRING_KEYS.INSIGHT_WIDGETS.MEDIAN_EXPERIENCE_THIS_ROLE_LABEL,
  [medianExperienceChartSettings.otherRolesKey]: STRING_KEYS.INSIGHT_WIDGETS.MEDIAN_EXPERIENCE_OTHER_ROLES_LABEL,
};

export const MedianExperienceWidget = ({
  jobTitle,
  medianYearTenure,
  data,
  onIntersectionHandler,
}:MedianExperienceWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);
  useInsightWidgetIntersection({
    componentRef, onIntersectionHandler, metadata: `median_experience: ${medianYearTenure}`,
  });

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.MEDIAN_EXPERIENCE_CARD_TEXT }>
        <AccentBold />
        <Bold />
        {{ medianYearTenure }}
        {{ jobTitle }}
      </Trans>
    );
  };

  const createChartData = () => {
    return data.map((dataItem: MedianExperienceData) => {
      return {
        years: dataItem.years,
        thisRole: dataItem.thisRole,
        otherTechRole: dataItem.otherTechRole,
        thisRoleColor: dataItem.isMedian ? barColors.thisRoleMedianColor : barColors.thisRoleColor,
        otherTechRoleColor: dataItem.isMedian ? barColors.otherTechRoleMedianColor : barColors.otherTechRoleColor,
      };
    });
  };

  const renderCardBody = () => {
    return (
      <BodyWrapper ref={ componentRef }>
        <Bar
          width={ medianExperienceChartSettings.width }
          enableLabel={ medianExperienceChartSettings.enableLabel }
          margin={ medianExperienceChartSettings.margin }
          height={ medianExperienceChartSettings.height }
          axisBottom={ medianExperienceChartSettings.axisBottom }
          axisLeft={ medianExperienceChartSettings.axisLeft }
          data={ createChartData() }
          indexBy={ medianExperienceChartSettings.indexBy }
          keys={ [medianExperienceChartSettings.thisRoleKey, medianExperienceChartSettings.otherRolesKey] }
          groupMode={ medianExperienceChartSettings.groupMode }
          isInteractive={ medianExperienceChartSettings.isInteractive }
          borderRadius={ medianExperienceChartSettings.borderRadius }
          colors={ medianExperienceChartSettings.barColors }
          innerPadding={ medianExperienceChartSettings.innerPadding }
          padding={ medianExperienceChartSettings.padding }
          theme={ medianExperienceChartSettings.theme }
          legends={ medianExperienceChartSettings.legends }
          legendLabel={ (datum) => translate(`${chartBarKeyToTranslationKey[datum.id.toString()]}`).toUpperCase() }
        />
      </BodyWrapper>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="💡"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.EXPERIENCE_INSIGHT_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

MedianExperienceWidget.defaultProps = {
  onIntersectionHandler: undefined,
};
