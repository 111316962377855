import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Autocomplete } from '../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import { InputStyle } from '../../../../onboarding/components/input/input.component';
import { IconButtonVariants } from '../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import {
  AddButtonIcon,
  RemoveButtonIcon,
  StyledIconButton,
} from '../../../../../components/Common/create-position-autocomplete/autocomplete.styled';
import OpenGuidanceModalButton
  from '../../../../../components/dialogs/guidance-modal/open-guidance-modal-button/open-guidance-modal-button.component';
import {
  InstructionsMessageContainer,
  SkipButton,
  SkipButtonIcon,
  SkipMessage,
} from './sentence-input.styled';

const SentenceInput = ({
  inputElementId,
  value,
  placeholder,
  changeHandler,
  addClickHandler,
  guidanceButtonClickHandler,
  showInstructionMessage = true,
  showAddButton,
  removeClickHandler,
  skipButtonClickHandler,
  showRemoveButton,
  autoFocus,
  showSkipButton,
}: SentenceInputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [isInputFocused, setInputFocused] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleValueChanged = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleOnBlur = () => {
    changeHandler(inputValue);
  };

  const EndAdornment = useMemo(() => {
    if (!!inputValue && !isInputFocused && addClickHandler && showAddButton) {
      return (
        <StyledIconButton
          type="button"
          variant={ IconButtonVariants.Fill }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ addClickHandler }
          StartIcon={ AddButtonIcon }
        />
      );
    }

    if (isInputFocused && showRemoveButton) {
      return (
        <StyledIconButton
          type="button"
          variant={ IconButtonVariants.Fill }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ removeClickHandler }
          StartIcon={ RemoveButtonIcon }
        />
      );
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, isInputFocused, showAddButton, showRemoveButton]);

  const onSkipButtonClick = () => {
    skipButtonClickHandler();
    setInputFocused(false);
  };

  const getInstructionMessage = () => {
    if (!showInstructionMessage) {
      return undefined;
    }

    return (
      <InstructionsMessageContainer>
        <OpenGuidanceModalButton clickHandler={ () => guidanceButtonClickHandler?.() } />

        { showSkipButton && !inputValue && (
          <SkipMessage onClick={ onSkipButtonClick }>
            <Trans i18nKey={ STRING_KEYS.ONBOARDING.MULTI_SENTENCE_INPUT_AUTOCOMPLETE_SKIP_MESSAGE }>
              <SkipButton />
              <SkipButtonIcon />
            </Trans>
          </SkipMessage>
        ) }
      </InstructionsMessageContainer>
    );
  };

  return (
    <div>
      <Autocomplete
        inputElementId={ inputElementId }
        InstructionsMessage={ getInstructionMessage() }
        onBlur={ handleOnBlur }
        optionsTitle=""
        autoFocus={ autoFocus }
        isLoading={ false }
        placeholder={ placeholder }
        inputValue={ inputValue }
        onValueChange={ handleValueChanged }
        isInputFocused={ isInputFocused }
        setInputFocused={ setInputFocused }
        isDisableOptions
        inputStyle={ InputStyle.Onboarding }
        EndAdornment={ EndAdornment }
        alternativeEnterChars={ [','] }
        alternativeEnterCharClickHandler={ addClickHandler }
      />
    </div>
  );
};

export default SentenceInput;
