import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import STRING_KEYS from '../../../language/keys';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { Bold, PositionTitleItem, PositionTitlesContainer } from './similar-title-widget.styled';

export interface SimilarTitleItem {
  title: string;
  distance: number;
}

export interface SimilarTitleWidgetProps {
  jobTitle: string;
  positionTitles: SimilarTitleItem[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const SimilarTitleWidget = ({
  jobTitle,
  positionTitles,
  onIntersectionHandler,
}:SimilarTitleWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);
  useInsightWidgetIntersection({
    componentRef, onIntersectionHandler, metadata: positionTitles.map((title) => title.title).join(', '),
  });

  const createDistanceToTitlesMap = (): Map<number, SimilarTitleItem[]> => {
    return positionTitles
      .sort((item1: SimilarTitleItem, item2: SimilarTitleItem) => item1.distance - item2.distance)
      .reduce((distanceToTitlesMap: Map<number, SimilarTitleItem[]>, currentValue: SimilarTitleItem) => {
        const distanceArray = distanceToTitlesMap.get(currentValue.distance) || [];
        distanceArray.push(currentValue);
        distanceToTitlesMap.set(currentValue.distance, distanceArray);

        return distanceToTitlesMap;
      }, new Map<number, SimilarTitleItem[]>());
  };

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.SIMILAR_TITLES_CARD_TEXT }>
        <Bold />
        {{ jobTitle }}
      </Trans>
    );
  };

  const createSimilarTitlesChips = () => {
    return Array.from(createDistanceToTitlesMap().values())
      .reduce((elementsArray: JSX.Element[], current: SimilarTitleItem[], currentIndex: number) => {
        const currentElements: JSX.Element[] = current.map((item) => (
          <PositionTitleItem opacity={ 1 - currentIndex / 10 } key={ item.title }>
            { item.title }
          </PositionTitleItem>
        ));

        return [...elementsArray, ...currentElements];
      }, []);
  };

  const renderCardBody = () => {
    return (
      <PositionTitlesContainer ref={ componentRef }>
        { createSimilarTitlesChips() }
      </PositionTitlesContainer>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="💡"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.SIMILAR_TITLES_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

SimilarTitleWidget.defaultProps = {
  onIntersectionHandler: undefined,
};
