import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body2Font, Subtitle1Font, Subtitle2Font } from '../../../themes/fonts';
import { hexToRgbA } from '../../../utils/colors';
import { ConnectionScoreEnum } from '../../../enums/connection-score.enum';
import { ASSETS } from '../../../assets';
import { scoreColorByLevel } from './base-card.consts';

export const TalentCardContainer = styled.div<{ showTip: boolean }>` 
  width: 100%;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px rgb(235, 235, 237); // TODO rgb(235, 235, 237) asked Israel about color
  border-bottom-left-radius: ${({ showTip }) => showTip ? 'unset' : undefined} ;
  border-bottom-right-radius: ${({ showTip }) => showTip ? 'unset' : undefined} ;
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AvatarContainer = styled.div`
  margin-top: ${THEME.SPACING.TINY};  
  margin-bottom: ${THEME.SPACING.BASE};  
`;

export const NameContainer = styled.div<{ isLocked: boolean }>`
  ${Subtitle1Font};
  font-weight: 600;
  ${({ isLocked }) => isLocked ? 'filter: blur(5px)' : ''};
`;

export const DescriptionContainer = styled.div`
  ${Body2Font};
  padding-top: ${THEME.SPACING.TINY};
`;

export const TipContainer = styled.div<{ level: ConnectionScoreEnum, isClickable?: boolean }>`
  width: 100%;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  background-color: ${({ level }) => hexToRgbA(scoreColorByLevel[level], 0.08)};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, 0.08)};
  border-bottom-left-radius: ${THEME.RADIUS.ROUNDISH};
  border-bottom-right-radius: ${THEME.RADIUS.ROUNDISH};
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: ${({ isClickable }) => isClickable ? 'pointer' : undefined};
  }
`;

export const TipLabel = styled.span`
  ${Subtitle2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const TipContent = styled.span<{ bold?: boolean }>`
  ${Subtitle2Font};
  font-weight: ${({ bold }) => bold ? 600 : 400};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const LockIcon = styled(ASSETS.LOCK_COMPONENT)<{ level: ConnectionScoreEnum }>`
  width: 11px;
  height: 10px;
  fill: ${({ level }) => scoreColorByLevel[level]};
`;
