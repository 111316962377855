import { positionQuery } from '../position.toolkit-api';
import { useGetMyAccountSelector } from '../../account/selectors/get-account-by-id.selector';
import { PositionInsightsTransformer } from '../../../../../transformers/position-insights.transformer';
import { useGetPositionByIdFromPositionsQuerySelector } from '../selectors/get-positions.selector';
import { PositionInsights } from '../../../../../models/dto/model/position-insights.model';

export const useGetPositionInsightsQuery = (positionId: string, skip = false): AppSelectorType<PositionInsights> => {
  // TODO [refactor]: Check this is approach is okay
  const { data: account } = useGetMyAccountSelector();
  const { data: position } = useGetPositionByIdFromPositionsQuerySelector(positionId);

  const {
    data: insights,
    isLoading,
    isError,
    isSuccess,
    error,
  } = positionQuery.useGetPositionInsightsQuery(
    positionId,
    { skip: skip || !position.hasInsights },
  );

  const transformedInsights = PositionInsightsTransformer.createPositionInsights(account, position, insights);

  return {
    data: transformedInsights || {} as PositionInsights,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
