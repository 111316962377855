import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Bold = styled.span`
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BarTextWrapper = styled.div`
  padding: 0 ${THEME.SPACING.BASE};
  max-width: 160px;
`;

export const BarTextTitle = styled.div`
  font-size: 12px;
  text-transform: capitalize;
  height: 35px;
  line-height: 1.17;
  letter-spacing: 1.08px;
`;

export const BarTextValue = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
`;

export const StayTunedValue = styled.div`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 3px;
`;

export const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const Bar = styled.div<{ opacity?: number, height?: number, marginLeft?: boolean}>`
  background-color: ${THEME.COLORS.ACCENT};
  width: ${({ marginLeft }) => marginLeft ? 'calc(100% + 8px)' : '100%'};
  position: relative;
  left: ${({ marginLeft }) => marginLeft ? '-8px' : 0};
  opacity: ${({ opacity }) => opacity};
  height: ${({ height }) => height ? `calc(${(height / 100) * 88}px)` : '88px'};
`;

export const Divider = styled.div`
  width: 2px;
  height: 160px;
  background-color: ${THEME.COLORS_RGB.LIGHT_GRAY}
`;

export const DividerContainer = styled.div`
  position: relative;
`;

export const DividerTop = styled.div`
  height: 20px;
  width: 10px;
  background-color: ${THEME.COLORS_RGB.LIGHT_GRAY};
  border-radius: 20px;
  position: absolute;
  left: -4px;
`;
