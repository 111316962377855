import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { InitialsAvatar } from '../../../../../components/Common/InitialsAvatar';
import { getInitials } from '../../../../../utils';
import { PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { usePageFilters } from '../../../../../hooks/use-page-filters.hooks';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import STRING_KEYS from '../../../../../language/keys';
import { ConversationPageSizeEnum, useConversationPageSize } from '../../../hooks/conversations-page-size.hook';
import { IconButtonVariants } from '../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { useArchiveConversationByIdMutation } from '../../../../../store/rtk-query/apis/outreach/hooks/archive-conversation-by-id.mutation-hook';
import { AlertSnackBar } from '../../../../../components/Common/CustomSnackBar/AlertSnackBar';
import ConfirmationDialog from '../../../../../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { PerfectTooltip } from '../../../../../components/Common/perfect-tooltip';
import {
  ActionButton,
  ActionsContainer,
  ArchiveIcon,
  Avatar,
  Caption,
  ConversationsPageHeader,
  InfoContainer,
  Label,
  LabelContainer,
  LinkedinIcon,
  LocationIcon, MatchDetailsIcon,
  Name,
  NameContainer,
  PositionContainer,
  ShowDetailsButton,
  TitleContainer,
} from './conversation-header.styled';
import { ConversationHeaderProps } from './conversation-header';
import ConversationHeaderSkeleton from './conversation-header-skelaton/conversation-header-skeleton.component';

const ConversationHeader = ({
  url,
  fullName,
  talentLocation,
  isLoading,
  isAutoMode,
  linkedinLink,
  isArchived,
}: ConversationHeaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { conversationId, positionId } = useParams<MatchUrlParams & { conversationId: string }>();
  const [showAlert, setShowAlert] = useState(false);
  const [archiveConfirmationDialogOpen, setArchiveConfirmationDialogOpen] = useState(false);
  const pageSize = useConversationPageSize();
  const {
    setFilterByKey, isFilterExists, deleteFiltersByKey, getFiltersByKey,
  } = usePageFilters();

  const [
    archiveConversation,
    {
      isLoading: archiveLoading, isError: isArchiveError,
    },
  ] = useArchiveConversationByIdMutation();

  useEffect(() => {
    if (isArchiveError) {
      setShowAlert(true);
    }
  }, [isArchiveError]);

  const showDetailsHandler = useCallback(() => {
    if (!isFilterExists(AppRouting.URL_PARAMS.OUTREACH.SHOW_DETAILS, 'true')) {
      setFilterByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_DETAILS, 'true', false);
    } else {
      deleteFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_DETAILS);
    }
  }, [deleteFiltersByKey, isFilterExists, setFilterByKey]);

  const isStepSelected = !!getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP)[0];

  const onArchiveAgreeClick = useCallback(() => {
    archiveConversation({ conversationId, positionId });
  }, [archiveConversation, conversationId, positionId]);

  const onLinkedinButtonClick = useCallback(() => {
    if (linkedinLink) {
      window.open(linkedinLink, '_blank');
    }
  }, [linkedinLink]);

  const showDetailsButton = useMemo(() => {
    if (isAutoMode && !isStepSelected) {
      return pageSize === ConversationPageSizeEnum.Small;
    }
    if (!isAutoMode && pageSize === ConversationPageSizeEnum.Small) {
      return true;
    }

    return !!(isAutoMode && pageSize !== ConversationPageSizeEnum.Large);
  }, [isAutoMode, pageSize, isStepSelected]);

  const renderShowDetailsButton = () => {
    if (isStepSelected) {
      if (pageSize === ConversationPageSizeEnum.Small) {
        return (
          <PerfectTooltip
            title={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.TOOLTIPS.SHOW_DETAILS) || '' }
          >
            <ActionButton
              type="button"
              variant={ IconButtonVariants.Ghost }
              StartIcon={ MatchDetailsIcon }
              onClick={ showDetailsHandler }
            />
          </PerfectTooltip>
        );
      }
    }

    return (
      <ShowDetailsButton
        type="button"
        variant={ PerfectButtonVariants.Outline }
        onClick={ showDetailsHandler }
      >
        {translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.SHOW_DETAILS_BUTTON)}
      </ShowDetailsButton>
    );
  };

  const renderHeaderContent = () => {
    return (
      <>
        <InfoContainer>
          <Avatar alt={ fullName } src={ url }>
            <InitialsAvatar
              alt={ fullName }
              message={ getInitials(fullName) }
              fontSize="16px"
            />
          </Avatar>
          <TitleContainer>
            <NameContainer>
              <Name>{fullName}</Name>
            </NameContainer>
            <PositionContainer>
              <LabelContainer>
                <LocationIcon />
                { talentLocation.city && (
                  <Label>{talentLocation.city},</Label>
                ) }
                <Caption>{talentLocation.country}</Caption>
              </LabelContainer>
            </PositionContainer>
          </TitleContainer>
        </InfoContainer>
        <ActionsContainer>
          { !isArchived && (
            <PerfectTooltip
              title={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.TOOLTIPS.ARCHIVE) || '' }
            >
              <ActionButton
                type="button"
                variant={ IconButtonVariants.Ghost }
                StartIcon={ ArchiveIcon }
                isLoading={ archiveLoading }
                onClick={ () => setArchiveConfirmationDialogOpen(true) }
              />
            </PerfectTooltip>
          ) }
          <PerfectTooltip title={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.TOOLTIPS.LINKEDIN) || '' }>
            <ActionButton
              type="button"
              variant={ IconButtonVariants.Ghost }
              StartIcon={ LinkedinIcon }
              onClick={ onLinkedinButtonClick }
            />
          </PerfectTooltip>
          {
            showDetailsButton && renderShowDetailsButton()
          }
        </ActionsContainer>
      </>
    );
  };

  return (
    <>
      <ConversationsPageHeader>
        {isLoading && <ConversationHeaderSkeleton />}
        {!isLoading && renderHeaderContent()}
      </ConversationsPageHeader>
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
        isOpen={ showAlert }
        shouldAutoDismiss
        onClose={ () => setShowAlert(false) }
      />
      <ConfirmationDialog
        isOpen={ archiveConfirmationDialogOpen }
        title={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.TITLE) }
        description={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.DESCRIPTION) }
        closeHandler={ () => setArchiveConfirmationDialogOpen(false) }
        confirmHandler={ onArchiveAgreeClick }
        confirmButtonTitle={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.CONFIRM_BUTTON_TITLE) }
        cancelButtonTitle={ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.CANCEL_BUTTON_TITLE) }
      />
    </>
  );
};

export default ConversationHeader;
