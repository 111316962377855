import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Bold = styled.span`
  font-weight: bold;
`;

export const AccentBold = styled.span`
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
`;

export const ChartWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

export const ValueItem = styled.div<{ color: string }>`
  display: flex;
  color: ${({ color }) => color};
  align-items: center;
  font-size: 14px;
  line-height: 32px;
  padding: 0 ${THEME.SPACING.LOOSE};

  ::before {
    content: '•';
    font-size: 30px;
    margin-right: ${THEME.SPACING.TINO};
  }
`;

export const ValueItemWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ValueItemLabel = styled.span`
  font-weight: 500;
`;
