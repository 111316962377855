import styled, { css } from 'styled-components';
import { OverlineFont } from '../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../utils/colors';
import { THEME } from '../../../../../../../themes';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../../../../assets';

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${THEME.SPACING.BASE};
`;

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const Label = styled.div`
  ${OverlineFont};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
  text-transform: uppercase;
  padding: 0 ${THEME.SPACING.BASE};
`;

const IconCss = css`
  width: 24px;
  height: 24px;
`;

export const LinkedInIcon = styled(ASSETS.SOCIAL_LINKEDIN_COMPONENT)`
  ${IconCss};
`;

export const EmailIcon = styled(FLUENT_UI_EMOJIS.EMAIL)`
  ${IconCss};
`;
export const PhoneIcon = styled(FLUENT_UI_EMOJIS.MOBILE_PHONE)`
  ${IconCss};
`;

export const SmsIcon = styled(FLUENT_UI_EMOJIS.SPEECH_BALLOON)`
  ${IconCss};
`;

export const SoonIcon = styled(ASSETS.SOON_LABEL)`
  width: 45px;
  height: 16px;
`;
