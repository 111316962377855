import { ReactComponent as ALERT } from './alert.svg';
import { ReactComponent as MATCH_DETAILS } from './match-details.svg';
import LOGOUT_ICON from './logout_icon.svg';
import SUPPORT_ICON from './support_chat.svg';
import STAR_ICON from './star_icon.png';
import SLEEPING_MOON from './sleeping_moon.svg';
import SLEEPING_CAT from './sleeping_cat.svg';
import SLEEPING_CLOUD from './sleeping_cloud.svg';
import EMPTY_POSITION from './no_positions.svg';
import MATCHES_ICON from './matches_icon.svg';
import PERFECT_LOGO from './perfect_logo.svg';
import { ReactComponent as PERFECT_MARK } from './perfect-mark.svg';
import GOOGLE_LOGO from './google_icon.png';
import PERFECT_BACKGROUND from './perfect_background.png';
import ERROR_CIRCLE from './error_circle.svg';
import { ReactComponent as ERROR_RECTANGLE } from './error-rectangle.svg';
import { ReactComponent as V_SIGN } from './v-sign.svg';
import { ReactComponent as DOUBLE_V_SIGN } from './double-v-sign.svg';
import { ReactComponent as X_SIGN } from './x-sign.svg';
import { ReactComponent as X_SIGN_FILLED } from './x-sign-filled.svg';
import { ReactComponent as X_SIGN_ROUNDISH } from './x-sign-roundish.svg';
import { ReactComponent as CHECK_STAR } from './check_star.svg';
import { ReactComponent as QUOTE_BUBBLE_OUTLINE } from './quote-bubble-outline.svg';
import { ReactComponent as QUOTE_BUBBLE_FILLED } from './quote-bubble-filled.svg';
import { ReactComponent as QUOTE } from './quote.svg';
import { ReactComponent as FILLED_FUNNEL } from './filled-funnel.svg';
import ENVELOP_OVERVIEW_ICON from './envelop.svg';
import STAR_OVERVIEW_ICON, { ReactComponent as STAR } from './star.svg';
import { ReactComponent as REPLY_ARROW } from './reply-arrow.svg';
import { ReactComponent as REQUIRED_NOTIFICATION_TIP_BACKGROUND_BANNER } from './required-notification-tip-background-banner.svg';
import { ReactComponent as BUBBLE_CHAT } from './bubble-chat.svg';
import { ReactComponent as BUBBLE_HEART } from './bubble-heart.svg';
import { ReactComponent as BUBBLE_QUESTION_MARK } from './bubble-question-mark.svg';
import WAVE_OVERVIEW_ICON from './wave.svg';
import WORK_ICON from './work_icon.svg';
import { ReactComponent as HOME_ICON } from './home.svg';
import { ReactComponent as HOURGLASS } from './hourglass.svg';
import { ReactComponent as FOLDER_LIGHTNING } from './folder-lightning.svg';
import { ReactComponent as TOGGLE_ON } from './toggle-on.svg';
import { ReactComponent as TOGGLE_OFF } from './toggle-off.svg';
import { ReactComponent as GOOGLE_G_LOGO } from './google-g-logo.svg';
import { ReactComponent as MICROSOFT_LOGO } from './microsoft-logo.svg';
import { ReactComponent as OUTREACH_ICON } from './outreach.svg';
import { ReactComponent as BALL_BOUNCE } from './ball-bounce.svg';
import { ReactComponent as OUTLINED_TIMER } from './outlined-timer.svg';
import { ReactComponent as FILLED_SEND } from './filled-send.svg';
import { ReactComponent as FILLED_CALENDAR } from './filled-calendar.svg';
import { ReactComponent as SHIRT_S } from './shirt_s.svg';
import { ReactComponent as SHIRT_M } from './shirt_m.svg';
import { ReactComponent as SHIRT_L } from './shirt_l.svg';
import { ReactComponent as SHIRT_XL } from './shirt_xl.svg';
import { ReactComponent as ARROW_RIGHT } from './arrow_right.svg';
import { ReactComponent as ARROW_SOLID_RIGHT } from './arrow-solid-right.svg';
import { ReactComponent as ARROW_FILLED_DOWN } from './arrow-filled-down.svg';
import { ReactComponent as NEW_LABEL } from './new-label.svg';
import { ReactComponent as TASK_LABEL } from './task-label.svg';
import { ReactComponent as SEARCH } from './search.svg';
import { ReactComponent as REGENERATION } from './regeneration.svg';
import { ReactComponent as RADIUS } from './radius.svg';
import { ReactComponent as MENU } from './menu.svg';
import { ReactComponent as NEW_MESSAGE } from './new-message.svg';
import { ReactComponent as MESSAGE_NOTIFICATION } from './message-notification.svg';
import SAVED_ICON from './saved.svg';
import { ReactComponent as DECLINED_ICON } from './declined.svg';
import { ReactComponent as ENGAGED_ICON } from './engaged.svg';
import { ReactComponent as ENGAGED_COMPONENT } from './engaged_status.svg';
import { ReactComponent as CONTACTED_ICON } from './contacted.svg';
import { ReactComponent as TALENT_NETWORK_ICON } from './talent-network-icon.svg';
import SOCIAL_FACEBOOK from './social_networks/facebook.svg';
import SOCIAL_GITHUB from './social_networks/github.svg';
import SOCIAL_LINKEDIN,
{ ReactComponent as SOCIAL_LINKEDIN_COMPONENT } from './social_networks/linkedin.svg';
import SOCIAL_MEDIUM from './social_networks/medium.svg';
import SOCIAL_TWITTER from './social_networks/twitter.svg';
import SOCIAL_OTHER from './social_networks/other.svg';
import LOCATION_ICON from './location_icon.svg';
import { ReactComponent as WORK_FULL_ICON } from './work_full_icon.svg';
import { ReactComponent as FILTER_HAMBURGER_ICON } from './filter_hamburger.svg';
import { ReactComponent as INFINITY_ARROW_ICON } from './infinity-arrow.svg';
import { ReactComponent as INFINITY_ARROW_INVERTED_ICON } from './infinity-arrow-inverted.svg';
import { ReactComponent as ADD_USER } from './add-user.svg';
import { ReactComponent as EXPORT } from './export.svg';
import { ReactComponent as ARROW_DOWN } from './arrow_down.svg';
import { ReactComponent as ALARM } from './alarm.svg';
import { ReactComponent as DREAM } from './dream.svg';
import SAND_CLOCK from './sand_clock.svg';
import { ReactComponent as BADGE_STAR } from './badge-star.svg';
import ARROW_BACK,
{ ReactComponent as ARROW_BACK_COMPONENT } from './arrow_back.svg';
import { ReactComponent as FILL_ARROW_BACK_COMPONENT } from './fill-arrow-back.svg';
import CALENDAR from './calendar-updates.svg';
import EDUCATION_HAT from './education_hat.svg';
import INSIGHTS_PANEL, { ReactComponent as INSIGHTS_PANEL_COMPONENT } from './insight-panel.svg';
import LIGHTNING_BACKGROUND from './lightning-background.svg';
import ENTER_KEYBOARD from './enter_keyboard.svg';
import { ReactComponent as INSIGHTS } from './server_icons/insights.svg';
import { ReactComponent as INSTITUTION_BUILDING } from './institution-building.svg';
import { ReactComponent as COLLEGE_HAT } from './college_hat.svg';
import { ReactComponent as ARROW_RIGHT_SQUARE } from './arrow-right-square.svg';
import EDUCATION_FALLBACK from './education-fallback.svg';
import { ReactComponent as ADD_LINK } from './add_link.svg';
import { ReactComponent as DUPLICATE } from './duplicate.svg';
import ADD_LINK_ALT from './add_link_alt.svg';
import EXPERIENCE_FALLBACK from './experience-fallback.svg';
import { ReactComponent as COMPANY_NAME } from './company_name_icon.svg';
import BADGE_FLAG from './badge_flag.svg';
import LIST, { ReactComponent as LIST_COMPONENT } from './list.svg';
import { ReactComponent as STEP_NEXT } from './step_next.svg';
import { ReactComponent as STEP_COMPLETED } from './step_completed.svg';
import { ReactComponent as CHECKED_GHOST } from './checked-ghost.svg';
import { ReactComponent as LIANA } from './liana.svg';
import { ReactComponent as SLOTH_ON_TREE } from './sloth_on_tree.svg';
import { ReactComponent as SLOTH_YOGA } from './sloth_yoga.svg';
import { ReactComponent as CLOSE_CHIP } from './close_chip.svg';
import { ReactComponent as LIST_START } from './list_start.svg';
import { ReactComponent as HORI_DOTS } from './hori_dots.svg';
import { ReactComponent as STEP_CURRENT } from './step_current.svg';
import { ReactComponent as FILTER_MENU_CALENDAR } from './filter_menu_calendar.svg';
import { ReactComponent as SCHEDULE_MEETING } from './schedule-meeting.svg';
import { ReactComponent as FILTER_MENU_ENGAGED } from './filter_menu_engaged.svg';
import { ReactComponent as NUMBER_CIRCLE_NINE } from './why_card_number_circle_nine.svg';
import { ReactComponent as CHECKED_CIRCLE_DONE_FILLED } from './checked_circle_done_filled.svg';
import { ReactComponent as UNKNOWN_COMPANY } from './unknown_company.svg';
import { ReactComponent as FIRE } from './fire.svg';
import { ReactComponent as MEDAL } from './medal.svg';
import { ReactComponent as UP_CHART } from './up_chart.svg';
import { ReactComponent as SPACESHIP_ICON } from './spaceship.svg';
import { ReactComponent as TERMINAL } from './terminal.svg';
import { ReactComponent as GMAIL } from './gmail.svg';
import { ReactComponent as BUTTON_ARROW_RIGHT } from './button_arrow_right.svg';
import { ReactComponent as CLOSE } from './close.svg';
import { ReactComponent as ELECTRIC_PLUG } from './electric-plug.svg';
import { ReactComponent as FILLED_PLUS } from './filled_plus.svg';
import { ReactComponent as TRASH_BASKET } from './trash-basket.svg';
import { ReactComponent as PLUS_CIRCLE_OUTLINE } from './plus-circle-outline.svg';
import { ReactComponent as PLUS_SIGN_FILLED } from './plus-sign-filled.svg';
import { ReactComponent as COMPUTER_MOUSE } from './computer_mouse.svg';
import { ReactComponent as NODES_CONNECTION } from './nodes-connection.svg';
import SORT from './sort.png';
import DECLINE from './decline.svg';
import { ReactComponent as UNDO } from './undo.svg';
import { ReactComponent as LOCATION_PIN } from './location-pin.svg';
import { ReactComponent as MAP } from './map.svg';
import CHECKED_CIRCLE from './checked_circle.svg';
import { ReactComponent as WARNING_FILLED } from './warning_filled.svg';
import CHECKED_CIRCLE_FILLED,
{ ReactComponent as CHECKED_CIRCLE_FILLED_COMPONENT } from './checked_circle_filled.svg';
import { ReactComponent as CHECKED_CIRCLE_FILLED_SVG } from './checked_circle_filled2.svg';
import { ReactComponent as CHECKED_ROMB_FILLED } from './checked_romb_filled.svg';
import WARNING from './warning.svg';
import ENVELOP from './envelop_black.svg';
import PHONE from './phone.svg';
import COPY, { ReactComponent as COPY_COMPONENT } from './copy.svg';
import { ReactComponent as COPY_TEXT } from './copy_text.svg';
import { ReactComponent as STARS_GROUP_FILL } from './stars_group_fill.svg';
import { ReactComponent as STARS_GROUP_FILL_MIRROR } from './stars-group-fill-mirror.svg';
import { ReactComponent as ASTERISK } from './asterisk.svg';
import { ReactComponent as MAGIC_WAND_FILL } from './magic_wand_fill.svg';
import FEEDBACK_NEEDED from './feedback_needed.png';
import BACKGROUND_IMAGE_MOBILE from './mobile_background.png';
import BACKGROUND_IMAGE_TABLET_LAND from './tablet_background_land.png';
import BACKGROUND_IMAGE_TABLET from './tablet_background.png';
import LEAF_LEFT from './leaf-left.svg';
import LEAF_RIGHT from './leaf-right.svg';
import { ReactComponent as INFO } from './info.svg';
import { ReactComponent as HEART } from './heart.svg';
import { ReactComponent as FORWARD } from './forward.svg';
import { ReactComponent as REJECT } from './reject.svg';
import { ReactComponent as PASSED_FILL } from './passed_fill.svg';
import { ReactComponent as LIKE_FILL } from './like_fill.svg';
import DISLIKE from './dislike.svg';
import START_ENGAGE from './start_engage.svg';
import CLOSE_DIALOG from './close_dialog.svg';
import CIRCLED_LOCK from './circled_lock.png';
import { ReactComponent as ENGAGE_OR_DECLINE }  from './engage-or-decline.svg';
import { ReactComponent as FILLED_LIKE }  from './filled-like.svg';
import { ReactComponent as FILLED_DIAMOND }  from './filled_diamond.svg';
import { ReactComponent as FILLED_TIMER }  from './filled_timer.svg';
import { ReactComponent as ETERNAL_LINK }  from './external_link.svg';
import LOCK, { ReactComponent as LOCK_COMPONENT } from './lock.svg';
import CONFUSE_SMILE from './confuse_smile.png';
import RAISED_HAND from './raised_hand.png';
import TRANSFER from './transfer.svg';
import SELECTION from './selection.svg';
import LETTER from './letter.svg';
import NOTE from './note.svg';
import { ReactComponent as EMAIL } from './email.svg';
import { ReactComponent as CHECKLIST_FILL } from './checklist-fill.svg';
import GROWTH from './growth.svg';
import { ReactComponent as THREE_DOTS } from './three-dots.svg';
import { ReactComponent as NEW_MATCH_STARS } from './new-match-stars.svg';
import AVATAR_DOTS from './avatar-dots.svg';
import CHART_UP from './chart-up.svg';
import { ReactComponent as ESTIMATED_TIME } from './estimated-time.svg';
import MAIL, { ReactComponent as MAIL_COMPONENT } from './mail.svg';
import NUMBER, { ReactComponent as NUMBER_COMPONENT } from './number.svg';
import REVEAL_CONTACT_INFO from './reveal_contact_info.svg';
import PEOPLE from './people.svg';
import FLAG from './flag.svg';
import LINK from './link.svg';
import LINKEDIN, { ReactComponent as LINKEDIN_COMPONENT } from './linkedin.svg';
import LEAF from './leaf.svg';
import DOLLAR_CIRCLE from './dollar_circle.svg';
import BUTTON_ARROW_LEFT from './button_arrow_left.svg';

import { ReactComponent as  COPY_ALT } from './copy_alt.svg';
import { ReactComponent as  STARS_BUBBLE_FILL } from './stars_bubble_fill.svg';
import { ReactComponent as ACADEMIC_EXCELLENCE } from './academic_excellence.svg';
import { ReactComponent as STOPPER } from './stopper.svg';
import { ReactComponent as CAREER_UPGRADE } from './career_upgrade.svg';
import { ReactComponent as ACTIVELY_LOOKING } from './actively_looking.svg';
import { ReactComponent as COMPANY_RANK } from './company_rank.svg';
import { ReactComponent as PROGRESSION } from './progression.svg';
import { ReactComponent as BILLINGUAL } from './bilingual.svg';
import { ReactComponent as TRILINGUAL } from './trilingual.svg';
import { ReactComponent as MULTILINGUAL } from './multilingual.svg';
import { ReactComponent as TALENT_NETWORK_HIGH_SCORE } from './talent-network-high-score.svg';
import { ReactComponent as TALENT_NETWORK_MED_LOW_SCORE } from './talent-network-med-low-score.svg';
import { ReactComponent as ACCOUNT_CIRCLE } from './server_icons/account_circle.svg';
import { ReactComponent as ALARM_ON } from './server_icons/alarm_on.svg';
import { ReactComponent as APPROVAL } from './server_icons/approval.svg';
import { ReactComponent as AUTO_AWESOME } from './server_icons/auto_awesome.svg';
import { ReactComponent as AUTO_STORIES } from './server_icons/auto_stories.svg';
import { ReactComponent as BUG_REPORT } from './server_icons/bug_report.svg';
import { ReactComponent as BUSINESS } from './server_icons/business.svg';
import { ReactComponent as CODE } from './server_icons/code.svg';
import { ReactComponent as DEVELOPER_BOARD } from './server_icons/developer_board.svg';
import { ReactComponent as DIAMOND } from './server_icons/diamond.svg';
import { ReactComponent as HANDSHAKE } from './server_icons/handshake.svg';
import { ReactComponent as HEADSET_MIC } from './server_icons/headset_mic.svg';
import { ReactComponent as HISTORY_EDU } from './server_icons/history_edu.svg';
import { ReactComponent as HOURGLASS_BOTTOM } from './server_icons/hourglass_bottom.svg';
import { ReactComponent as HOURGLASS_TOP } from './hourglass_top.svg';
import { ReactComponent as LAYERS } from './server_icons/layers.svg';
import { ReactComponent as LOCATION_ON } from './server_icons/location_on.svg';
import { ReactComponent as MILITARY_TECH } from './server_icons/military_tech.svg';
import { ReactComponent as MY_LOCATION } from './server_icons/my_location.svg';
import { ReactComponent as ROCKET_LAUNCH } from './server_icons/rocket_launch.svg';
import { ReactComponent as SCHEDULE } from './server_icons/schedule.svg';
import { ReactComponent as SCHOOL } from './server_icons/school.svg';
import { ReactComponent as SCIENCE } from './server_icons/science.svg';
import { ReactComponent as STARS } from './server_icons/stars.svg';
import { ReactComponent as TRACK_CHANGES } from './server_icons/track_changes.svg';
import { ReactComponent as TRENDING_UP } from './server_icons/trending_up.svg';
import { ReactComponent as WHATSHOT } from './server_icons/whatshot.svg';
import { ReactComponent as CAMPAIGN } from './server_icons/campaign.svg';
import { ReactComponent as CENTER_FOCUS } from './server_icons/center_focus.svg';
import { ReactComponent as CLOUD } from './server_icons/cloud.svg';
import { ReactComponent as ATTACH_MONEY } from './server_icons/attach_money.svg';
import { ReactComponent as FILTER_DRAMA } from './server_icons/filter_drama.svg';
import { ReactComponent as GRASS } from './server_icons/grass.svg';
import { ReactComponent as LANGUAGE } from './server_icons/language.svg';
import { ReactComponent as MONETIZATION } from './server_icons/monetization.svg';
import { ReactComponent as PUBLIC } from './server_icons/public.svg';
import { ReactComponent as RECOMMEND } from './server_icons/recommend.svg';
import { ReactComponent as SPORTS_HOCKEY } from './server_icons/sports_hockey.svg';
import { ReactComponent as SPORTS_VOLLEYBALL } from './server_icons/sports_volleyball.svg';
import { ReactComponent as THUMB_UP } from './server_icons/thumb_up.svg';
import { ReactComponent as VOLUNTEER_ACTIVISM } from './server_icons/volunteer_activism.svg';
import { ReactComponent as WYSIWYG } from './server_icons/wysiwyg.svg';
import { ReactComponent as HIKING } from './server_icons/hiking.svg';
import { ReactComponent as SPORTS_CRICKET } from './server_icons/sports_cricket.svg';
import { ReactComponent as VERIFIED } from './server_icons/verified.svg';
import { ReactComponent as CLOSE_BASKET_COMPONENT } from './close_basket.svg';
import { ReactComponent as DOWNLOAD } from './download.svg';
import { ReactComponent as DOWNLOAD_FILLED } from './download-filled.svg';
import { ReactComponent as VERTICAL_SETTINGS } from './vertical-settings.svg';
import { ReactComponent as SETTINGS } from './settings.svg';
import { ReactComponent as ICON_BUBBLE } from './info_bubble.svg';
import { ReactComponent as UNREAD_EMAIL } from './unread_email.svg';
import { ReactComponent as QUESTION_FILLED } from './question_filled.svg';
import { ReactComponent as QUESTION_OUTLINE } from './question_outline.svg';
import { ReactComponent as LIGHTNING } from './lightning.svg';
import { ReactComponent as SIGNATURE } from './signature.svg';
import { ReactComponent as TASK } from './task.svg';
import { ReactComponent as COLORED_LIGHTNING } from './colored-lightning.svg';
import { ReactComponent as BACK } from './back.svg';
import { ReactComponent as INSIGHTS_ICON } from './insights.svg';
import { ReactComponent as ALL_MATCHES_FILTER_ICON } from './all-matches-filter-icon.svg';
import { ReactComponent as HEART_OUTLINE } from './heart-outline.svg';
import { ReactComponent as HEART_REFLECTION } from './heart-reflection.svg';
import { ReactComponent as STARS_FILLED } from './stars-filled.svg';
import { ReactComponent as FULL_PROFILE } from './full_profile.svg';
import { ReactComponent as STEAM } from './steam.svg';
import { ReactComponent as SOON_LABEL } from './soon.svg';
import { ReactComponent as SUNSHINE } from './sunshine.svg';
import { ReactComponent as FROM_USER } from './email_from_user.svg';
import { ReactComponent as INVITE_GHOST } from './invite-ghost.svg';
import { ReactComponent as LINKEDIN_LETTERS } from './linkedin_letters.svg';
import { ReactComponent as CHAT } from './chat.svg';
import { ReactComponent as CHAT_V_SIGN } from './chat-v-sign.svg';
import { ReactComponent as USER_AVATAR } from './user-avatar.svg';
import { ReactComponent as TWO } from './two.svg';
import { ReactComponent as MESSAGE_CONTENT } from './message-content.svg';
import { ReactComponent as MAGIC_WAND } from './magic-wand.svg';
import { ReactComponent as SPICES } from './spices.svg';
import { ReactComponent as PAUSE } from './pause.svg';
import { ReactComponent as INCREASE_REPLAY_RATE } from './increase-replay-rate.svg';
import { ReactComponent as SMILING_FACE_WITH_TEAR } from './smiling-face-with-tear.svg';

import { ReactComponent as EXIT_ALERT } from './exit-alert.svg';
import { ReactComponent as BELL } from './bell.svg';
import { ReactComponent as UNLIKE_LIKE } from './group-unlike-like.svg';
import { ReactComponent as BRACKETS } from './brackets.svg';
import { ReactComponent as COG } from './cog.svg';
import { ReactComponent as COG_FILLED } from './cog-filled.svg';
import { ReactComponent as PENCIL } from './pencil.svg';
import { ReactComponent as ARCHIVE_FILLED } from './archive-filled.svg';
import { ReactComponent as MASKS } from './masks.svg';

import MARKED_CLOCK from './marked_clock.svg';

import SPONGEBOB from './gifs/errors/spongebob.webp';
import DOG_PEOPLE from './gifs/errors/dog.webp';

import PULP_FICTION from './gifs/errors/pulp_fiction.webp';
import HOMER_SIMPSON from './gifs/errors/homer_simpson.gif';
import CHECK_THIS_OUT from './gifs/errors/check_this_out.gif';

import THANK_YOU from './gifs/thank_you.gif';

import BACKGROUND_IMAGE_BLURRED from './blurred_app_background.png';
import BLURRED_BACKGROUND_MATCH from './blurred_match_background.jpg';

import MAIL_ALT from './mail_alt.png';
import SECRET_ICON from './secret_icon.png';

import ATS_SYNC_PROMO from './images/ats-sync-promo.png';
import ATS_LOGOS from './images/ats-logos.png';
import ATS_COMEET_LOGO from './images/ats-comeet-logo.png';
import ATS_GREENHOUSE_LOGO from './images/ats-greenhouse-logo.png';
import ATS_ASHBY_LOGO from './images/ats-ashby-logo.png';
import REGISTRATION_PAGE_BACKGROUND from './images/registration-background.png';
import SLOTH_ON_ROCKET from './images/sloth_on_rocket.png';

import FAKE_IMAGE_1 from './fake_avatars/profileImage1.png';
import FAKE_IMAGE_2 from './fake_avatars/profileImage2.png';
import FAKE_IMAGE_3 from './fake_avatars/profileImage3.png';
import FAKE_IMAGE_4 from './fake_avatars/profileImage4.png';
import FAKE_IMAGE_5 from './fake_avatars/profileImage5.png';
import FAKE_IMAGE_6 from './fake_avatars/profileImage6.png';
import FAKE_IMAGE_7 from './fake_avatars/profileImage7.png';
import FAKE_IMAGE_8 from './fake_avatars/profileImage8.png';
import FAKE_IMAGE_9 from './fake_avatars/profileImage9.png';
import FAKE_IMAGE_10 from './fake_avatars/profileImage10.png';

import FAKE_BLURRED_IMAGE_1 from './fake_blurred_avatars/fake_blurred_avatar.png';
import FAKE_BLURRED_IMAGE_2 from './fake_blurred_avatars/fake_blurred_avatar_1.png';
import FAKE_BLURRED_IMAGE_3 from './fake_blurred_avatars/fake_blurred_avatar_2.png';
import FAKE_BLURRED_IMAGE_4 from './fake_blurred_avatars/fake_blurred_avatar_3.png';
import FAKE_BLURRED_IMAGE_5 from './fake_blurred_avatars/fake_blurred_avatar_4.png';
import FAKE_BLURRED_IMAGE_6 from './fake_blurred_avatars/fake_blurred_avatar_5.png';
import FAKE_BLURRED_IMAGE_7 from './fake_blurred_avatars/fake_blurred_avatar_6.png';
import FAKE_BLURRED_IMAGE_8 from './fake_blurred_avatars/fake_blurred_avatar_7.png';
import FAKE_BLURRED_IMAGE_9 from './fake_blurred_avatars/fake_blurred_avatar_8.png';
import FAKE_BLURRED_IMAGE_10 from './fake_blurred_avatars/fake_blurred_avatar_9.png';

import { ReactComponent as FACEBOOK } from './social_links/facebook.svg';
import { ReactComponent as GITHUB } from './social_links/github.svg';
import { ReactComponent as LINKED_IN } from './social_links/linkedin-fill.svg';
import { ReactComponent as LINKED_IN_OUTLINE } from './social_links/linkedin-outline.svg';
import { ReactComponent as MEDIUM } from './social_links/medium.svg';
import { ReactComponent as STACK_OVERFLOW } from './social_links/stack_overflow.svg';
import { ReactComponent as TWITTER } from './social_links/twitter.svg';
import { ReactComponent as OTHER } from './social_links/other.svg';

import { ReactComponent as ALIGN_LEFT } from './perfect-text-editor/align-left.svg';
import { ReactComponent as ALIGN_CENTER } from './perfect-text-editor/align-center.svg';
import { ReactComponent as ALIGN_RIGHT } from './perfect-text-editor/align-right.svg';
import { ReactComponent as ITALIC } from './perfect-text-editor/italic.svg';
import { ReactComponent as BOLD } from './perfect-text-editor/bold.svg';
import { ReactComponent as UNDERLINE } from './perfect-text-editor/underline.svg';
import { ReactComponent as TOKENS } from './perfect-text-editor/tokens.svg';

import { ReactComponent as DAILY_STREAK_1 } from './daily-streak/daily-streak-1.svg';
import { ReactComponent as DAILY_STREAK_2 } from './daily-streak/daily-streak-2.svg';
import { ReactComponent as DAILY_STREAK_3 } from './daily-streak/daily-streak-3.svg';
import { ReactComponent as DAILY_STREAK_4 } from './daily-streak/daily-streak-4.svg';
import { ReactComponent as DAILY_STREAK_5 } from './daily-streak/daily-streak-5.svg';
import { ReactComponent as DAILY_STREAK_6 } from './daily-streak/daily-streak-6.svg';
import { ReactComponent as DAILY_STREAK_7 } from './daily-streak/daily-streak-7.svg';

import { ReactComponent as BRIEFCASE } from './fluentui-emojis/briefcase.svg';
import { ReactComponent as DECIDUOUS_TREE } from './fluentui-emojis/deciduous-tree.svg';
import { ReactComponent as EVERGREEN_TREE } from './fluentui-emojis/evergreen-tree.svg';
import { ReactComponent as EIGHT_SPOKED_ASTERISK } from './fluentui-emojis/eight-spoked-asterisk.svg';
import { ReactComponent as OFFICE_BUILDING } from './fluentui-emojis/office-building.svg';
import { ReactComponent as ROUND_PUSHPIN } from './fluentui-emojis/round-pushpin.svg';
import { ReactComponent as MILITARY_MEDAL } from './fluentui-emojis/military-medal.svg';
import { ReactComponent as MAGNIFYING_GLASS_TILTED_LEFT } from './fluentui-emojis/magnifying-glass-tilted-left.svg';
import { ReactComponent as MAN_JUGGLING } from './fluentui-emojis/man-juggling.svg';
import { ReactComponent as POSTBOX } from './fluentui-emojis/postbox.svg';
import { ReactComponent as FACE_WITH_MONOCLE } from './fluentui-emojis/face-with-monocle.svg';
import { ReactComponent as HERB } from './fluentui-emojis/herb.svg';
import { ReactComponent as SEEDLING } from './fluentui-emojis/seedling.svg';
import { ReactComponent as FLUENT_EMAIL } from './fluentui-emojis/email.svg';
import { ReactComponent as SPEECH_BALLOON } from './fluentui-emojis/speech-balloon.svg';
import { ReactComponent as MOBILE_PHONE } from './fluentui-emojis/mobile-phone.svg';
import { ReactComponent as ROCKET } from './fluentui-emojis/rocket.svg';
import { ReactComponent as FLUENT_LINK } from './fluentui-emojis/link.svg';
import { ReactComponent as WAVING_HAND } from './fluentui-emojis/waving-hand.svg';
import { ReactComponent as ENVELOPE_WITH_ARROW } from './fluentui-emojis/envelope-with-arrow.svg';
import { ReactComponent as LOVE_LETTER } from './fluentui-emojis/love-letter.svg';
import { ReactComponent as PARTY_POPPER } from './fluentui-emojis/party-popper.svg';
import { ReactComponent as PINIATA } from './fluentui-emojis/piniata.svg';
import { ReactComponent as KITE } from './fluentui-emojis/kite.svg';
import { ReactComponent as RAINBOW } from './fluentui-emojis/rainbow.svg';
import { ReactComponent as WOMAN_RAISING_HAND } from './fluentui-emojis/woman-raising-hand.svg';
import { ReactComponent as HIGH_VOLTAGE } from './fluentui-emojis/high-voltage.svg';
import HIGH_VOLTAGE_GREEN from './fluentui-emojis/high-voltage-green.png';
import HIGH_VOLTAGE_PURPLE from './fluentui-emojis/high-voltage-purple.png';
import { ReactComponent as GRADUATION_CAP } from './fluentui-emojis/graduation-cap.svg';
import { ReactComponent as FIRST_PLACE_MEDAL } from './fluentui-emojis/1st_place_medal.svg';
import { ReactComponent as SPORTS_MEDAL } from './fluentui-emojis/sports-medal.svg';
import { ReactComponent as TROPHY } from './fluentui-emojis/trophy.svg';
import { ReactComponent as CROWN } from './fluentui-emojis/crown.svg';
import { ReactComponent as SPARKLES } from './fluentui-emojis/sparkles.svg';
import { ReactComponent as LIGHT_BUILD } from './fluentui-emojis/light-build.svg';
import { ReactComponent as FLUENT_FIRE } from './fluentui-emojis/fire.svg';
import { ReactComponent as SPARKLING_HEART } from './fluentui-emojis/sparkling-heart.svg';
import { ReactComponent as RIGHT_ARROW_CURVING_LEFT } from './fluentui-emojis/right-arrow-curving-left.svg';
import { ReactComponent as CLAPPING_HANDS } from './fluentui-emojis/clapping-hands.svg';
import { ReactComponent as CLOSED_MAILBOX_WITH_RAISED_FLAG } from './fluentui-emojis/closed-mailbox-with-raised-flag.svg';
import { ReactComponent as STAR_STRUCK } from './fluentui-emojis/star-struck.svg';
import { ReactComponent as JAPANESE_SYMBOL_FOR_BEGINNER } from './fluentui-emojis/japanese-symbol-for-beginner.svg';
import { ReactComponent as FLUENT_STAR } from './fluentui-emojis/star.svg';
import { ReactComponent as GLOWING_STAR } from './fluentui-emojis/glowing-star.svg';
import { ReactComponent as RUNNING_SHOE } from './fluentui-emojis/running-shoe.svg';
import { ReactComponent as BAR_CHART } from './fluentui-emojis/bar-chart.svg';
import { ReactComponent as SPIDER_WEB } from './fluentui-emojis/spider-web.svg';
import { ReactComponent as CLASSICAL_BUILDING } from './fluentui-emojis/classical-building.svg';
import { ReactComponent as GLOBE_WITH_MERIDIANS } from './fluentui-emojis/globe-with-meridians.svg';
import { ReactComponent as CRYSTAL_BALL } from './fluentui-emojis/crystal-ball.svg';
import { ReactComponent as ELECTRIC_PLUG_FLUENT } from './fluentui-emojis/electric-plug.svg';

import ANIMATED_LOVE_LETTER from './animated-fluentui-emojis/love-letter.png';
import ANIMATED_PARTY_POPPER from './animated-fluentui-emojis/party-popper.png';
import ANIMATED_ROCKET from './animated-fluentui-emojis/rocket.png';
import ANIMATED_WAVING_HAND from './animated-fluentui-emojis/waving-hand.png';
import ANIMATED_SLOTH from './animated-fluentui-emojis/sloth.png';

export const DAILY_STREAK = [
  DAILY_STREAK_1,
  DAILY_STREAK_2,
  DAILY_STREAK_3,
  DAILY_STREAK_4,
  DAILY_STREAK_5,
  DAILY_STREAK_6,
  DAILY_STREAK_7,
];

export const SOCIAL_ICONS = {
  FACEBOOK,
  GITHUB,
  LINKED_IN,
  LINKED_IN_OUTLINE,
  MEDIUM,
  STACK_OVERFLOW,
  TWITTER,
  OTHER,
};

export const ASSETS = {
  FILLED_FUNNEL,
  SMILING_FACE_WITH_TEAR,
  CHECKED_ROMB_FILLED,
  INCREASE_REPLAY_RATE,
  MATCH_DETAILS,
  CHECKLIST_FILL,
  DREAM,
  ALARM,
  REGENERATION,
  RADIUS,
  TOGGLE_ON,
  TOGGLE_OFF,
  MASKS,
  SPICES,
  PAUSE,
  MAGIC_WAND,
  MESSAGE_CONTENT,
  COG,
  COG_FILLED,
  PENCIL,
  ARCHIVE_FILLED,
  COLORED_LIGHTNING,
  STEAM,
  SOON_LABEL,
  SUNSHINE,
  BRACKETS,
  FROM_USER,
  INVITE_GHOST,
  FULL_PROFILE,
  ALIGN_RIGHT,
  ALIGN_CENTER,
  ALIGN_LEFT,
  ITALIC,
  TOKENS,
  BOLD,
  UNDERLINE,
  OUTREACH_ICON,
  BALL_BOUNCE,
  FILLED_SEND,
  FILLED_CALENDAR,
  OUTLINED_TIMER,
  SHIRT_S,
  SHIRT_M,
  SHIRT_L,
  SHIRT_XL,
  GOOGLE_G_LOGO,
  MICROSOFT_LOGO,
  NEW_LABEL,
  TASK_LABEL,
  ARROW_FILLED_DOWN,
  NEW_MESSAGE,
  MESSAGE_NOTIFICATION,
  MENU,
  SEARCH,
  ARROW_SOLID_RIGHT,
  LIKE_FILL,
  PASSED_FILL,
  MAGIC_WAND_FILL,
  SOCIAL_ICONS,
  STARS_GROUP_FILL,
  STARS_GROUP_FILL_MIRROR,
  ASTERISK,
  COPY_TEXT,
  SLOTH_YOGA,
  ETERNAL_LINK,
  FILLED_DIAMOND,
  FILLED_LIKE,
  ENGAGE_OR_DECLINE,
  FILLED_TIMER,
  SLOTH_ON_TREE,
  LIANA,
  FILLED_PLUS,
  PLUS_CIRCLE_OUTLINE,
  PLUS_SIGN_FILLED,
  SLOTH_ON_ROCKET,
  STEP_NEXT,
  STEP_CURRENT,
  STEP_COMPLETED,
  CHECKED_GHOST,
  CLOSE_CHIP,
  LIST_START,
  HORI_DOTS,
  ENGAGED_COMPONENT,
  BELL,
  UNLIKE_LIKE,
  PERFECT_LOGO,
  PERFECT_MARK,
  BADGE_STAR,
  GOOGLE_LOGO,
  PERFECT_BACKGROUND,
  LOGOUT_ICON,
  SUPPORT_ICON,
  STAR_ICON,
  SLEEPING_MOON,
  SLEEPING_CAT,
  SLEEPING_CLOUD,
  CLOSE_BASKET_COMPONENT,
  DOWNLOAD,
  DOWNLOAD_FILLED,
  SETTINGS,
  VERTICAL_SETTINGS,
  TRASH_BASKET,
  COMPUTER_MOUSE,
  NODES_CONNECTION,
  EMPTY_POSITION,
  MATCHES_ICON,
  ERROR_CIRCLE,
  ERROR_RECTANGLE,
  V_SIGN,
  DOUBLE_V_SIGN,
  X_SIGN,
  X_SIGN_FILLED,
  X_SIGN_ROUNDISH,
  ENVELOP_OVERVIEW_ICON,
  STAR_OVERVIEW_ICON,
  STAR,
  BUBBLE_CHAT,
  BUBBLE_HEART,
  BUBBLE_QUESTION_MARK,
  REPLAY_ARROW: REPLY_ARROW,
  REQUIRED_NOTIFICATION_TIP_BACKGROUND_BANNER,
  WAVE_OVERVIEW_ICON,
  WORK_ICON,
  HOME_ICON,
  HOURGLASS,
  FOLDER_LIGHTNING,
  ARROW_RIGHT,
  SAVED_ICON,
  DECLINED_ICON,
  ENGAGED_ICON,
  CONTACTED_ICON,
  TALENT_NETWORK_ICON,
  FILTER_HAMBURGER_ICON,
  ADD_USER,
  EXPORT,
  ARROW_DOWN,
  BADGE_FLAG,
  SOCIAL_FACEBOOK,
  SOCIAL_GITHUB,
  SOCIAL_LINKEDIN,
  SOCIAL_MEDIUM,
  SOCIAL_TWITTER,
  LOCATION_ICON,
  WORK_FULL_ICON,
  FILTER_MENU_CALENDAR,
  SCHEDULE_MEETING,
  FILTER_MENU_ENGAGED,
  SPACESHIP_ICON,
  NUMBER_CIRCLE_NINE,
  ARROW_RIGHT_SQUARE,
  EDUCATION_HAT,
  SORT,
  COLLEGE_HAT,
  FIRE,
  MEDAL,
  GMAIL,
  CALENDAR,
  CHART_UP,
  ESTIMATED_TIME,
  UP_CHART,
  TERMINAL,
  SAND_CLOCK,
  EDUCATION_FALLBACK,
  EXPERIENCE_FALLBACK,
  COMPANY_NAME,
  BUTTON_ARROW_RIGHT,
  CLOSE,
  ELECTRIC_PLUG,
  UNDO,
  LOCATION_PIN,
  MAP,
  DECLINE,
  CHECKED_CIRCLE,
  WARNING,
  ENVELOP,
  PHONE,
  COPY,
  DISLIKE,
  HEART,
  FORWARD,
  REJECT,
  SOCIAL_OTHER,
  BACKGROUND_IMAGE_TABLET_LAND,
  LINK,
  FLAG,
  PEOPLE,
  LINKEDIN,
  LINKEDIN_COMPONENT,
  LINKEDIN_LETTERS,
  CHAT,
  CHAT_V_SIGN,
  USER_AVATAR,
  TWO,
  DOLLAR_CIRCLE,
  LEAF,
  BUTTON_ARROW_LEFT,
  UNREAD_EMAIL,
  CHECKED_CIRCLE_FILLED_COMPONENT,
  LIST,
  LIST_COMPONENT,
  CHECK_STAR,
  QUOTE_BUBBLE_OUTLINE,
  QUOTE_BUBBLE_FILLED,
  QUOTE,

  // server icons
  ACCOUNT_CIRCLE,
  DIAMOND,
  ROCKET_LAUNCH,
  ALARM_ON,
  HANDSHAKE,
  SCHEDULE,
  APPROVAL,
  HEADSET_MIC,
  SCHOOL,
  AUTO_AWESOME,
  HISTORY_EDU,
  SCIENCE,
  AUTO_STORIES,
  HOURGLASS_BOTTOM,
  HOURGLASS_TOP,
  STARS,
  BUG_REPORT,
  LAYERS,
  TRACK_CHANGES,
  BUSINESS,
  LOCATION_ON,
  TRENDING_UP,
  CODE,
  ARROW_BACK,
  ARROW_BACK_COMPONENT,
  FILL_ARROW_BACK_COMPONENT,
  MILITARY_TECH,
  WHATSHOT,
  DEVELOPER_BOARD,
  MY_LOCATION,
  CAMPAIGN,
  CENTER_FOCUS,
  CLOUD,
  ATTACH_MONEY,
  FILTER_DRAMA,
  GRASS,
  INSTITUTION_BUILDING,
  INSIGHTS,
  INSIGHTS_PANEL,
  INSIGHTS_PANEL_COMPONENT,
  LANGUAGE,
  MONETIZATION,
  PUBLIC,
  RECOMMEND,
  SPORTS_HOCKEY,
  SPORTS_VOLLEYBALL,
  THUMB_UP,
  VOLUNTEER_ACTIVISM,
  WYSIWYG,
  HIKING,
  SPORTS_CRICKET,
  VERIFIED,
  FEEDBACK_NEEDED,
  BACKGROUND_IMAGE_MOBILE,
  BACKGROUND_IMAGE_TABLET,
  BACKGROUND_IMAGE_BLURRED,
  MAIL_ALT,
  SECRET_ICON,
  INFINITY_ARROW_ICON,
  INFINITY_ARROW_INVERTED_ICON,
  LEAF_LEFT,
  LEAF_RIGHT,
  INFO,
  START_ENGAGE,
  CLOSE_DIALOG,
  AVATAR_DOTS,
  THREE_DOTS,
  NEW_MATCH_STARS,
  TRILINGUAL,
  MULTILINGUAL,
  TALENT_NETWORK_HIGH_SCORE,
  TALENT_NETWORK_MED_LOW_SCORE,
  BILLINGUAL,
  PROGRESSION,
  COMPANY_RANK,
  ENTER_KEYBOARD,
  ACTIVELY_LOOKING,
  CAREER_UPGRADE,
  ACADEMIC_EXCELLENCE,
  COPY_ALT,
  STARS_BUBBLE_FILL,
  STOPPER,
  MAIL,
  NUMBER,
  REVEAL_CONTACT_INFO,
  MARKED_CLOCK,
  CHECKED_CIRCLE_FILLED,
  ALERT,
  ICON_BUBBLE,
  WARNING_FILLED,
  ADD_LINK,
  ADD_LINK_ALT,
  SOCIAL_LINKEDIN_COMPONENT,
  CHECKED_CIRCLE_FILLED_SVG,
  LOCK,
  LOCK_COMPONENT,
  CONFUSE_SMILE,
  RAISED_HAND,
  CIRCLED_LOCK,
  CHECKED_CIRCLE_DONE_FILLED,
  UNKNOWN_COMPANY,
  BLURRED_BACKGROUND_MATCH,
  EXIT_ALERT,
  QUESTION_FILLED,
  QUESTION_OUTLINE,
  BACK,
  INSIGHTS_ICON,
  COPY_COMPONENT,
  MAIL_COMPONENT,
  NUMBER_COMPONENT,
  DUPLICATE,
  TRANSFER,
  GROWTH,
  EMAIL,
  NOTE,
  LETTER,
  SELECTION,
  LIGHTNING,
  SIGNATURE,
  TASK,
  LIGHTNING_BACKGROUND,
  ATS_SYNC_PROMO,
  ATS_LOGOS,
  ATS_COMEET_LOGO,
  ATS_GREENHOUSE_LOGO,
  ATS_ASHBY_LOGO,
  REGISTRATION_PAGE_BACKGROUND,
  ALL_MATCHES_FILTER_ICON,
  HEART_OUTLINE,
  HEART_REFLECTION,
  STARS_FILLED,
};

export const ANIMATED_FLUENT_UI_EMOJIS = {
  LOVE_LETTER: ANIMATED_LOVE_LETTER,
  PARTY_POPPER: ANIMATED_PARTY_POPPER,
  ROCKET: ANIMATED_ROCKET,
  WAVING_HAND: ANIMATED_WAVING_HAND,
  SLOTH: ANIMATED_SLOTH,
};

export const FLUENT_UI_EMOJIS = {
  BRIEFCASE,
  DECIDUOUS_TREE,
  EVERGREEN_TREE,
  EIGHT_SPOKED_ASTERISK,
  OFFICE_BUILDING,
  ROUND_PUSHPIN,
  MILITARY_MEDAL,
  MAGNIFYING_GLASS_TILTED_LEFT,
  MAN_JUGGLING,
  POSTBOX,
  FACE_WITH_MONOCLE,
  HERB,
  SEEDLING,
  EMAIL: FLUENT_EMAIL,
  SPEECH_BALLOON,
  MOBILE_PHONE,
  ROCKET,
  LINK: FLUENT_LINK,
  WAVING_HAND,
  ENVELOPE_WITH_ARROW,
  LOVE_LETTER,
  PARTY_POPPER,
  PINIATA,
  KITE,
  RAINBOW,
  WOMAN_RAISING_HAND,
  HIGH_VOLTAGE,
  HIGH_VOLTAGE_GREEN,
  HIGH_VOLTAGE_PURPLE,
  GRADUATION_CAP,
  FIRST_PLACE_MEDAL,
  SPORTS_MEDAL,
  TROPHY,
  CROWN,
  SPARKLES,
  LIGHT_BUILD,
  FIRE: FLUENT_FIRE,
  SPARKLING_HEART,
  RIGHT_ARROW_CURVING_LEFT,
  CLAPPING_HANDS,
  CLOSED_MAILBOX_WITH_RAISED_FLAG,
  STAR_STRUCK,
  JAPANESE_SYMBOL_FOR_BEGINNER,
  STAR: FLUENT_STAR,
  GLOWING_STAR,
  RUNNING_SHOE,
  BAR_CHART,
  SPIDER_WEB,
  CLASSICAL_BUILDING,
  GLOBE_WITH_MERIDIANS,
  CRYSTAL_BALL,
  ELECTRIC_PLUG: ELECTRIC_PLUG_FLUENT,
};

export const GIFS = {
  SPONGEBOB,
  DOG_PEOPLE,
  PULP_FICTION,
  HOMER_SIMPSON,
  CHECK_THIS_OUT,
  THANK_YOU,
};

export const LOCKED_PROFILE_IMAGES = {
  LOCKED_IMAGE_1: FAKE_BLURRED_IMAGE_1,
  LOCKED_IMAGE_2: FAKE_BLURRED_IMAGE_2,
  LOCKED_IMAGE_3: FAKE_BLURRED_IMAGE_3,
  LOCKED_IMAGE_4: FAKE_BLURRED_IMAGE_4,
  LOCKED_IMAGE_5: FAKE_BLURRED_IMAGE_5,
  LOCKED_IMAGE_6: FAKE_BLURRED_IMAGE_6,
  LOCKED_IMAGE_7: FAKE_BLURRED_IMAGE_7,
  LOCKED_IMAGE_8: FAKE_BLURRED_IMAGE_8,
  LOCKED_IMAGE_9: FAKE_BLURRED_IMAGE_9,
  LOCKED_IMAGE_10: FAKE_BLURRED_IMAGE_10,
};

export const FAKE_AVATARS = {
  FAKE_IMAGE_1,
  FAKE_IMAGE_2,
  FAKE_IMAGE_3,
  FAKE_IMAGE_4,
  FAKE_IMAGE_5,
  FAKE_IMAGE_6,
  FAKE_IMAGE_7,
  FAKE_IMAGE_8,
  FAKE_IMAGE_9,
  FAKE_IMAGE_10,
};
