import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';
import { ASSETS } from '../../../../../../assets';
import { THEME } from '../../../../../../themes';

export const IconFallback = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: ${THEME.RADIUS.BASE};
  position: relative;
  :before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${ASSETS.EXPERIENCE_FALLBACK});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${THEME.RADIUS.BASE};
  padding: ${THEME.SPACING.MICRO} 0px;
`;

export const InstituteLogoImage = styled(MuiAvatar).attrs({
  sx: {
    height: '64px',
    width: '64px',
    backgroundColor: 'unset',
    borderRadius: THEME.RADIUS.BASE,
  },
})`
`;
