import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { BaseWidgetCard } from '../base-widget-card/base-widget-card.component';
import {
  BarChartData,
  BarChartDataItem,
  HorizontalBarChart,
} from '../horizontal-bar-chart/horizontal-bar-chart.component';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import {
  onInsightWidgetIntersectionHandler,
  useInsightWidgetIntersection,
} from '../../../hooks/useInsightWidgetIntersection';
import { AccentBold, Bold } from './field-of-study-widget.styled';

const chartTheme: Record<string, Partial<BarChartDataItem>> = {
  firstElement: {
    backgroundColor: THEME.COLORS.ACCENT, textColor: THEME.COLORS.PURE_WHITE, valueColor: THEME.COLORS.ACCENT,
  },
  otherElement: {
    backgroundColor: THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER, textColor: THEME.COLORS.PRIMARY, valueColor: THEME.COLORS.PRIMARY,
  },
};

export interface FieldOfStudyInsight {
  value: number;
  text: string;
}

export interface FieldOfStudyWidgetProps {
  jobTitle: string;
  data: FieldOfStudyInsight[];
  onIntersectionHandler?: onInsightWidgetIntersectionHandler;
}

export const FieldOfStudyWidget = ({
  jobTitle,
  data,
  onIntersectionHandler,
}:FieldOfStudyWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);

  data.sort((insight1: FieldOfStudyInsight, insight2: FieldOfStudyInsight) => insight2.value - insight1.value);
  const mostCommonFieldOfStudy = data[0];

  useInsightWidgetIntersection({
    componentRef, onIntersectionHandler, metadata: `${mostCommonFieldOfStudy?.value}%`, metadataType: mostCommonFieldOfStudy?.text,
  });

  const renderCardText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.INSIGHT_WIDGETS.FIELD_OF_STUDY_CARD_TEXT }>
        <AccentBold />
        <Bold />
        {{ percentage: mostCommonFieldOfStudy?.value }}
        {{ jobTitle }}
        {{ fieldOfStudyName: mostCommonFieldOfStudy?.text }}
      </Trans>
    );
  };

  const createChartData = (): BarChartDataItem[] => {
    return data.map((insight: FieldOfStudyInsight, index: number) => {
      let barStyle: Partial<BarChartDataItem>;
      if (index === 0) {
        barStyle = chartTheme.firstElement;
      } else {
        barStyle = chartTheme.otherElement;
      }

      return { ...insight, ...barStyle } as BarChartDataItem;
    });
  };

  const createChartObject = (): BarChartData => {
    return {
      data: createChartData(),
      valueTransformationMethod: (value: number) => {
        if (value < 1) {
          return '< 1%';
        }

        return `${value}%`;
      },
      shouldIncreaseBarScale: true,
    };
  };

  const renderCardBody = () => {
    return (
      <div ref={ componentRef }>
        <HorizontalBarChart chartData={ createChartObject() } />
      </div>
    );
  };

  return (
    <BaseWidgetCard
      cardIcon="🎓"
      cardTitle={ translate(STRING_KEYS.INSIGHT_WIDGETS.EDUCATION_CARD_TITLE) }
      cardText={ renderCardText() }
      cardBody={ renderCardBody() }
    />
  );
};

FieldOfStudyWidget.defaultProps = {
  onIntersectionHandler: undefined,
};
