export enum PositionInsightTypeEnum {
  MedianExperience = 'MEDIAN_EXPERIENCE',
  TopIndustries = 'TOP_INDUSTRIES',
  SimilarTitles = 'SIMILAR_TITLES',
  EducationDegree = 'EDUCATION_DEGREE',
  EducationFieldOfStudy = 'EDUCATION_FIELD_OF_STUDY',
  TopEmployer = 'TOP_EMPLOYERS_BY_INDUSTRY',
  PreviousCompanies = 'PREVIOUS_COMPANIES',
  EducationSchool = 'EDUCATION_SCHOOL',
  FunnelOverview = 'FUNNEL_OVERVIEW',
  RoleJourney = 'ROLE_JOURNEY',
}
